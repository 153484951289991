import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Container, Row } from 'react-bootstrap';
import { XAxis, YAxis, CartesianGrid, Tooltip, ScatterChart, Scatter, ResponsiveContainer, AxisDomain } from 'recharts';
import { ShipSaleDot, ShipSaleTooltip } from './';
import { ShipSale, SalesStats } from './types/stats';
import 'bootstrap/dist/css/bootstrap.css';

interface Props {
	isPortrait: boolean;
	isStarSales: boolean;
	clickedShipSale:((dot: any) => void);
	salesStats: SalesStats;
	selectedScale: number;
	selectedCurrency: number;
	priceKey: string;
}

interface State {
	selectedShipSale: ShipSale;
}

class ShipSalesChart extends Component<Props, State> {
	public state = {
		selectedShipSale: Object() as ShipSale
	}

  public render() {
  	return (
  		<Row 
				className='justify-content-md-center'
				style={{ marginLeft: 'auto', marginRight: 'auto', display: 'inline-block' }}
			>
				{this.generateSalesChart()}
			</Row>
		);
  }

  private generateSalesChart = (): JSX.Element => {
		const { isPortrait, isStarSales, selectedCurrency, selectedScale, priceKey, salesStats } = this.props;
  	const unit = selectedCurrency === 0
			? ' USD'
			: selectedCurrency === 1
				? ' BTC'
				: ' ETH';
		const chartMargin = isMobile && !isTablet
			? { top: 5, right: 3, bottom: 5, left: 5 }
			: { top: 5, right: 20, bottom: 15, left: 10 };
		const yAxisWidth = isMobile && !isTablet ? 55 : 100;
		const height = isMobile && !isTablet 
			? isPortrait
				? 500 
				: 300
			: 550;
		const aspect = isMobile && !isTablet 
			? isPortrait
				? 0.7 
				: 2.25
			: 2;
		const domain = selectedScale !== 0 ? ['auto', 'auto'] : [0, (isStarSales ? this.calcUpperBound() : 'auto')];
  	return (
	  	<Container style={{ paddingLeft: '5px', paddingRight: '5px' }}>
	  		<ResponsiveContainer 
	  			height={height}
	  			aspect={aspect}
	  		>
	        <ScatterChart margin={chartMargin}>
				    <CartesianGrid 
				    	strokeDasharray='3 3'
				    	stroke='rgb(102, 102, 102)'
				    />
				    <XAxis 
				    	type='category'
				    	dataKey='date'
				    	allowDuplicatedCategory={false}
				    />
				    <YAxis 
				    	type='number' 
				    	dataKey={priceKey}
				    	width={yAxisWidth}
				    	unit={unit}
				    	tickFormatter={tick => selectedCurrency === 0 ? '$' + this.addCommasToPoint(tick.toString()) : tick.toString()}
				    	scale={selectedScale === 0 ? 'auto' : 'log'}
				    	domain={domain as [AxisDomain, AxisDomain]}
				    />
				    <Scatter 
				    	data={salesStats.sales}
				    	strokeWidth='2'
				    	fill='#FFFFFF'
				    	stroke='#343A40'
				    	onClick={this.clickedShipSale}
				    	shape={(props: any) =>
				    		<ShipSaleDot
				    			selectedPlanetSale={this.state.selectedShipSale}
				    			props={props}
				    		/>
				    	}
				    />
				    <Tooltip 
				    	cursor={isMobile ? false : { strokeDasharray: '3 3' }}
				    	wrapperStyle={{ pointerEvents: 'bounding-box', cursor: 'pointer' }}
				    	content={(active: any, payload: any) => 
				    		<ShipSaleTooltip
				    			selectedShipSale={this.state.selectedShipSale}
				    			selectedCurrency={selectedCurrency}
				    			salesStats={salesStats}
				    			priceKey={priceKey}
				    			active={active}
				    			payload={payload}
				    		/>
				    	}
				    />
				  </ScatterChart>
			  </ResponsiveContainer>
			</Container>
		);
	}

	private calcUpperBound = (): number => {
		const { priceKey, salesStats } = this.props;
		const sortedSales = salesStats.sales
			.map(sale => sale[priceKey])
			.filter(price => price > 0)
			.sort((a, b) => (a as number) - (b as number));
		const lowSale = sortedSales.pop();
		const minPrice = lowSale === undefined ? 0 : lowSale;
		const maxPrice = sortedSales[0];
		const ceil = Math.ceil((minPrice as number) + (maxPrice as number));
		return Math.round(ceil / 100) * 100;
	}

	private clickedShipSale = (dot: any) => {
		if (!!dot && !!dot.points && dot.points.length > 0) {
			const selectedShipSale = dot.date === this.state.selectedShipSale.date && dot.priceEth === this.state.selectedShipSale.priceEth
				? Object() as ShipSale
				: {
					date: dot.date,
					priceEth: dot.priceEth,
					priceBtc: dot.priceBtc,
					priceUsd: dot.priceUsd,
					txHashes: dot.txHashes,
					points: dot.points
				} as ShipSale;
	  	this.setState({ selectedShipSale });
	  	this.props.clickedShipSale(dot);
	  }
  }

  private addCommasToPoint = (point: string): string => {
  	return point.split('').reverse().map((char, i) => 
  		i % 3 === 0 && i !== 0 ? char + ',' : char
  	).reverse().join('');
  }

}

export default ShipSalesChart;