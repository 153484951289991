import React, { Component } from 'react';
import { sigil, reactRenderer } from 'urbit-sigil-js';

interface Props {
	patp: string;
	size: number;
	color?: string;
	margin?: number;
	display?: string;
	paddingLeft?: string;
}

class Sigil extends Component<Props> {
  public render() {
  	const bgColor = !!this.props.color
  		? this.props.color
  		: '#4330FC';
  	const bgMargin = !!this.props.margin
  		? this.props.margin
  		: this.props.size * 0.2;
  	const display = !!this.props.display
  		? this.props.display
  		: 'inherit';
  	const paddingLeft = !!this.props.paddingLeft
  		? this.props.paddingLeft
  		: 'inherit';
    return (
	    <div style={{ display, paddingLeft }}>
	      {sigil({ 
	      	patp: this.props.patp,
	      	size: this.props.size,
	      	margin: bgMargin,
	      	renderer: reactRenderer,
	      	colors: [bgColor, '#FFFFFF']
	      })}
	    </div>
    );
  }
}

export default Sigil;