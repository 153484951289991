import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Row, DropdownButton, Dropdown, Form, Button, ButtonGroup, Container, Tooltip, OverlayTrigger } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/BuyControlPanel.css';

interface Props {
	sortBySelected:((sortIdx: number, sortAsc: boolean) => void);
	layoutButtonSelected:((layout: number) => void);
	helpButtonSelected:(() => void);
	searchResultsTitle: string;
	starMode: boolean;
	listMyStarButtonSelected:(() => void);
}

interface State {
	selectedLayout: number;
	sortIdx: number;
	sortAsc: boolean;
}

class BuyControlPanel extends Component<Props, State> {
	public state = {
		selectedLayout: isMobile && !isTablet ? 1 : 0,
		sortIdx: 0,
		sortAsc: true
	}

  public render() {
  	const marginTop = isMobile && !isTablet
  		? { marginTop: '5px' }
  		: { marginTop: '30px' };
    return (
    	<Container style={{ display: 'inline-block' }}>
	    	<div 
	    		className='buy-control-panel-container'
	    		style={marginTop}
	    	>
		    	{this.generatePanel()}
	    	</div>
    	</Container>
    );
  }

  private generatePanel = (): JSX.Element => {
  	const { selectedLayout, sortAsc, sortIdx } = this.state;
  	const titleClassname = this.props.searchResultsTitle === 'Planets available to purchase'
  		? 'control-panel-title-buy'
  		: 'control-panel-title-search';
  	const label = sortIdx === 0 ? 'Name' : sortIdx === 1 ? 'Point' : 'Parent';
  	const arrow = sortAsc ? '\u2193' : '\u2191';
  	const dropdownButtonTitle = `Sort by: ${label}  ${arrow}`;
  	return (
  		<Row>
	  		<ButtonGroup className='dropdown-button-group'>
	  			<DropdownButton 
	  				id='bg-nested-dropdown'
	  				title={dropdownButtonTitle}
	  				variant='dark'
	  				className='sort-by-dropdown'
	  				as={ButtonGroup}
	  			>
					  <Dropdown.Item 
					  	as='button'
					  	onClick={this.clickedSortButton}
					  	id='0'
					  	className={`${sortIdx === 0 ? 'sort-by-dropdown-item-selected' : 'sort-by-dropdown-item'}`}
					  >
					  	{`Planet name${sortIdx === 0 ? arrow : ''}`}
					  </Dropdown.Item>
					  <Dropdown.Item 
					  	as='button'
					  	onClick={this.clickedSortButton}
					  	id='1'
					  	className={`${sortIdx === 1 ? 'sort-by-dropdown-item-selected' : 'sort-by-dropdown-item'}`}
					  >
					  	{`Azimuth point${sortIdx === 1 ? arrow : ''}`}
					  </Dropdown.Item>
					  <Dropdown.Item 
					  	as='button'
					  	onClick={this.clickedSortButton}
					  	id='2'
					  	className={`${sortIdx === 2 ? 'sort-by-dropdown-item-selected' : 'sort-by-dropdown-item'}`}
					  >
					  	{`Parent name${sortIdx === 2 ? arrow : ''}`}
					  </Dropdown.Item>
					</DropdownButton>
				</ButtonGroup>
				{isMobile && !isTablet
					? null
					: <div className={titleClassname}>
							{this.props.searchResultsTitle}
						</div>}
				{this.generateHelpButton()}
				{this.props.starMode
					? this.generateListMyStarButton()
					: this.generateLayoutButtons()}
  		</Row>
		);
  }

  private generateHelpButton = (): JSX.Element => {
  	const helpButton = 
  		<Button 
    		onClick={this.props.helpButtonSelected}
    		variant='dark'
    		className='buy-help-button'
    	>
				<i className='far fa-question-circle'/>						
			</Button>;
  	return isMobile
  		? helpButton
  		: <OverlayTrigger
		      key='help-tooltip-trigger'
		      // placement='bottom'
		      trigger={['hover', 'focus']}
		      overlay={
		        <Tooltip id='tooltip-top'>
							<strong>{'Need help? '}</strong>{`${isMobile ? 'Tap' : 'Click'} here to learn how to buy a planet.`}
		        </Tooltip>
		      }
		    >
					{helpButton}
				</OverlayTrigger>;
  }

  private generateLayoutButtons = (): JSX.Element => {
  	const gridButton = 
  		<Button 
    		onClick={this.clickedLayoutButton}
    		variant={this.state.selectedLayout === 0 ? 'light' : 'dark'}
    		className='grid-layout-button'
    		id='0'
    	>
				<i className='fas fa-th'/>						
			</Button>;
		const listButton = 
			<Button 
    		onClick={this.clickedLayoutButton}
    		variant={this.state.selectedLayout === 1 ? 'light' : 'dark'}
    		className='list-layout-button'
    		id='1'
    	>
				<i className='far fa-list-alt'/>
			</Button>;
  	return isMobile
  		? <ButtonGroup className='layout-button-group'>
  				{gridButton}
  				{listButton}
  			</ButtonGroup>
  		: <ButtonGroup className='layout-button-group'>
	  			<OverlayTrigger
			      key='grid-tooltip-trigger'
			      // placement='top'
			      trigger={['hover', 'focus']}
			      overlay={
			        <Tooltip id='tooltip-top'>
								Show planet <strong>grid</strong>
			        </Tooltip>
			      }
			    >
						{gridButton}
					</OverlayTrigger>
					<OverlayTrigger
			      key='list-tooltip-trigger'
			      // placement='top'
			      trigger={['hover', 'focus']}
			      overlay={
			        <Tooltip id='tooltip-top'>
								Show planet <strong>list</strong>
			        </Tooltip>
			      }
			    >
						{listButton}
					</OverlayTrigger>
				</ButtonGroup>;
  }

  private generateListMyStarButton = (): JSX.Element => {
		const listMyStarButton = 
			<Button 
    		onClick={this.clickedListMyStarButton}
    		variant={this.state.selectedLayout === 1 ? 'light' : 'dark'}
    		className='list-star-button'
    	>
				<i className='fas fa-file-invoice-dollar'/>
			</Button>;
  	return listMyStarButton;
  }

  private clickedSortButton = (e: React.MouseEvent<HTMLElement>) => {
  	const sortIdx = Number(e.currentTarget.id);
  	const sortAsc = this.state.sortIdx === sortIdx
  		? !this.state.sortAsc
  		: true;
  	this.setState({ sortIdx, sortAsc });
		this.props.sortBySelected(sortIdx, sortAsc);
  }

  private clickedLayoutButton = (e: React.MouseEvent<HTMLElement>) => {
  	const selectedLayout = Number(e.currentTarget.id);
  	this.setState({ selectedLayout })
  	this.props.layoutButtonSelected(selectedLayout);
  }

  private clickedListMyStarButton = (e: React.MouseEvent<HTMLElement>) => {
  	this.props.listMyStarButtonSelected();
  }

}

export default BuyControlPanel;
