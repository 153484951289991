import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Container, Row, ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import ob from 'urbit-ob';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/Stats.css';

interface Props {
	selectedScale: number;
	selectedCurrency: number;
	startDate: Date;
	endDate: Date;
	setStartDate:((startDate: Date) => void);
	setEndDate:((endDate: Date) => void);
	clickedScaleButton:((e: React.MouseEvent<HTMLElement>) => void);
	clickedCurrencyButton:((e: React.MouseEvent<HTMLElement>) => void);
}

class StatsControlPanel extends Component<Props, {}> {

  public render() {
  	const style = isMobile && !isTablet
  		? { display: 'inline-block' }
  		: { display: 'inline-block', paddingRight: '0px', paddingLeft: '0px' };
  	return (
  		<Container style={style}>
  			{isMobile && !isTablet
  				? this.generateMobileControlPanel()
  				: this.generateControlPanel()}
			</Container>
		);
  }

  private generateControlPanel = (): JSX.Element => {
  	return (
  		<Container style={{ display: 'inline-block', paddingRight: '0px', paddingLeft: '0px' }}>
  			<div className='stats-control-panel-container'>
		  		<Row>
		  			{this.generateDatePickers()}
						{this.generateScaleButtons()}
			  		{this.generateCurrencyButtons()}
					</Row>
				</div>
			</Container>
  	);
  }

  private generateMobileControlPanel = (): JSX.Element => {
  	return (
  		<Container style={{ display: 'inline-block' }}>
  			<div className='stats-control-panel-container-mobile'>
		  		<Row className='stats-control-panel-top-row-mobile'>
		  			{this.generateDatePickers()}
		  		</Row>
		  		<Row className='stats-control-panel-second-row-mobile'>
						{this.generateScaleButtons()}
			  		{this.generateCurrencyButtons()}
			  	</Row>
				</div>
			</Container>
  	);
  }

  private generateDatePickers = (): JSX.Element => {
  	const { startDate, endDate } = this.props;
  	const customOpenButton = 
  		<Button variant='dark'>
				{`From: ${startDate.toLocaleDateString()}`}
			</Button>;
		const customEndButton = 
			<Button variant='dark'>
				{`To: ${endDate.toLocaleDateString()}`}
			</Button>;
		const classNameSuffix = isMobile && !isTablet ? '-mobile' : '';
  	return (
  		<Row className={`stats-date-picker-button-group${classNameSuffix}`}>
	  		<div className={`start-date-picker-button${classNameSuffix}`}>
	  			<DatePicker
       			customInput={customOpenButton}
						selected={startDate} 
						onChange={this.props.setStartDate}
						minDate={new Date(2018, 10, 27)}
						maxDate={new Date()}
					/>
				</div>
				<div className={`end-date-picker-button${classNameSuffix}`}>
	  			<DatePicker 
	  				customInput={customEndButton}
						selected={endDate} 
						onChange={this.props.setEndDate}
						minDate={new Date(2018, 10, 28)}
						maxDate={new Date()}
						popperPlacement='auto-left'
						popperModifiers={{
			        offset: {
				        enabled: true,
				        offset: '-15px, 0px'
				      }
				    }}
					/>
				</div>
			</Row>
		);
  }

  private generateScaleButtons = (): JSX.Element => {
  	const { selectedScale } = this.props;
  	const linearIconColor = selectedScale === 0 ? 'black' : 'white';
  	const logIconColor = selectedScale === 1 ? 'black' : 'white';
  	const linearIconRaw = 
  		<svg 
  			x='0px'
  			y='0px'
  			viewBox='0 0 100 100'
  		>
  			<g>
	  			<polygon 
	  				points='95 95 5 95 5 5 10 5 10 90 95 90'
	  				stroke={linearIconColor}
	  				strokeWidth='4'
	  				strokeLinejoin='round'
	  			/>
	  			<polygon 
	  				points='25 65 22 61 82 36 85 40'
	  				stroke={linearIconColor}
	  				strokeWidth='4'
	  				strokeLinejoin='round'
	  			/>
  			</g>
  		</svg>;
  	const logIconRaw =
  		<svg 
  			x='0px'
  			y='0px'
  			viewBox='0 0 100 100'
  		>
  			<g>
  				<path 
  					d='M90.9,90.5h-81c-0.3,0-0.5-0.2-0.5-0.5V10c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v79.5h80.5c0.3,0,0.5,0.2,0.5,0.5   S91.2,90.5,90.9,90.5z'
  					stroke={logIconColor}
  					strokeWidth='5'
  				/>
  			</g>
  			<g>
  				<path 
	  				d='M13.1,86.8C13.1,86.8,13.1,86.8,13.1,86.8c-0.3,0-0.5-0.2-0.5-0.5c0-0.2,0.7-17.5,10-34.5c8.6-15.8,26.5-34.6,63-34.6   c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5c-36,0-53.7,18.5-62.1,34c-9.2,16.8-9.9,33.9-9.9,34.1C13.6,86.6,13.4,86.8,13.1,86.8z'
	  				stroke={logIconColor}
	  				strokeWidth='5'
  				/>
  			</g>
  		</svg>;
  	const classNameSuffix = isMobile && !isTablet ? '-mobile' : '';
  	const linearButton =
  		<Button 
    		onClick={this.props.clickedScaleButton}
    		variant={selectedScale === 0 ? 'light' : 'dark'}
    		className={`scale-button${classNameSuffix}`}
    		id='0'
    	>
    		<div className={`scale-button-img${classNameSuffix}`}>{linearIconRaw}</div>
			</Button>;
		const logButton = 
			<Button 
    		onClick={this.props.clickedScaleButton}
    		variant={selectedScale === 1 ? 'light' : 'dark'}
    		className={`scale-button${classNameSuffix}`}
    		id='1'
    	>
				<div className={`scale-button-img${classNameSuffix}`}>{logIconRaw}</div>
			</Button>;
  	return isMobile
  		? <ButtonGroup className={`scale-button-group${classNameSuffix}`}>
  				{linearButton}
  				{logButton}
  			</ButtonGroup>
  		: <ButtonGroup className={`scale-button-group${classNameSuffix}`}>
  			<OverlayTrigger
		      key={'linear-tooltip-trigger'}
		      // placement={'top'}
		      trigger={['hover', 'focus']}
		      overlay={
		        <Tooltip id={'tooltip-top'}>
							Use <strong>linear</strong> scale
		        </Tooltip>
		      }
		    >
					{linearButton}
				</OverlayTrigger>
				<OverlayTrigger
		      key={'log-tooltip-trigger'}
		      // placement={'top'}
		      trigger={['hover', 'focus']}
		      overlay={
		        <Tooltip id={'tooltip-top'}>
							Use <strong>log</strong> scale
		        </Tooltip>
		      }
		    >
					{logButton}
				</OverlayTrigger>
			</ButtonGroup>;
  }

  private generateCurrencyButtons = (): JSX.Element => {
  	const buttonDetails = [{
  		tooltipMsg: 'US dollars',
  		keyPrefix: 'dollar',
  		iconClassName: 'fas fa-dollar-sign'
  	}, {
  		tooltipMsg: 'Bitcoin',
  		keyPrefix: 'bitcoin',
  		iconClassName: 'fab fa-bitcoin'
  	}, {
  		tooltipMsg: 'Ether',
  		keyPrefix: 'ether',
  		iconClassName: 'fab fa-ethereum'
  	}];
  	const buttons = buttonDetails.map((buttonDetail, idx) => {
  		const button = 
  			<Button 
      		onClick={this.props.clickedCurrencyButton}
      		variant={this.props.selectedCurrency === idx ? 'light' : 'dark'}
      		className={`currency-button${idx === 1 ? '-bitcoin' : ''}-mobile`}
      		id={idx.toString()}
      		key={idx.toString()}
      	>
					<i className={buttonDetail.iconClassName}/>
				</Button>;
  		return isMobile
				? button
				: <OverlayTrigger
			      key={`${idx}-${buttonDetail.keyPrefix}-tooltip-trigger`}
			      // placement={'top'}
			      trigger={['hover', 'focus']}
			      overlay={
			        <Tooltip id={'tooltip-top'}>
								Show prices in <strong>{buttonDetail.tooltipMsg}</strong>
			        </Tooltip>
			      }
			    >
						{button}
					</OverlayTrigger>
  	});
  	return (
  		<ButtonGroup className={`currency-button-group${isMobile ? '-mobile' : ''}`}>
  			{buttons}
			</ButtonGroup>
  	);
  }

}

export default StatsControlPanel;