import React, { Component } from 'react';
import { ShipSale } from './types/stats';

interface Props {
	selectedPlanetSale: ShipSale;
	props: any;
}

class ShipSaleDot extends Component<Props, {}> {

  public render() {
  	const { selectedPlanetSale, props } = this.props;
		const radius = props.points.length > 7 ? 12 : (4 + props.points.length);
		const fillColor = 
			props.date === selectedPlanetSale.date && 
			props.priceEth === selectedPlanetSale.priceEth
				? '#cc0000' 
				: props.fill;
		return (
			props.priceEth > 0
				? <circle 
						cx={props.cx}
						cy={props.cy}
						r={radius}
						stroke={props.stroke}
						strokeWidth={props.strokeWidth}
						fill={fillColor}
						cursor='pointer'
					/>
				: null
		);
  }

}

export default ShipSaleDot;