import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import { Alert } from 'react-bootstrap';
import BN from 'bn.js';
import Web3 from 'web3';
import { Home, Header, Buy, Explore, ShipView, Stats, AddressView, Help } from './components';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

interface HeaderMetadata {
	activeLink: number;
	searchMode: number;
	title: string;
	scope: number;
	query?: string;
}

interface Wallet { 
	web3Enabled: boolean;
	networkId: number;
	account: string;
}

interface SearchRequest {
	scope: string;
	query: string;
	page: string;
	sortBy: string;
	sortDir: string;
	[key: string]: string;
}

interface TransactionParams { 
	to: string;
	from: string;
	value: string;
	data: string;
}

interface State {
	wallet: Wallet;
	walletIsMetamask: boolean;
	headerMetadata: HeaderMetadata;
	showTxConfirmation: boolean;
	showNoWalletModal: boolean;
	txHash: string;
	showDebug: boolean;
	debugMsg: string;
	web3Available: boolean;
}

class App extends Component<{}, State> {
	private constants = {
		developmentAPIURL: 'http://localhost:3001',
		stagingAPIURL: 'https://stagingapi.urbit.live',
		productionAPIURL: 'https://api.urbit.live',
		purchaseMethodID: '0xc7f04e65',
		web3Method: 'eth_sendTransaction'
	};

	public state = {
		wallet: Object() as Wallet,
		walletIsMetamask: false,
		headerMetadata: Object() as HeaderMetadata,
		showTxConfirmation: false,
		showNoWalletModal: false,
		txHash: '',
		showDebug: false,
		debugMsg: '',
		web3Available: false
	};

	public componentDidMount = () => {
	  const wndw = (window as any);
		if (!!wndw.ethereum || !!wndw.web3) {
	  	this.setState({ web3Available: true });
	  }
	}

	public render() {
		const { headerMetadata, showTxConfirmation, showNoWalletModal, showDebug, web3Available } = this.state;
		const { productionAPIURL, stagingAPIURL, developmentAPIURL } = this.constants;
		const apiURL = process.env.REACT_APP_ENV === 'prod'
			? productionAPIURL
			: process.env.REACT_APP_ENV === 'staging' 
				? stagingAPIURL 
				: developmentAPIURL;
		const wallet = this.parseNetworkId();
		return (
			<BrowserRouter>
				<div className='UrbitLive'>
			  	{showTxConfirmation
	        	? this.generateTxSuccessModal()
	        	: null}
	        {showNoWalletModal
	        	? this.generateNoWalletModal()
	        	: null}
	        {showDebug
	        	? this.generateDebugModal()
	        	: null}
		  		<Header
		  			apiBaseURL={apiURL}
						metadata={headerMetadata}
						validateName={this.validateName}
					/>
			  	<Switch>
			      <Route 
			      	exact 
			      	path='/' 
			      	render={props => 
			      		<Home
			      			updateHeader={this.updateHeader}
			      			validateName={this.validateName}
			      		/>
			      	}
			      />
			      <Route
			      	exact
			      	path='/buy' 
			      	render={props => 
			      		<Buy
			      			apiBaseURL={apiURL}
			      			wallet={wallet}
			      			updateHeader={this.updateHeader}
			      			searchRequest={this.parseURLString(window.location.search)}
			      			initiateTx={this.initiateTx}
			      		/>
			      	}
			      />
			      <Route
			      	exact
			      	path='/help' 
			      	render={props => 
			      		<Help
			      			web3Enabled={web3Available}
				      		updateHeader={this.updateHeader}
			      		/>
			      	}
			      />
			      <Route 
			      	exact
			      	path='/stats' 
			      	render={props => 
			      		<Stats
			      			apiBaseURL={apiURL}
			      			updateHeader={this.updateHeader}
			      		/>
			      	}
			      />
			      <Route 
			      	path='/explore' 
			      	render={props => 
			      		<Explore
			      			apiBaseURL={apiURL}
			      			updateHeader={this.updateHeader}
			      			searchRequest={this.parseURLString(window.location.search)}
			      		/>
			      	}
			      />
			    	<Route 
			    		path='/address/:address' 
			    		render={props => 
			    			<AddressView 
			    				apiBaseURL={apiURL}
			    				updateHeader={this.updateHeader}
			    			/>
			    		}
			    	/>
			    	<Route 
			    		path='/:ship' 
			    		render={props => 
			    			<ShipView
			    				apiBaseURL={apiURL}
			    				web3Available={web3Available}
			    				updateHeader={this.updateHeader}
			    				initiateTx={this.initiateTx}
			    			/>
			    		}
			    	/>
			  	</Switch>
		  	</div>
		  </BrowserRouter>
    );
  }

  private parseNetworkId = (): Wallet => {
		const wndw = (window as any);
		const web3Enabled = !!wndw.ethereum || !!wndw.web3;
		const networkId = web3Enabled
	  	? !!wndw.ethereum
	  		? !!wndw.ethereum.networkVersion
					? Number(wndw.ethereum.networkVersion)
					: 1
				: !!wndw.web3.currentProvider.networkVersion
					? Number(wndw.web3.currentProvider.networkVersion)
					: 1
			: process.env.REACT_APP_ENV === 'prod'
				? 1
				: 42;
		return {
			web3Enabled,
			account: '',
			networkId
		};
	}

  private generateTxSuccessModal = (): JSX.Element => {
  	const { txHash, wallet } = this.state;
  	const subdomain = wallet.networkId === 42
			? 'kovan.' 
			: '';
  	const marginTop = isMobile ? '20px' : '10px';
  	return (
	  	<Alert 
	  		dismissible 
	  		key='0' 
	  		variant='success'
	  		onClose={() => this.setState({ showTxConfirmation: false })}
	  		style={{ position: 'fixed', marginTop, zIndex: 100, width: '100%' }}
	  	>
		    <div>
			    {'Your transaction was successfully broadcasted  '}
			    <Alert.Link
			    	href={`https://${subdomain}etherscan.io/tx/${txHash}`}
			    	target='_blank'
			    >
			    	{' View on Etherscan'}
			    </Alert.Link>
		    </div>
		    <div>
			    Join our 
			    <Alert.Link
			    	href='https://t.me/UrbitLiveGroup'
			    	target='_blank'
			    >
			    	{'  Telegram group  '}
			    </Alert.Link>
			    for support
	    	</div>
		  </Alert>
	  );
  }

  private generateNoWalletModal = (): JSX.Element => {
  	const marginTop = isMobile ? '20px' : '10px';
  	const mobileStyle = {
  		fontSize: '0.8rem',
  		textAlign: 'left'
  	} as React.CSSProperties;
  	const divStyle = isMobile && !isTablet ? mobileStyle : {};
  	return (
	  	<Alert 
	  		dismissible 
	  		key='0' 
	  		variant='secondary'
	  		onClose={() => this.setState({ showNoWalletModal: false })}
	  		style={{ position: 'fixed' as 'fixed', marginTop, zIndex: 100, width: '100%', paddingRight: '2.5rem' }}
	  	>
		    <div style={divStyle}>
			    To purchase a planet you need an Ethereum web3 wallet.
		    </div>
		    <div style={divStyle}>
			    Install
			    <Alert.Link
			    	href='https://metamask.io/'
			    	target='_blank'
			    >
			    	{' Metamask'}
			    </Alert.Link>
			    . More info
			    <Alert.Link
			    	href='/help'
			    	target='_self'
			    >
			    	{' here'}
			    </Alert.Link>
			    .
	    	</div>
		  </Alert>
	  );
  }

  private generateDebugModal = (): JSX.Element => {
  	const marginTop = isMobile ? '20px' : '10px';
  	return (
	  	<Alert
	  		dismissible
	  		key='0'
	  		variant='success'
	  		onClose={() => this.setState({ showDebug: false })}
	  		style={{ position: 'fixed' as 'fixed', marginTop, zIndex: 100, width: '100%' }}
	  	>
		    {this.state.debugMsg}
		  </Alert>
	  );
  }

  private updateHeader = (headerMetadata: HeaderMetadata) => this.setState({ headerMetadata });

  private parseURLString = (urlString: string): SearchRequest => {
  	let params: SearchRequest = Object();
  	if (!!window.location.search) {
  		const regex = /[?&]([^=#]+)=([^&#]*)/g;
			let matchArray: RegExpExecArray | null;
			while (!!(matchArray = regex.exec(window.location.search))) {
			  params[matchArray[1]] = matchArray[2];
			}
			if (!!params && !!params.query && params.query.length > 2) {
				const cleanedQuery = params.query.toLowerCase().match(/[a-z~-]/g);
				const query = !!cleanedQuery ? cleanedQuery.reduce((a, c) => a + c) : '';
				const page = !!params.page && Number.isInteger(Number(params.page)) ? params.page : '1';
				const cleanedSortBy = !!params.sortDir ? params.sortBy.toLowerCase().match(/[a-z]/g) : null;
				const unvalidatedSortBy = !!cleanedSortBy ? cleanedSortBy.reduce((a, c) => a + c) : '';
				const sortBy = (unvalidatedSortBy === 'name' || unvalidatedSortBy === 'point' || unvalidatedSortBy === 'parent') ? unvalidatedSortBy : 'name';
				const cleanedSortDir = !!params.sortDir ? params.sortDir.toLowerCase().match(/[ascde]/g) : null;
				const unvalidatedSortDir = !!cleanedSortDir ? cleanedSortDir.reduce((a, c) => a + c) : '';
				const sortDir = (unvalidatedSortDir === 'asc' || unvalidatedSortDir === 'desc') ? unvalidatedSortDir : 'asc';
				const scopeJSON = !!params.scope && params.scope !== 'spawning' ? this.safeParseJSON(params.scope) : false;
				const searchScope = scopeJSON && scopeJSON.length > 0 ? JSON.stringify(scopeJSON) : '[0]';
				const scope = scopeJSON ? searchScope : 'spawning';
				return {
					scope,
					query,
					page,
					sortBy,
					sortDir
				};
			} 
		}
		return params;
  }

  private safeParseJSON = (subject: string) => {
  	try {
  		return JSON.parse(subject);
  	} catch (e) {
  		console.log('error', e);
  		return false;
  	}
  }

  private parseWindowWeb3 = () => {
  	return new Promise((resolve, reject) => {
	  	const wndw = (window as any);
			if (!!wndw.ethereum) {
				const ethereum = wndw.ethereum;
				ethereum.enable().then((accounts: string[]) => {
					const networkId = !!ethereum.networkVersion
						? Number(ethereum.networkVersion)
						: 1;
					const wallet = {
						web3Enabled: true,
						account: accounts[0],
						networkId
					};
					this.setState({
						wallet,
						walletIsMetamask: true
					}, () => resolve(ethereum));
				}).catch((error: Error) => reject(error));
			} else if (!!wndw.web3 && !!wndw.web3.currentProvider) {
				const web3 = new Web3(wndw.web3.currentProvider);
				web3.eth.net.getId().then((chainID: number) => {
					const account = !!web3.eth.defaultAccount
						? web3.eth.defaultAccount
						: '';
					const wallet = {
						web3Enabled: true,
						networkId: chainID,
						account
					};
					this.setState({ 
						wallet,
						walletIsMetamask: false
					}, () => resolve(web3.eth));
				}).catch((error: Error) => reject(error));
			} else {
				reject('Web3 wallet not found');
			}
		});
  }

  private validateName = (input: string): boolean => {

		const lcInput = input.toLowerCase();
		const charArray = lcInput.split('');
		const wordArray = lcInput.split('-');
		const tildeIdx = charArray.indexOf('~');
		const hyphenIdx = charArray.indexOf('-');

		const invalidChars = /([^a-z~-])/g.test(lcInput);
		const repeatedPunc = /(~|-)+[a-z]*(\1)/g.test(lcInput);
		const letterArray = lcInput.match(/([a-z])/g);

		if ((lcInput.length === 0 ||
				(!!letterArray && letterArray.length === 0)) &&
				!invalidChars) {
			return true;
		}

		const wordTooLong = wordArray.some(word => (word.match(/[a-z]/g)||[]).length > 6);
		const vowelError = wordArray.some(word => 
			((word.match(/[a-z]/g)||[]).length > 4 &&
			(word.match(/[aeiouy]/g)||[]).length < 2) ||
			(word.match(/[aeiouy][^aeiouy]{1}[aeiouy]/g)||[]).length > 0);
		const firstWordTooShort = 
			tildeIdx === 0 &&
			hyphenIdx !== -1 &&
			(hyphenIdx - tildeIdx !== 7);

		if (tildeIdx > 0 ||  // tilde not first character
				wordTooLong ||  // word has more than six characters
				vowelError ||  // word has 5 or 6 characters
				firstWordTooShort || // less than 6 characters between tilde and hyphen 
				invalidChars ||  // invalid characters
				repeatedPunc) {  // multiple hyphens or tildes
			return false;
		}

		const validPrefixes = ['doz','mar','bin','wan','sam','lit','sig','hid','fid','lis','sog','dir','wac','sab','wis','sib','rig','sol','dop','mod','fog','lid','hop','dar','dor','lor','hod','fol','rin','tog','sil','mir','hol','pas','lac','rov','liv','dal','sat','lib','tab','han','tic','pid','tor','bol','fos','dot','los','dil','for','pil','ram','tir','win','tad','bic','dif','roc','wid','bis','das','mid','lop','ril','nar','dap','mol','san','loc','nov','sit','nid','tip','sic','rop','wit','nat','pan','min','rit','pod','mot','tam','tol','sav','pos','nap','nop','som','fin','fon','ban','mor','wor','sip','ron','nor','bot','wic','soc','wat','dol','mag','pic','dav','bid','bal','tim','tas','mal','lig','siv','tag','pad','sal','div','dac','tan','sid','fab','tar','mon','ran','nis','wol','mis','pal','las','dis','map','rab','tob','rol','lat','lon','nod','nav','fig','nom','nib','pag','sop','ral','bil','had','doc','rid','moc','pac','rav','rip','fal','tod','til','tin','hap','mic','fan','pat','tac','lab','mog','sim','son','pin','lom','ric','tap','fir','has','bos','bat','poc','hac','tid','hav','sap','lin','dib','hos','dab','bit','bar','rac','par','lod','dos','bor','toc','hil','mac','tom','dig','fil','fas','mit','hob','har','mig','hin','rad','mas','hal','rag','lag','fad','top','mop','hab','nil','nos','mil','fop','fam','dat','nol','din','hat','nac','ris','fot','rib','hoc','nim','lar','fit','wal','rap','sar','nal','mos','lan','don','dan','lad','dov','riv','bac','pol','lap','tal','pit','nam','bon','ros','ton','fod','pon','sov','noc','sor','lav','mat','mip','fip'];
		const validSuffixes = ['zod','nec','bud','wes','sev','per','sut','let','ful','pen','syt','dur','wep','ser','wyl','sun','ryp','syx','dyr','nup','heb','peg','lup','dep','dys','put','lug','hec','ryt','tyv','syd','nex','lun','mep','lut','sep','pes','del','sul','ped','tem','led','tul','met','wen','byn','hex','feb','pyl','dul','het','mev','rut','tyl','wyd','tep','bes','dex','sef','wyc','bur','der','nep','pur','rys','reb','den','nut','sub','pet','rul','syn','reg','tyd','sup','sem','wyn','rec','meg','net','sec','mul','nym','tev','web','sum','mut','nyx','rex','teb','fus','hep','ben','mus','wyx','sym','sel','ruc','dec','wex','syr','wet','dyl','myn','mes','det','bet','bel','tux','tug','myr','pel','syp','ter','meb','set','dut','deg','tex','sur','fel','tud','nux','rux','ren','wyt','nub','med','lyt','dus','neb','rum','tyn','seg','lyx','pun','res','red','fun','rev','ref','mec','ted','rus','bex','leb','dux','ryn','num','pyx','ryg','ryx','fep','tyr','tus','tyc','leg','nem','fer','mer','ten','lus','nus','syl','tec','mex','pub','rym','tuc','fyl','lep','deb','ber','mug','hut','tun','byl','sud','pem','dev','lur','def','bus','bep','run','mel','pex','dyt','byt','typ','lev','myl','wed','duc','fur','fex','nul','luc','len','ner','lex','rup','ned','lec','ryd','lyd','fen','wel','nyd','hus','rel','rud','nes','hes','fet','des','ret','dun','ler','nyr','seb','hul','ryl','lud','rem','lys','fyn','wer','ryc','sug','nys','nyl','lyn','dyn','dem','lux','fed','sed','bec','mun','lyr','tes','mud','nyt','byr','sen','weg','fyr','mur','tel','rep','teg','pec','nel','nev','fes'];

		let failedPhoneme = '';
		const entryErrors = charArray.map((char0, idx, ary) => {
			let errors = 0;
			if (ary.length >= idx + 3 &&
					char0 !== '~' &&
					ary[idx + 1] !== '-' &&
					ary[idx + 2] !== '-') {
				const char1 = ary[idx + 1];
				const char2 = ary[idx + 2];
				if (['a', 'e', 'i', 'o', 'u', 'y'].includes(char1)) {
					const phoneme = char0 + char1 + char2;
					if (!validPrefixes.find(prefix => prefix === phoneme) &&
							!validSuffixes.find(suffix => suffix === phoneme)) {
						errors++;
						errors++;
						errors++;
					}
				}
				if (char0 !== '-' &&
						char1 !== '-' &&
						char2 !== '-') {
					const partialPhoneme0 = char0 + char1;
					const partialPhoneme1 = char1 + char2;
					const partial0IsPrefix = validPrefixes.find(prefix => prefix.includes(partialPhoneme0));
					const partial0IsSuffix = validSuffixes.find(suffix => suffix.includes(partialPhoneme0));
					const partial1IsPrefix = validPrefixes.find(prefix => prefix.includes(partialPhoneme1));
					const partial1IsSuffix = validSuffixes.find(suffix => suffix.includes(partialPhoneme1));
					if ((!partial0IsPrefix && !partial0IsSuffix && partialPhoneme0 !== failedPhoneme) ||
							(!partial1IsPrefix && !partial1IsSuffix && partialPhoneme1 !== failedPhoneme)) {
						if (!partial0IsPrefix && !partial0IsSuffix) {
							failedPhoneme = partialPhoneme0;
						} else {
							failedPhoneme = partialPhoneme1;
						}
						errors++;
					}
				}
			}
			if (ary.length >= idx + 2 &&
				  (char0 === '~' ||
					 char0 === '-')) {
				const char1 = ary[idx + 1];
				if (!validPrefixes.find(prefix => prefix.substring(0, 1) === char1)) {
					errors++;
				}
			}

			return errors;
		});

		const totalErrors = entryErrors.reduce((acc, val) => acc + val);

		if (charArray.length > 3 &&
				((totalErrors > (charArray.length - 3)) ||
				 (charArray.length > 10 &&
					totalErrors > (charArray.length - 7)))) {
			return false;
		}

		return true;
	}

  private initiateTx = (point: string) => {
  	const { wallet } = this.state;
  	const { purchaseMethodID, productionAPIURL, stagingAPIURL, developmentAPIURL } = this.constants;
  	const apiURL = process.env.REACT_APP_ENV === 'prod'
			? productionAPIURL
			: process.env.REACT_APP_ENV === 'staging' 
				? stagingAPIURL 
				: developmentAPIURL;
		this.parseWindowWeb3().then(ethereum => {
			fetch(`${apiURL}/contract?ship=${point}`)
				.then(res => res.json())
	      .then((contractData: { address: string, priceInEther: string }) => {
	      	const txValue = Web3.utils.numberToHex(Web3.utils.toBN(Web3.utils.toWei(contractData.priceInEther, 'ether')));
	      	const txData = purchaseMethodID + Web3.utils.padLeft((Web3.utils.numberToHex(parseInt(point))).slice(2), 64, '0');
	      	const txParams = {
	      		to: contractData.address,
	      		from: this.state.wallet.account,
	      		value: txValue,
			  		data: txData
				  };
				  this.sendTx(ethereum, txParams).then().catch(error => console.log('Tx error:', error));
	      }).catch((error: Error) => console.log(error));
    }).catch((error: Error) => {
    	console.log(error);
    	this.setState({ showNoWalletModal: true });
    });
	}

	private sendTx = (ethereum: any, txParams: TransactionParams): Promise<string> => {
		return new Promise((resolve, reject) => {
			if (this.state.walletIsMetamask) {
				ethereum.sendAsync({
				  method: this.constants.web3Method,
				  params: [txParams],
				  from: txParams.from
				}, (error: Error, response: { result: string, error: Error }) => {
					if (!error && !!response) {
						if (!response.error) {
							this.showTxModal(response.result);
							const wndw = (window as any);
							if (!!wndw._paq) { 
								wndw._paq.push(['trackEvent', 'transaction-sent', 'TransactionSent', response.result]); 
							}
							return resolve(response.result);
						} else {
							reject(response.error);
						}
					} else {
						reject(error);
					}
				});
			} else {
				const transactionParameters = {
				  to: txParams.to,
				  from: txParams.from
				}
				ethereum.sendTransaction({
					method: this.constants.web3Method,
				  params: [transactionParameters],
				  from: txParams.from,
				  to: txParams.to,
				  value: txParams.value,
				  data: txParams.data
				}, (error: Error, response: { result: string, error: Error }) => {
					if (!error && !!response) {
						if (!response.error) {
							this.showTxModal(response.result);
							const wndw = (window as any);
							if (!!wndw._paq) { 
								wndw._paq.push(['trackEvent', 'transaction-sent', 'TransactionSent', response.result]); 
							}
							return resolve(response.result);
						} else {
							reject(response.error);
						}
					} else {
						reject(error);
					}
				});
			}
		});
	}

  private showTxModal = (txHash: string) => {
  	this.setState({
			showTxConfirmation: true,
			txHash
		});
  }
}

export default App;
