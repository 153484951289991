import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Form, InputGroup, FormControl, Table, Row } from 'react-bootstrap';
import { Footer } from './';
import { HeaderMetadata } from './types/header';
import ob from 'urbit-ob';
import web3 from 'web3';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/Home.css';

interface Props {
	updateHeader:((metadata: HeaderMetadata) => void);
	validateName:((input: string) => boolean);
}

interface State {
	portrait: boolean;
	fixFooter: boolean;
	currentInput: string;
	validEntry: boolean;
	showInvalidQueryFeedback: boolean;
}

class Home extends Component<Props, State> {
	public state = {
		portrait: true,
		fixFooter: true,
		currentInput: '',
		validEntry: true,
		showInvalidQueryFeedback: false
	}

	public componentDidMount = () => {
	  window.addEventListener('resize', this.setOrientation);
		this.props.updateHeader({
			activeLink: 0, 
			searchMode: 0, 
			title: 'urbit live', 
			scope: 0
		});
		setTimeout(this.setOrientation, 500);
	}

	public componentWillUnmount() {
	  window.removeEventListener('resize', this.setOrientation);
	}

  public render() {
  	const { portrait, fixFooter, validEntry, currentInput } = this.state;
  	const scrollStyle = isMobile || isTablet 
  		? 'touch' 
  		: 'auto';
  	const paddingTop = isMobile && !isTablet
  		? '10px'
  		: '40px';
  	const homeViewStyle = { 
  		WebkitOverflowScrolling: scrollStyle, 
  		overflowScrolling: scrollStyle,
  		backgroundColor: '#212121',
  		paddingTop
  	} as React.CSSProperties;
  	const titleClass = isMobile && !isTablet
  		? 'title-container-mobile'
  		: 'title-container';
    return (
      <div style={homeViewStyle}>
      	<div className={titleClass}>
      		The universe's largest Urbit planet market
      	</div>
      	{isMobile && !isTablet
      		? this.generateMobileShopButton()
      		: this.generateDesktopShopButton()}
				<hr style={{ borderColor: 'grey', marginLeft: '5vw', marginRight: '5vw' }}/>
      	{isMobile && !isTablet
      		? this.generateMobileSearchField()
      		: this.generateDesktopSearchField()}
        <Footer fixedToBottom={fixFooter}/>
      </div>
    );
  }

  private generateDesktopShopButton = (): JSX.Element => {
  	return (
  		<div className='shop-button-container'>
      	<a 
      		className='jumbotron row shop-button'
      		href='/buy'
      	>
      		<div className='shop-button-icon-container'>
      			<i className='fas fa-globe-americas shop-button-icon'/>
      			<i className='fas fa-globe-asia shop-button-icon-middle'/>
      			<i className='fas fa-globe-europe shop-button-icon'/>
      		</div>
      		<div className='shop-button-text-container'>
					  <div className='shop-button-text-title'>Shop Urbit planets</div>
					  <p style={{ fontSize: '1.1rem' }}>Over 1 million planets available</p>
				  </div>
				  <div className='carat-container'>
				  	<i className='fas fa-angle-right'/>
				  </div>
			  </a>
			</div>
  	);
  }

  private generateMobileShopButton = (): JSX.Element => {
  	const className = this.state.portrait
  		? 'shop-button-container-mobile-portrait'
  		: 'shop-button-container-mobile-landscape'
  	return (
  		<div className={className}>
      	<a 
      		className='jumbotron row shop-button-mobile'
      		href='/buy'
      	>
      		<div className='shop-button-text-container-mobile'>
					  <div className='shop-button-text-title-mobile'>Shop Urbit planets</div>
					  <p style={{ fontSize: '0.8rem' }}>Over 1 million planets available</p>
				  </div>
				  <div className='carat-container-mobile'>
				  	<i className='fas fa-angle-right'/>
				  </div>
			  </a>
			</div>
  	);
  }

  private generateDesktopSearchField = (): JSX.Element => {
  	const { validEntry, currentInput, showInvalidQueryFeedback } = this.state;
  	return (
  		<div className='search-field-container'>
	  		<Form onSubmit={this.performSearch}>
	      	<InputGroup 
	      		size='lg'
	      		className='search-field-input-group'
	      	>
				    <InputGroup.Prepend>
				      <InputGroup.Text id='inputGroup-sizing-lg'>
								Search the Urbit network
				      </InputGroup.Text>
				    </InputGroup.Prepend>
				    <FormControl 
				    	type='search'
				    	placeholder="e.g. ~magmyr-darlex"
				    	className='search-field-form-control'
				    	spellCheck={false}
				    	autoCorrect='off'
				    	autoCapitalize='off'
				    	autoComplete='off'
				    	onInput={this.processInput}
				    	defaultValue={currentInput}
				    	isInvalid={!validEntry || showInvalidQueryFeedback}
				    	style={{ borderTopRightRadius: '0.3rem', borderBottomRightRadius: '0.3rem', cursor: 'pointer' }}
				    />
				    {showInvalidQueryFeedback
					  	? <Form.Control.Feedback 
					  			type='invalid'
					  			className='search-field-length-feedback'
					  		>
							  	Search must be at least three characters
							  </Form.Control.Feedback>
					  	: <Form.Control.Feedback 
					  			type='invalid'
					  			className='search-field-no-match-feedback'
					  		>
							  	{`${currentInput} has no matches `}
							  	<a
							  		href='https://medium.com/@urbitlive/an-intro-to-urbit-names-e54b06ec984b'
							  		target='_blank'
							  		style={{ color: 'white', marginLeft: '5px' }}
							  	>
							  		Learn why
							  	</a>
							  </Form.Control.Feedback>}
				  </InputGroup>
			  </Form>
	  	</div>
  	);
  }

  private generateMobileSearchField = (): JSX.Element => {
  	const { validEntry, currentInput, showInvalidQueryFeedback } = this.state;
  	return (
  		<div className='search-field-container-mobile'>
  			<div className='search-field-text-title-mobile'>
  				Search the Urbit network
  			</div>
	  		<Form onSubmit={this.performSearch}>
	      	<InputGroup 
	      		size='lg'
	      		className='search-field-input-group-mobile'
	      	>
	      		<InputGroup.Prepend>
				      <InputGroup.Text id='inputGroup-sizing-lg'>
								<i className='fas fa-search'/>
				      </InputGroup.Text>
				    </InputGroup.Prepend>
				    <FormControl 
				    	aria-label='Large'
				    	aria-describedby='inputGroup-sizing-lg'
				    	placeholder='e.g. ~binzod'
				    	spellCheck={false}
				    	autoCorrect='off'
				    	autoCapitalize='off'
				    	autoComplete='off'
				    	onInput={this.processInput}
				    	defaultValue={currentInput}
				    	isInvalid={!validEntry || showInvalidQueryFeedback}
				    	style={{ borderTopRightRadius: '0.3rem', borderBottomRightRadius: '0.3rem' }}
				    />
				    {showInvalidQueryFeedback
					  	? <Form.Control.Feedback 
					  			type='invalid'
					  			className='search-field-length-feedback-mobile'
					  		>
							  	Search must be at least three characters
							  </Form.Control.Feedback>
					  	: <Form.Control.Feedback 
					  			type='invalid'
					  			className='search-field-no-match-feedback-mobile'
					  		>
							  	{`${currentInput} has no matches `}
							  	<a
							  		href='https://medium.com/@urbitlive/an-intro-to-urbit-names-e54b06ec984b'
							  		target='_blank'
							  		style={{ color: 'white', marginLeft: '5px' }}
							  	>
							  		Learn more
							  	</a>
							  </Form.Control.Feedback>}
				  </InputGroup>
			  </Form>
	  	</div>
  	);
  }

  private processInput = (e: React.FormEvent<HTMLInputElement>) => {
  	e.preventDefault();
  	this.cleanSearchInput(e);
  }

  private cleanSearchInput = (input: React.BaseSyntheticEvent<Event, EventTarget & HTMLInputElement, EventTarget>) => {
  	/[\d]/.test(input.currentTarget.value)
  		? this.cleanPoint(input)
  		: this.cleanName(input);
	};

	private cleanName = (input: React.BaseSyntheticEvent<Event, EventTarget & HTMLInputElement, EventTarget>) => {
		if (input.currentTarget.value.length >= 1) {
			const currentInput = input.currentTarget.value.substring(0, 14);
  		this.setState({ 
  			currentInput,
  			validEntry: this.props.validateName(currentInput)
  		});
  		input.currentTarget.value = currentInput;
	  } else {
	  	this.setState({ 
	  		currentInput: '',
	  		validEntry: true
	  	});
	  }

	  if (this.state.showInvalidQueryFeedback && 
	  		input.currentTarget.value.length > 2) {
	  	this.setState({ showInvalidQueryFeedback: false });
	  }
	}

	private cleanPoint = (input: React.BaseSyntheticEvent<Event, EventTarget & HTMLInputElement, EventTarget>) => {
		const shipPoint = input.currentTarget.value.replace(/[^0-9]{0,10}/g, '');
		if (input.currentTarget.value.length > 1 && 
				input.currentTarget.value.substring(0, 2) === '0x') {
			web3.utils.isAddress(input.currentTarget.value)
				? window.open(`/address/${input.currentTarget.value}`, '_self')
				: this.setState({ currentInput: input.currentTarget.value });
		} else if (!!shipPoint && input.currentTarget.value.length > 0) {
			const maxPoint = Math.pow(2, 32) - 1;
			const shipPointNumber = Number(shipPoint);
			const cleanShipPointNumber = shipPointNumber < 0
				? 0
				: shipPointNumber > maxPoint
					? maxPoint
					: !Number.isInteger(shipPointNumber)
						? Math.floor(shipPointNumber)
						: shipPointNumber;
	  	this.setState({ currentInput: cleanShipPointNumber.toString() });
	  	input.currentTarget.value = cleanShipPointNumber.toString();
	  }
	}

	private performSearch = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
		const { currentInput } = this.state;
  	e.preventDefault();
  	const query = currentInput.toLowerCase();
  	const queryIsText = /[^\d]/.test(query);
  	if (!queryIsText || query.length > 2) {
	  	const nameWithTilde = query.charAt(0) === '~' 
	  		? query 
	  		: '~' + query;
	  	const queryIsPlanetName = queryIsText && query.length > 8 && ob.isValidPatp(nameWithTilde);
	  	if (!queryIsText) {
	  		window.open(`/${query}`, '_self');
	  	} else if (queryIsPlanetName) {
	  		window.open(`/${nameWithTilde}`, '_self');
	  	} else {
	  		window.open(`/explore?scope=[0]&query=${query}`, '_self');
	  	}
	  } else {
	  	this.setState({ showInvalidQueryFeedback: true });
	  }
  }

  private setOrientation = () => {
  	const portrait = (window.innerWidth / window.innerHeight) < 1;
  	const fixFooter = window.innerHeight >= document.body.scrollHeight;
	  this.setState({ portrait, fixFooter });
	}
}

export default Home;
