import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Container, Row, Table } from 'react-bootstrap';
import { Sigil } from './';
import { SalesStats, ShipSale } from './types/stats';
import ob from 'urbit-ob';
import 'bootstrap/dist/css/bootstrap.css';

interface Props {
	selectedCurrency: number;
	salesStats: SalesStats;
	selectedShipSale: ShipSale;
	priceKey: string;
}

class ShipSalesTable extends Component<Props, {}> {

  public render() {
  	return (
  		<Row 
				className='justify-content-md-center'
				style={{ marginLeft: 'auto', marginRight: 'auto' }}
			>
				{this.generateSalesTable()}
			</Row>
		);
  }

  private generateSalesTable = (): JSX.Element => {
  	const { selectedCurrency, salesStats, selectedShipSale, priceKey } = this.props;
		const unit = selectedCurrency === 0 ? 'USD' : selectedCurrency === 1 ? 'BTC' : 'ETH';
		const decimalPlaces = selectedCurrency === 0 ? 2 : selectedCurrency === 1 ? 5 : 3;
		const price = (selectedShipSale[priceKey] as number).toFixed(decimalPlaces);
		const formattedPrice = selectedCurrency === 0 ? this.addCommasToDollarValue(price) : price;
		const displayPrice = `${selectedCurrency === 0 ? '$' : ''}${formattedPrice} ${unit}`;
		const datePrice = isMobile && !isTablet
			? <div className='points-row-mobile-dateprice'>
    			<div className='points-row-date-mobile'>
      			{selectedShipSale.date}
				  </div>
	      	<div className='points-row-price-mobile'>
      			{displayPrice}
				  </div>
			  </div>
  		: <div>
	      	<div className='points-row-date'>
      			{selectedShipSale.date}
				  </div>
	      	<div className='points-row-price'>
      			{displayPrice}
				  </div>
  			</div>;
		const classNameMod = isMobile && !isTablet ? '-mobile' : '';
  	const rows = selectedShipSale.txHashes.map((txHash, idx) => {
  		const etherscanLink = `https://etherscan.io/tx/${txHash}`;
  		const point = selectedShipSale.points[idx];
  		const shipName = ob.patp(point);
  		const parent = ob.sein(shipName);
  		const style = { fontSize: isMobile && !isTablet ? '0.85rem' : '1.1rem' };
  		return (
  			<tr className={`points-table-row${classNameMod}`} key={idx}>
		      <td className={`points-table-ship-data${classNameMod}`}>
		      	<div className={`points-row-sigil${classNameMod}`} style={style}>
		      		<a href={`/${shipName}`}>
				      	<Sigil 
							  	patp={shipName}
							  	size={40}
							  	margin={8}
							  	color='#000000'
							  />
						  </a>
						  <a
		      			href={`/${shipName}`}
		      			className={`points-row-name${classNameMod}`}
		      			style={{ fontSize: isMobile && !isTablet ? '0.75rem' : '1.1rem' }}
		      		>
		      			{shipName}
		      		</a>
					  </div>
					</td>
		      <td 
		      	className={`points-table-row-data${classNameMod}`}
		      	style={style}
		      >
		      	<a
	      			href={`/${parent}`}
	      			className='points-row-parent'
	      			style={style}
	      		>
	      			{parent}
	      		</a>
		      </td>
		      <td className={`points-table-row-data${classNameMod}`} style={style}>
	      		<a
		      		href={etherscanLink}
		      		target='_blank'
		      		className='points-link'
		      	>
			      	Etherscan
			      	<i className='fas fa-external-link-alt points-link-icon'></i>
		      	</a>
					</td>
		    </tr>
  		);
  	});
  	return (
	  	<Container style={{ display: 'contents' }}>
	  		{datePrice}
	  		<div className={`points-table-container${classNameMod}`}>
					<Table hover variant='dark' style={{ backgroundColor: 'transparent' }}>
						<thead>
					    <tr>
					      <th className='points-table-header-row'>Planet</th>
					      <th className='points-table-header-row'>Parent</th>
					      <th className='points-table-header-row'>Transaction</th>
					    </tr>
					  </thead>
					  <tbody>
					    {rows}
					  </tbody>
					</Table>
				</div>
	  	</Container>
  	);
  }

  private addCommasToDollarValue = (amount: string): string => {
  	const decimalValue = amount.split('.');
  	return decimalValue[0].split('').reverse().map((char, idx) => 
  		`${char}${idx % 3 === 0 && idx !== 0 ? ',' : ''}`
  	).reverse().join('') + '.' + decimalValue[1];
  }

}

export default ShipSalesTable;