import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Container, Row } from 'react-bootstrap';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import { StarDistro } from './types/stats';
import 'bootstrap/dist/css/bootstrap.css';

interface Props {
	portrait: boolean;
	starDistro: StarDistro;
	selectedStarStatusIdx: number;
	// clickedPieSector:((data: any, index: number) => void);
}
interface State {
	radian: number;
}

class StarDistroChart extends Component<Props, State> {
	public state = {
		radian: (Math.PI / 180) * -1
	}

  public render() {
  	return (
  		<Row 
				className='justify-content-md-center'
				style={{ marginLeft: 'auto', marginRight: 'auto', display: 'inline-block' }}
			>
				{this.generateStarDistroChart()}
			</Row>
		);
  }

  private generateStarDistroChart = (): JSX.Element => {
		const { starDistro, portrait, selectedStarStatusIdx } = this.props;
		const chartMargin = isMobile && !isTablet
			? { top: 5, right: 5, bottom: 5, left: 5 }
			: { top: 5, right: 20, bottom: 5, left: 10 };
		const height = isMobile && !isTablet 
			? portrait
				? 420 
				: 360
			: 430;
		const width = isMobile && !isTablet 
			? portrait
				? 360
				: 700
			: 900;
		const aspect = width / height;
		const pieData = [
			{ name: 'Locked up for over 1 year', value: starDistro.lockedCount - (starDistro.availableYearCount + starDistro.withdrawLimitCount) },
			{ name: 'Active', value: starDistro.activeCount },
			{ name: 'Inactive', value: starDistro.inactiveCount + starDistro.withdrawLimitCount },
			{ name: 'Unspawned', value: starDistro.unspawnedCount },
			{ name: 'Unlock within 1 year', value: starDistro.availableYearCount - starDistro.availableMonthCount },
			{ name: 'Unlock within 1 month', value: starDistro.availableMonthCount }
		];
		const colors = ['#3c75c3', '#58e79e', '#1d4ab4', '#fec34e', '#5e6467', '#ec332e'];
		const innerRadius = isMobile && !isTablet ? 50 : 80;
		const outerRadius = isMobile && !isTablet ? 90 : 140;
		const startAngle = isMobile && !isTablet ? 210 : 210;
		return (
	  	<Container style={{ paddingLeft: '0px', paddingRight: '0px', overflow: isMobile && !isTablet ? 'scroll' : 'hidden', width }}>
        <PieChart
        	height={height}
			  	width={isMobile && !isTablet && portrait ? 650 : width}
        	margin={chartMargin}
        >
				  <Pie 
				  	cx={(isMobile && !isTablet) ? portrait ? '55%' : '50%' : '50%'}
				  	data={pieData}
				  	dataKey='value'
				  	innerRadius={innerRadius}
				  	outerRadius={outerRadius}
				  	activeIndex={selectedStarStatusIdx}
				  	// activeShape={selectedStarStatusIdx === -1 ? <this.LockedActiveShape/> : null}
				  	isAnimationActive={false}
				  	label={props => true ? this.NonLockedActiveShape(props) : false}
				  	// labelLine={true ? <this.NonLockedActiveShape/> : false}
				  	startAngle={startAngle}
				  	endAngle={startAngle + 360}
				  	paddingAngle={5}
				  >
				  	{pieData.map((entry, idx) => 
			  			<Cell 
					  		key={`cell-${idx}`} 
					  		fill={colors[idx % colors.length]} 
					  		stroke='none'
					  	/>)}
				  </Pie>
				</PieChart>
			</Container>
		);
	}

	private PieChartLabelLine = (props: any): JSX.Element => {
		const { cx, cy, midAngle, innerRadius, outerRadius, stroke, index } = props;
		const lineRadiusMod = (isMobile && !isTablet) && index === 2 ? 35 : 20;
		const angleMod = index === 0 ? isMobile && !isTablet ? 20 : 45 : 0;
		const sin = Math.sin(this.state.radian * (midAngle + angleMod));
	  const cos = Math.cos(this.state.radian * (midAngle + angleMod));
	  const sx = cx + outerRadius * cos;
	  const sy = cy + outerRadius * sin;
	  const mx = cx + (outerRadius + lineRadiusMod) * cos;
	  const my = cy + (outerRadius + lineRadiusMod) * sin;
	  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	  const ey = my;
		return (
			<path 
				stroke={stroke}
				fill='none'
				cursor='pointer'
				d={`M${sx},${sy}L${mx},${my}`} 
			/>
		);
	}

	private PieChartLabel = (props: any): JSX.Element => {
		const fontSize = isMobile && !isTablet ? '1rem' : '1.2rem';
		const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, name } = props;
		const labelRadiusMod = isMobile && !isTablet ? index === 2 ? 2.2 : 1.8 : 1.4;
		const angleMod = index === 0 ? isMobile && !isTablet ? 20 : 45 : 0;
	  const radius = innerRadius + (outerRadius - innerRadius) * labelRadiusMod;
	  const x = cx + radius * Math.cos(this.state.radian * (midAngle + angleMod));
	  const y = cy + radius * Math.sin(this.state.radian * (midAngle + angleMod));
	  const label = index === 0 && isMobile && !isTablet ? 'Locked up' : name;
	  const textAnchor = isMobile && !isTablet
	  	? index === 0 || index === 2
	  		? 'middle'
	  		: index === 3
	  			? 'start'
	  			: 'end'
	  	: x > cx 
	  		? 'start' 
	  		: 'end';
	  return (
	  	<text 
	    	x={x} 
	    	y={y} 
	    	dx={isMobile && !isTablet && index === 2 ? -35 : 0}
	    	fill='white'
	    	textAnchor={textAnchor} 
	    	dominantBaseline='central'
	    	fontSize={fontSize}
	    >
	      {`${label}: ${(percent * 100).toFixed(1)}%`}
	    </text>
	  );
	}

	private NonLockedActiveShape = (props: any): JSX.Element => {
	  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, percent, value, name } = props;
	  const sin = Math.sin(this.state.radian * midAngle);
	  const cos = Math.cos(this.state.radian * midAngle);
	  const sx = cx + outerRadius * cos;
	  const sy = cy + outerRadius * sin;
	  const mx = cx + (outerRadius + 30) * cos;
	  const my = cy + (outerRadius + 30) * sin;
	  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	  const ey = my;
	  const textAnchor = cos >= 0 ? 'start' : 'end';
	  return (
	    <g>
	      <Sector
	        cx={cx}
	        cy={cy}
	        innerRadius={innerRadius}
	        outerRadius={outerRadius}
	        startAngle={startAngle}
	        endAngle={endAngle}
	        fill={fill}
	      />
	      <path 
	      	d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} 
	      	stroke={fill} 
	      	fill='none' 
	      />
	      <circle 
	      	cx={ex} 
	      	cy={ey} 
	      	r={2} 
	      	fill={fill} 
	      	stroke='none'
	      />
	      <text 
	      	x={ex + (cos >= 0 ? 1 : -1) * 12} 
	      	y={ey} 
	      	dy={-14} 
	      	textAnchor={textAnchor} 
	      	fill='#FFF'
	      	fontSize='1.1rem'
	      >
	        {name}
	      </text>
	      <text 
	      	x={ex + (cos >= 0 ? 1 : -1) * 12} 
	      	y={ey} 
	      	dy={4}
	      	textAnchor={textAnchor} 
	      	fill='#FFF'
	      >
	      	{`${this.addCommasToPoint(value.toString())} stars`}
	      </text>
	      <text 
	      	x={ex + (cos >= 0 ? 1 : -1) * 12} 
	      	y={ey} 
	      	dy={22} 
	      	textAnchor={textAnchor} 
	      	fill="#999"
	      >
	        {`${(percent * 100).toFixed(1)}%`}
	      </text>
	    </g>
	  );
	}

	private LockedActiveShape = (props: any): JSX.Element => {
		const { starDistro } = this.props;
		const { radian } = this.state;
	  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, percent, value, name } = props;
	  const angleMod = isMobile && !isTablet ? 22 : 45;
	  const sin = Math.sin(radian * (midAngle + angleMod));
	  const cos = Math.cos(radian * (midAngle + angleMod));
	  const sx = cx + outerRadius * cos;
	  const sy = cy + outerRadius * sin;
	  const mx = cx + (outerRadius + 30) * cos;
	  const my = cy + (outerRadius + 30) * sin;
	  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	  const ey = my;
	  const textAnchor = cos >= 0 ? 'start' : 'end';
	  const pieData = [
			{ name: 'Unlocked, not yet withdrawn', value: starDistro.withdrawLimitCount },
			{ name: 'Unlock within 1 month', value: starDistro.availableMonthCount },
			{ name: 'Unlock within 1 year', value: starDistro.availableYearCount - starDistro.availableMonthCount }
		];
	  return (
	    <g>
	      <Sector
	      	key='0'
	        cx={cx}
	        cy={cy}
	        startAngle={startAngle}
	        endAngle={endAngle}
	        innerRadius={innerRadius}
	        outerRadius={outerRadius}
	        fill={fill}
	        cursor='pointer'
	      />
	      {pieData.map((data, idx) => this.generateLockedSubsectors(props, data, idx))}
	      <path 
	      	key='1'
	      	d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} 
	      	stroke={fill} 
	      	fill="none" 
	      />
	      <circle 
	      	key='2'
	      	cx={ex} 
	      	cy={ey} 
	      	r={2} 
	      	fill={fill} 
	      	stroke="none"
	      />
	      <text 
	      	key='3'
	      	x={ex + (cos >= 0 ? 1 : -1) * 12} 
	      	y={ey} 
	      	dy={-14} 
	      	textAnchor={textAnchor} 
	      	fill="#FFF"
	      	fontSize='1.2rem'
	      >
	        {name}
	      </text>
	      <text 
	      	key='4'
	      	x={ex + (cos >= 0 ? 1 : -1) * 12} 
	      	y={ey} 
	      	dy={4}
	      	textAnchor={textAnchor} 
	      	fill="#FFF"
	      >
	      	{`${this.addCommasToPoint(value.toString())} stars`}
	      </text>
	      <text 
	      	key='5'
	      	x={ex + (cos >= 0 ? 1 : -1) * 12} 
	      	y={ey} 
	      	dy={22} 
	      	textAnchor={textAnchor} 
	      	fill="#999"
	      >
	        {`${(percent * 100).toFixed(1)}%`}
	      </text>
	    </g>
	  );
	}

	private generateLockedSubsectors = (props: any, data: { name: string, value: number }, idx: number): JSX.Element => {
		const { starDistro, selectedStarStatusIdx } = this.props;
		const { radian } = this.state;
		const baseAngle = isMobile && !isTablet ? 270 : 180;
		const darkBlue = '#0088FE';
	  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, percent, value, name } = props;
	  const angleOffset = idx === 0
	  	? 0
	  	: idx === 1
	  		? (starDistro.withdrawLimitCount / 65280) * 360 + 1
	  		: ((starDistro.withdrawLimitCount + starDistro.availableMonthCount) / 65280) * 360 + 2;
		const subAngleTotal = (data.value / 65280) * 360;
		const midAngleSub = (subAngleTotal / 2) + angleOffset + baseAngle;
		const sinSub = Math.sin(radian * midAngleSub);
	  const cosSub = Math.cos(radian * midAngleSub);
	  const sxSub = cx + (outerRadius - 3) * cosSub;
	  const sySub = cy + (outerRadius - 3) * sinSub;
	  const mxSub = isMobile && !isTablet
	  	? cx + (outerRadius + 40 - (10 * idx)) * cosSub
	  	: cx + (outerRadius + 20 + (10 * (idx === 1 ? 2 : 1))) * cosSub;
	  const mySub = isMobile && !isTablet
	  	? cy + (outerRadius + 40 - (10 * idx)) * sinSub
	  	: cy + (outerRadius + 20 + (10 * (idx === 1 ? 2 : 1))) * sinSub;
	  const exSub = mxSub + (cosSub >= 0 ? 1 : -1) * 22;
	  const eySub = mySub;
	  const textAnchorSub = cosSub >= 0 ? 'start' : 'end';
	  return (
	    <g key={idx}>
	      <Sector
		      key={`${idx}0`}
	        cx={cx}
	        cy={cy}
	        innerRadius={innerRadius + 3}
	        outerRadius={outerRadius - 3}
	        startAngle={baseAngle + angleOffset + (idx === 0 ? 1.5 : 0.5)}
	        endAngle={baseAngle + angleOffset + subAngleTotal - (idx === 2 ? 1 : 0.5)}
	        fill={darkBlue}
	        cursor='pointer'
	      />
	      <path
	      	key={`${idx}1`}
	      	d={`M${sxSub},${sySub}L${mxSub},${mySub}L${exSub},${eySub}`} 
	      	stroke={darkBlue}
	      	fill="none" 
	      />
	      <circle
	      	key={`${idx}2`}
	      	cx={exSub} 
	      	cy={eySub} 
	      	r={2} 
	      	fill={darkBlue}
	      	stroke="none"
	      />
	      <text
	      	key={`${idx}3`}
	      	x={exSub + (cosSub >= 0 ? 1 : -1) * 12} 
	      	y={eySub} 
	      	dy={-14} 
	      	textAnchor={textAnchorSub} 
	      	fill="#FFF"
	      >
	        {data.name}
	      </text>
	      <text
	      	key={`${idx}4`}
	      	x={exSub + (cosSub >= 0 ? 1 : -1) * 12} 
	      	y={eySub}
	      	dy={4}
	      	textAnchor={textAnchorSub} 
	      	fill="#FFF"
	      >
	      	{`${this.addCommasToPoint(data.value.toString())} stars`}
	      </text>
	      <text
	      	key={`${idx}5`}
	      	x={exSub + (cosSub >= 0 ? 1 : -1) * 12} 
	      	y={eySub} 
	      	dy={22}
	      	textAnchor={textAnchorSub} 
	      	fill="#999"
	      >
	        {`${((data.value / 65280) * 100).toFixed(1)}%`}
	      </text>
	    </g>
	  );
	}

	private addCommasToPoint = (point: string): string => {
  	return point.split('').reverse().map((char, i) => 
  		i % 3 === 0 && i !== 0 ? char + ',' : char
  	).reverse().join('');
  }

}

export default StarDistroChart;