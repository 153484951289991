import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Container, Row, Button } from 'react-bootstrap';
import { BigNumber } from '@0x/utils';
// import { Order } from '@0x/types';
import { SignedOrder } from '@0x/order-utils';
import { ContractWrappers } from '@0x/contract-wrappers';
import { RPCSubprovider, Web3ProviderEngine, MetamaskSubprovider } from '@0x/subproviders';
import { Web3Wrapper } from '@0x/web3-wrapper';
import ob from 'urbit-ob';
import Web3 from 'web3';
import 'bootstrap/dist/css/bootstrap.css';

interface Props {
	apiBaseURL: string;
	refreshShipData: (() => void);
	titleLength: string;  // short or long
	orderDetails: ShipOrderDetails;
}

interface ShipOrder { 
	signedOrder: SignedOrder;
	point: number;
	networkId: number;
	signedZrxOrder: SignedOrder;
}

interface ShipOrderDetails { 
	point: number;
	networkId: number;
	priceEth: number;
	expirationTimeSeconds: BigNumber;
}

class ZeroExInstantModal extends Component<Props, {}> {

  public render() {
  	const { titleLength } = this.props;
    return titleLength === 'short'
    	? this.generateBuyButton()
    	: <Container>
		    	<Row className='justify-content-md-center'>
						{this.generateBuyButton()}
					</Row>
	    	</Container>;
  }

  private generateBuyButton = (): JSX.Element => {
  	const { apiBaseURL, titleLength, orderDetails } = this.props;
  	const title = titleLength === 'short'
			? 'Buy'
			: `Buy ${ob.patp(orderDetails.point.toString())} for ${orderDetails.priceEth.toString()} ETH`;
  	return (
  		<Button 
  			onClick={() => this.launchModal()}
  			className={`btn-lg btn-dark ${titleLength === 'short' ? 'buy-planet-card-buy-button' : 'purchase-button'}`}
  		>
  			{title}
  			{titleLength === 'short'
  				? <i 
				  		style={{ marginLeft: '12px' }}
				  		className='fas fa-shopping-cart'
				  	/>
			  	: null}
  		</Button>
  	);
  }

  private launchModal = () => {
  	const zeroExInstant = (window as any).zeroExInstant;
  	this.buildTxDetails()
  		.then(txDetails => zeroExInstant.render(txDetails, 'body'))
  		.catch((error: Error) => console.log(error));

  	// this.fillOrder();
  }

  private buildTxDetails = (): Promise<any> => {
  	return new Promise((resolve, reject) => {

	  	const { apiBaseURL, orderDetails } = this.props;
	  	const zeroExInstant = (window as any).zeroExInstant;
	  	const name = ob.patp(orderDetails.point.toString());

	  	this.getOrder().then(shipOrder => {

	  		// const zeroExApiOrder = {"chainId":42,"exchangeAddress":"0x4eacd0af335451709e1e7b570b8ea68edec8bc97","makerAddress":"0xc3a0ea325df274f54bffc833476fddf5f0aa1f22","takerAddress":"0x0000000000000000000000000000000000000000","feeRecipientAddress":"0x1000000000000000000000000000000000000011","senderAddress":"0x0000000000000000000000000000000000000000","makerAssetAmount":"2866447354942562367747","takerAssetAmount":"3917573599999999988","makerFee":"0","takerFee":"0","expirationTimeSeconds":"1585818751","salt":"1585815151259","makerAssetData":"0xf47261b00000000000000000000000002002d3812f58e35f0ea1ffbf80a75a38c32175fa","takerAssetData":"0xf47261b0000000000000000000000000d0a1e359811322d97991e03f863a0c30c2cf029c","makerFeeAssetData":"0x","takerFeeAssetData":"0x","signature":"0x1c0606d06e4a28202adf1ccfb86ffb36d2284037e5bbfaa599a8456bc3bdf5af807e28bd1a439c3434b80ad5e02211f322c7e99b509e56b77ee877a31944c4836302"};
	  		// return this.getZeroExApiOrder(shipOrder.signedOrder.takerAssetAmount.toString()).then(zeroExApiOrder => {

	  			// console.log('zeroExApiOrder', zeroExApiOrder);

	  			const ethereum = (window as any).ethereum;
	  			const providerEngine = new Web3ProviderEngine();
					providerEngine.addProvider(new MetamaskSubprovider(ethereum));
					providerEngine.addProvider(new RPCSubprovider('https://kovan.infura.io/v3/92968c6aa05646c8997bd5a609067107'));  // is this needed?
					providerEngine.start();
					const web3Wrapper = new Web3Wrapper(providerEngine);

			  	const txDetails = {
					  orderSource: [shipOrder.signedOrder],  // shipOrder.signedZrxOrder  , zeroExApiOrder
					  chainId: shipOrder.signedOrder.chainId,
					  networkId: shipOrder.signedOrder.chainId,
					  // shouldDisableAnalyticsTracking: true,
					  // provider: new MetamaskSubprovider(ethereum),
					  availableAssetDatas: [shipOrder.signedOrder.makerAssetData],  // zeroExApiOrder.makerAssetData, 
					  defaultSelectedAssetData: shipOrder.signedOrder.makerAssetData, // shipOrder.signedOrder.makerAssetData,   zeroExApiOrder.makerAssetData
					  affiliateInfo: {
	            feeRecipient: '0xb35ee255c88ea0863e2ead5ace10bb5ed2a3ea52',
	            feePercentage: 0.05,
		        },
		        // defaultAssetBuyAmount: 4,
					  additionalAssetMetaDataMap: {
					    [shipOrder.signedOrder.makerAssetData]: {
					      assetProxyId: zeroExInstant.ERC721_PROXY_ID,
					      name,
					      primaryColor: '#000000',
					      imageUrl: `${apiBaseURL}/images/${name}_black.png`
					    }
					    // [zeroExApiOrder.makerAssetData]: {
					    //   assetProxyId: zeroExInstant.ERC20_PROXY_ID,
					    //   decimals: 18,
					    //   symbol: 'WETH',
					    //   name: 'Wrapped Ether',
					    //   // primaryColor: '#000000',
					    //   // imageUrl: `${apiBaseURL}/images/${name}_black.png`
					    // }
					  },
					  onSuccess: (txHash: string) => this.orderFilled(txHash)
					};

					console.log(txDetails);

					resolve(txDetails);
				// })

			}).catch((error: Error) => reject(error));
	  });
  }

  // private fillOrder = () => {

  // 	const ethereum = (window as any).ethereum;
		// const provider = new MetamaskSubprovider(ethereum);
  // 	const contractWrappers = new ContractWrappers(provider, { chainId: 42 });

  // 	this.getOrder().then(shipOrder => {

  // 		console.log(shipOrder.signedOrder);

  // 		contractWrappers.exchange
  // 			.fillOrder(shipOrder.signedOrder, new BigNumber(shipOrder.signedOrder.takerAssetAmount), shipOrder.signedOrder.signature)
  // 			.callAsync()
  // 			.then(response => console.log('response', response))
  // 			.catch(err => console.log('error', err));

		// }).catch((error: Error) => console.log(error));

  // }

  private getOrder = (): Promise<ShipOrder> => {
  	return new Promise((resolve, reject) => {
			const { apiBaseURL, orderDetails } = this.props;
		  fetch(`${apiBaseURL}/order?point=${orderDetails.point}&networkId=${orderDetails.networkId}`)
		  	.then(res => res.json())
		    .then((result: ShipOrder) => resolve(result))
		    .catch((error: Error) => console.log(error));
    });
  }

  // private getZeroExApiOrder = (takerAssetAmount: string): Promise<SignedOrder> => {
  // 	return new Promise((resolve, reject) => {
		// 	const { apiBaseURL, orderDetails } = this.props;
		//   fetch(`https://kovan.api.0x.org/swap/v0/quote?buyToken=ZRX&sellToken=ETH&buyAmount=${takerAssetAmount}`)
		//   	.then(res => res.json())
		//     .then((result: any) => resolve(result.orders[0] as SignedOrder))
		//     .catch((error: Error) => console.log(error));
  //   });
  // }

  private orderFilled = (txHash: string) => {
  	const { apiBaseURL, orderDetails } = this.props;
  	const body = JSON.stringify(Object.assign({ txHash }, orderDetails));
  	fetch(`${apiBaseURL}/completeOrder`, {
  		method: 'POST',
  		headers: { 'Content-Type': 'application/json' },
      body
  	}).then(res => res.json())
      .then((conf: { completed: boolean }) => {
      	console.log(`Successfully purchased ${ob.patp(orderDetails.point.toString())}. Tx details: https://kovan.etherscan.io/tx/${txHash}`);
      	this.props.refreshShipData();
      }).catch((error: Error) => console.log(error));
  }
}

export default ZeroExInstantModal;