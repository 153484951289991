import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Container, Row, Button, Card, CardDeck, Spinner, Pagination, Col, Jumbotron, Table, Modal } from 'react-bootstrap';
import { Footer, Sigil, BuyControlPanel, ShipCardDeck, ZeroExInstantModal, ShipDashboard } from './';
import { Planet, SearchRequest, SearchResult } from './types/buy';
import { HeaderMetadata } from './types/header';
import ob from 'urbit-ob';
import { BigNumber } from '@0x/utils';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/Buy.css';

interface Props {
	apiBaseURL: string;
	wallet: Wallet;
	updateHeader:((metadata: HeaderMetadata) => void);
	searchRequest: SearchRequest;
	initiateTx:((point: string) => void);
}

interface Wallet { 
	web3Enabled: boolean;
	networkId: number;
	account: string;
}

interface ShipOrderDetails { 
	point: number;
	networkId: number;
	priceEth: number;
	expirationTimeSeconds: BigNumber;
}

interface State {
	planetsLoaded: boolean;
	shipOrdersLoaded: boolean;
	planets: Planet[];
	shipOrders: ShipOrderDetails[];
	selectedPlanetPoint: number;
	selectedShip: number;
	loadingMore: boolean;
	portrait: boolean;
	fixFooter: boolean;
	completedSearch: SearchResult;
	layoutStyle: number;
	sortIdx: number;
	sortAsc: boolean;
	showHelpModal: boolean;
	starMode: boolean;
	showMyShipsModal: boolean;
	ownedShips: number[];
	showMyShipsModalBody: boolean;
	retrievedOwnedShips: boolean;
}

class Buy extends Component<Props, State> {
	public state = {
		planetsLoaded: false,
		shipOrdersLoaded: false,
		planets: Array<Planet>(),
		shipOrders: Array<ShipOrderDetails>(),
		selectedPlanetPoint: -1,
		selectedShip: -1,
		loadingMore: false,
		portrait: true,
		fixFooter: true,
		completedSearch: Object() as SearchResult,
		layoutStyle: isMobile && !isTablet ? 1 : 0,
		sortIdx: 0,
		sortAsc: true,
		showHelpModal: false,
		starMode: window.location.hash.includes('stars'),
		showMyShipsModal: false,
		ownedShips: Array<number>(),
		showMyShipsModalBody: true,
		retrievedOwnedShips: true
	}

	public componentDidMount = () => {
		const { starMode } = this.state;
		const { searchRequest, updateHeader } = this.props;
		this.setOrientation();
	  window.addEventListener('resize', this.setOrientation);
	  const headerMetadata = {
			activeLink: 1, 
			searchMode: 0, 
			title: `Buy ${starMode ? 'stars' : 'planets'}`,
			scope: 0,
			query: ''
		};
		if (!!searchRequest.query && searchRequest.query.length > 2) {
			headerMetadata.query = searchRequest.query;
			this.searchForPlanets()
		} else if (starMode) {
			this.retrieveShipOrders();
		} else {
			this.retrieveSpawnCandidates();
		}
		updateHeader(headerMetadata);
	}

	public componentDidUpdate() {
		if (window.location.hash.includes('stars') && !this.state.starMode) {
			this.setState({ starMode: true }, () => this.retrieveShipOrders());
		} else if (window.location.hash.includes('planets') && this.state.starMode) {
			this.setState({ starMode: false }, () => this.retrieveSpawnCandidates());
		}
	}

	public componentWillUnmount() {
	  window.removeEventListener('resize', this.setOrientation);
	}

  public render() {
  	const { 
  		portrait, 
  		planets, 
  		planetsLoaded, 
  		shipOrders, 
  		shipOrdersLoaded, 
  		fixFooter, 
  		completedSearch, 
  		layoutStyle, 
  		showHelpModal, 
  		loadingMore, 
  		selectedPlanetPoint, 
  		starMode, 
  		showMyShipsModal 
  	} = this.state;
  	const { searchRequest } = this.props;
  	const scrollStyle = isMobile || isTablet 
  		? 'touch'
  		: 'auto';
  	const paddingTop = isMobile && !isTablet 
  		? portrait
  			? '20px'
  			: '10px'
  		: '10px';
  	const shipsLoaded = starMode
  		? shipOrdersLoaded
  		: planetsLoaded;
  	const ships = starMode
  		? shipOrders
  		: planets;
  	const buyViewStyle = {
  		WebkitOverflowScrolling: scrollStyle,
  		overflowScrolling: scrollStyle,
  		backgroundColor: '#212121',
  		paddingTop
  	} as React.CSSProperties;
  	const paginationButtons = shipsLoaded &&
    	!!completedSearch.query &&
    	completedSearch.pageCount > 1 
    		? this.generateSearchPagination() 
    		: null;
    return (
      <div style={buyViewStyle}>
      	{!!searchRequest.query &&
    		 searchRequest.query.length > 0 &&
         ob.isValidPatp(`${searchRequest.query.substring(0,1) === '~' ? '' : '~'}${searchRequest.query}`) &&
         Number(searchRequest.page) === 1
        	?	isMobile && !isTablet
        		? this.generateStarBannerMobile(searchRequest.query)
        		: this.generateStarBannerDesktop(searchRequest.query) 
        	: null}
        {shipsLoaded && (isMobile && !isTablet)
        	? <h4 style={{ color: 'white', marginTop: '20px' }}>
        			{this.generateSearchResultsTitle()}
        		</h4>
        	: null}
        {shipsLoaded
        	? <BuyControlPanel 
		        	sortBySelected={this.setSortState}
        			layoutButtonSelected={(layoutStyle: number) => this.setState({ layoutStyle })}
        			helpButtonSelected={() => this.setState({ showHelpModal: true })}
        			searchResultsTitle={this.generateSearchResultsTitle()}
        			starMode={starMode}
        			listMyStarButtonSelected={() => this.showMyShips()}
        		/>
        	: null}
	    	{shipsLoaded &&
         !!completedSearch.query 
        	? this.generateSearchResultsSubtitle() 
        	: null}
        {paginationButtons}
        {!starMode && shipsLoaded && ships.length === 0
        	? this.generateSuggestionPrompt() 
        	: null}
        {shipsLoaded 
        	? layoutStyle === 0
        			? starMode
        				? this.generateCards()
        				: <ShipCardDeck
	        					loadingMore={loadingMore}
	        					planets={planets}
	        					selectedPlanetPoint={selectedPlanetPoint}
	        					completedSearch={completedSearch}
	        					searchRequest={searchRequest}
	        					findMoreCandidates={this.findMoreCandidates}
	        					clickedX={this.clickedX}
	        					clickedCard={this.clickedCard}
	        					clickedBuy={this.clickedBuy}
	        				/>
        			: this.generateList()
        	: <Container style={{ marginTop: '100px', marginBottom: '25px' }}>
		    			<h3 style={{ color: 'white', marginRight: '10px', display: 'inline-block' }}>
		    				{`Finding ${starMode ? 'stars' : 'planets'}`}
		    			</h3>
							<Spinner animation='border' variant='light'/>
						</Container>}
				{paginationButtons}
				{showHelpModal
					? this.generateHelpModal()
					: null}
				{showMyShipsModal
					? this.generateMyShipsModal()
					: null}
        <Footer fixedToBottom={fixFooter}/>
      </div>
    );
  }

  private generateStarBannerDesktop = (query: string): JSX.Element => {
  	const starName = `${query.substring(0,1) === '~' ? '' : '~'}${query}`;
  	const clan = ob.clan(starName);
  	const capClan = clan.charAt(0).toUpperCase() + clan.slice(1);
		return (
			<div className='buy-star-banner'>
		  	<Jumbotron style={{ marginBottom: '10px', paddingBottom: '30px', paddingTop: '30px' }}>
		  		<Row>
		  			<Col style={{ marginLeft: '15px', marginRight: '20px' }}>
						  <h1 style={{ fontSize: '3.5rem' }}>{starName}</h1>
						  <h3>{capClan}</h3>
						  <h5>Azimuth point {this.addCommasToPoint(ob.patp2dec(starName))}</h5>
						  <a 
						  	className='btn btn-lg btn-dark'
						  	href={`/${starName}`} 
						  	role='button' 
						  	style={{ margin: '25px' }}
						  >
					  		More info
					  		<i 
						  		style={{ marginLeft: '12px' }}
						  		className='fas fa-sign-in-alt'
						  	/>
						  </a>
					  </Col>
					  <Col style={{ display: 'inline-flex' }}>
					  	<Sigil
						  	patp={starName} 
						  	size={250}
						  	margin={35}
						  	color='#000000'
						  />
					  </Col>
				  </Row>
				</Jumbotron>
			</div>
		);
  }

  private generateStarBannerMobile = (query: string): JSX.Element => {
  	const starName = `${query.substring(0,1) === '~' ? '' : '~'}${query}`;
  	const clan = ob.clan(starName);
  	const capClan = clan.charAt(0).toUpperCase() + clan.slice(1);
		return (
			<div className='buy-star-banner-mobile'>
		  	<Jumbotron style={{ marginBottom: '10px', paddingBottom: '30px', paddingTop: '30px' }}>
		  		<Row style={{ display: 'inline-flex' }}>
		  			<div style={{ marginRight: '15px', marginLeft: '20px', }}>
						  <h1 style={{ fontSize: '1.8rem' }}>{starName}</h1>
						  <h3 style={{ fontSize: '1.1rem' }}>{`${capClan} | AZP ${this.addCommasToPoint(ob.patp2dec(starName))}`}</h3>
						  <a 
						  	className='btn btn-lg btn-dark'
						  	href={`/${starName}`} 
						  	role='button' 
						  	style={{ marginTop: '5px', fontSize: '0.9rem', paddingLeft: '15px', paddingRight: '15px' }}
						  >
					  		More info
					  		<i 
						  		style={{ marginLeft: '5px' }}
						  		className='fas fa-sign-in-alt'
						  	/>
						  </a>
					  </div>
					  <div style={{ paddingTop: '10px', marginLeft: '15px', marginRight: '20px' }}>
					  	<Sigil
						  	patp={starName} 
						  	size={100}
						  	color='#000000'
						  />
					  </div>
				  </Row>
				</Jumbotron>
			</div>
		);
  }

  private generateSearchResultsTitle = (): string => {
  	const { completedSearch, starMode } = this.state;
  	return !!completedSearch.query 
  		? `${this.addCommasToPoint(completedSearch.resultsCount.toString())} result${completedSearch.resultsCount !== 1 ? 's' : ''} for \'${completedSearch.query}\' ${completedSearch.scope !== 'spawning' ? (' found in ' + ob.patp(JSON.parse(completedSearch.scope)[0])) : ' found in spawning stars'}`
  		: `${starMode ? 'Stars' : 'Planets'} available to purchase`;
  }

  private generateSearchResultsSubtitle = (): JSX.Element => {
  	const { completedSearch, planets } = this.state;
  	const firstResultNumber = (Number(this.props.searchRequest.page) - 1) * 100 + 1;
  	const lastResultNumber = firstResultNumber + planets.length - 1;
  	const searchResultsSubtitle = 'Displaying results ' + firstResultNumber + ' to ' + lastResultNumber;
		return (
			<div>
				{completedSearch.pageCount > 1 
					? <p className='search-results-subtitle'>
							{searchResultsSubtitle}
						</p> 
					: null}
			</div>
		);
  }

  private generateSuggestionPrompt = (): JSX.Element => {
  	const { completedSearch } = this.state;
  	const buttonStyle = isMobile && !isTablet
  		? 'search-suggestion-button-mobile btn btn-lg btn-dark'
  		: 'search-suggestion-button btn btn-lg btn-dark';
  	const suggestionRows = [
	  	['faster', 'walled', 'differ', 'dotnet'],
	  	['harbur', 'firnus', 'watter', 'pilled'],
	  	['docter', 'master', 'molten', 'botnet'],
	  	['bitter', 'poster', 'listen', 'massed'],
	  	['winner', 'donnut', 'doller', 'magnet'],
	  	['winter', 'middel', 'midnyt', 'wallet']
  	];
		return (
			<Container style={{ marginBottom: '25px' }}>
				<h5 style={{ color: 'white', marginTop: '10px' }}>
					Want some suggestions? These are popular search phrases:
				</h5>
				{suggestionRows.map((row, rowIdx) => 
					<div 
						className='justify-content-md-center'
						key={rowIdx}
					>
						{row.map((suggestion, idx) => 
							idx === 3 && isMobile && !isTablet
								? null
								: <a 
										className={buttonStyle} 
										href={`/buy?query=${suggestion}`} 
										role='button'
										key={idx}
									>
										{suggestion}
									</a>
						)}
					</div>
				)}
			</Container>
		);
  }

  private generateCards = (): JSX.Element => {
  	const { loadingMore, completedSearch, starMode } = this.state;
  	const { searchRequest } = this.props;
		return (
			<div style={{ paddingBottom: '15px' }}>
  			{this.buildDecks()}
  			{loadingMore
  				? <Container style={{ marginTop: '25px', marginBottom: '12px' }}>
							<Spinner animation='border' variant='light'/>
						</Container>
  				: !starMode &&
  					(!searchRequest.query || 
  						completedSearch.pageCount === Number(searchRequest.page))
  					? <Container style={{ marginTop: '15px', marginBottom: '10px' }}>
					  		<Button
					  			onClick={this.findMoreCandidates}
					  			className='btn-dark btn-lg'
					  			style={{ backgroundColor: '#2E2E2E' }}
					  		>
								  Show more available planets
							  </Button>
						  </Container>
  					: null}
	  	</div>
		);
  }

  private buildDecks = (): JSX.Element[] => {
  	const { planets, shipOrders, starMode } = this.state;
  	const cardsPerRow = isMobile && !isTablet ? 2 : 4;
  	const baseClass = `buy-planet-deck${isMobile && !isTablet ? '-mobile' : ''}`;
  	return starMode
  		? shipOrders.filter((shipOrder, i) => i % cardsPerRow === 0).map((shipOrder, i) => {
		  		const rowLeadIdx = shipOrders.indexOf(shipOrder);
		  		const cardCount = rowLeadIdx + cardsPerRow >= shipOrders.length 
		  			? shipOrders.length - rowLeadIdx
		  			: cardsPerRow;
					const type = rowLeadIdx === 0
						? 'top' 
						: rowLeadIdx + cardsPerRow >= shipOrders.length 
							? 'bottom' + (cardCount < cardsPerRow ? '-straggler' : '') 
							: 'other';
					return (
						<CardDeck
							className={`${baseClass} buy-planet-deck-${type}`}
							key={i}
						>
							{this.buildShipOrderCards(rowLeadIdx, cardCount)}
						</CardDeck>
					);
				})
  		: planets.filter((planet, i) => i % cardsPerRow === 0).map((planet, i) => {
		  		const rowLeadIdx = planets.indexOf(planet);
		  		const cardCount = rowLeadIdx + cardsPerRow >= planets.length 
		  			? planets.length - rowLeadIdx
		  			: cardsPerRow;
					const type = rowLeadIdx === 0
						? 'top' 
						: rowLeadIdx + cardsPerRow >= planets.length 
							? 'bottom' + (cardCount < cardsPerRow ? '-straggler' : '') 
							: 'other';
					return (
						<CardDeck
							className={`${baseClass} buy-planet-deck-${type}`}
							key={i}
						>
							{this.buildCards(rowLeadIdx, cardCount)}
						</CardDeck>
					);
				});
  }

  private buildCards = (startingValue: number, count: number): JSX.Element[] => {
  	const { planets, selectedPlanetPoint } = this.state;
  	return planets.filter((planet, i) => i >= startingValue && i < (startingValue + count)).map(planet => {
			const selected = selectedPlanetPoint === Number(planet.point);
			const xButton = selected
				? <Button 
	      		onClick={() => this.setState({ selectedPlanetPoint: -1 })}
	      		variant='dark'
	      		className='x-button'
	      	>
						<i className='fas fa-times-circle'/>
					</Button>
			  : null;
			const infoButton = 
			  <a 
			  	className='buy-planet-card-learn-more-button' 
			  	href={`/${planet.name}`}
			  	role='button' 
			  >
			  	More info
			  	<i 
			  		style={{ marginLeft: '12px' }}
			  		className='fas fa-sign-in-alt'
			  	/>
			  </a>;
			const buyButton = planet.available === undefined
			  ? <div style={{ display: 'inline-block', marginLeft: '35px', verticalAlign: 'middle' }}>
						<Spinner animation='border' variant='light' size='sm'/>
					</div>
				: planet.available
					? <Button 
					  	className='buy-planet-card-buy-button' 
					  	onClick={this.clickedBuy}
					  >
					  	Buy
					  	<i 
					  		style={{ marginLeft: '12px' }}
					  		className='fas fa-shopping-cart'
					  	/>
					  </Button>
					: <div style={{ display: 'inline-block', marginLeft: '25px', verticalAlign: 'middle' }}>
							<p style={{ marginBottom: '10px', fontSize: '1.1rem' }}>Not available</p>
						</div>;
			const sponsorName = ob.sein(planet.name);
			const sigilSize = isMobile && !isTablet ? 125 : 160;
			const mobileMod = isMobile && !isTablet ? '-mobile' : '';
			const card = 
				<Card 
		  		key={planet.point} 
		  		onClick={(isMobile && !isTablet) || selected ? undefined : this.clickedCard}
		  		id={planet.point.toString()}
		  		className={`buy-planet-card${selected ? '-selected' : ''}${mobileMod}`}
		  		style={{ backgroundColor: '#2E2E2E' }}
		  	>
		  		<Card.Header as='h4' className={selected ? 'buy-planet-card-selected-header' : 'buy-planet-card-header'}>
		  			{xButton}
		  			{planet.name}
		  		</Card.Header>
		  		<div className='buy-planet-card-content-container'>
			  		<div 
			  			className={`buy-planet-card-sigil-container${mobileMod}`}
			  			key={planet.point}
			  		>
						  <Sigil 
						  	patp={planet.name}
						  	size={sigilSize}
						  	margin={20}
						  	color='#000000'
						  />
					  </div>
					  {selected 
					  	? <div className={`buy-planet-card-info-container${mobileMod}`}>
							  	<h4><b>{planet.available == undefined ? ' ' : planet.available ? 'Available' : 'Unavailable'}</b></h4>
							  	<h5>Price: 0.025 ETH</h5>
							  	<h5>
							  		Parent:
							  		<a
							  			className='buy-planet-card-sponsor-link' 
									  	href={`/${sponsorName}`}
							  		>
							  			{sponsorName}
							  		</a>
								  </h5>
								  <h5>AZP: {this.addCommasToPoint(planet.point.toString())}</h5>
							  </div>
							: null}
				  </div>
				  {selected 
				  	? <Card.Footer>
				  			<Row style={{ display: 'inline-block' }}>
					  			{infoButton}
					  			{buyButton}
				  			</Row>
				  		</Card.Footer>
				  	: null}
				</Card>;
			return isMobile && !isTablet
				? <a
						href={`/${planet.name}`}
						key={planet.point}
						className='buy-planet-card-mobile-link'
					>
						{card}
					</a>
				: card;
		});
  }

  private buildShipOrderCards = (startingValue: number, count: number): JSX.Element[] => {
  	const { shipOrders, selectedShip } = this.state;
  	return shipOrders.filter((shipOrder, i) => i >= startingValue && i < (startingValue + count)).map(shipOrder => {
			const selected = selectedShip === shipOrder.point;
			const shipName = ob.patp(shipOrder.point.toString());
			const xButton = selected
				? <Button 
	      		onClick={() => this.setState({ selectedShip: -1 })}
	      		variant='dark'
	      		className='x-button'
	      	>
						<i className='fas fa-times-circle'/>
					</Button>
			  : null;
			const infoButton = 
			  <a 
			  	className='buy-planet-card-learn-more-button' 
			  	href={`/${shipName}`}
			  	role='button' 
			  >
			  	More info
			  	<i 
			  		style={{ marginLeft: '12px' }}
			  		className='fas fa-sign-in-alt'
			  	/>
			  </a>;
			const buyButton = 
				<ZeroExInstantModal 
					apiBaseURL={this.props.apiBaseURL}
					refreshShipData={this.retrieveShipOrders}
					titleLength={'short'}
					orderDetails={shipOrder}
				/>;
			const sponsorName = ob.sein(shipName);
			const sigilSize = isMobile && !isTablet ? 125 : 160;
			const mobileMod = isMobile && !isTablet ? '-mobile' : '';
			const expDate = new BigNumber(shipOrder.expirationTimeSeconds).times(1000).toNumber();
			const daysToExp = Math.round((expDate - Date.now()) / (1000 * 60 * 60 * 24));
			const card = 
				<Card 
		  		key={shipOrder.point} 
		  		onClick={(isMobile && !isTablet) || selected ? undefined : this.clickedCard}
		  		id={shipOrder.point.toString()}
		  		className={`buy-planet-card${selected ? '-selected' : ''}${mobileMod}`}
		  		style={{ backgroundColor: '#2E2E2E' }}
		  	>
		  		<Card.Header as='h4' className={selected ? 'buy-planet-card-selected-header' : 'buy-planet-card-header'}>
		  			{xButton}
		  			{shipName}
		  		</Card.Header>
		  		<div className='buy-planet-card-content-container'>
			  		<div 
			  			className={`buy-planet-card-sigil-container${mobileMod}`}
			  			key={shipOrder.point}
			  		>
						  <Sigil 
						  	patp={shipName}
						  	size={sigilSize}
						  	margin={20}
						  	color='#000000'
						  />
					  </div>
					  {selected 
					  	? <div className={`buy-planet-card-info-container${mobileMod}`}>
							  	<h4><b>{`Price: ${shipOrder.priceEth} ETH`}</b></h4>
							  	<h5>{`Expires in ${daysToExp} days`}</h5>
							  	<h5>
							  		Parent:
							  		<a
							  			className='buy-planet-card-sponsor-link' 
									  	href={`/${sponsorName}`}
							  		>
							  			{sponsorName}
							  		</a>
								  </h5>
								  <h5>AZP: {this.addCommasToPoint(shipOrder.point.toString())}</h5>
							  </div>
							: null}
				  </div>
				  {selected 
				  	? <Card.Footer>
				  			<Row style={{ display: 'inline-block' }}>
					  			{infoButton}
					  			{buyButton}
				  			</Row>
				  		</Card.Footer>
				  	: null}
				</Card>;
			return isMobile && !isTablet
				? <a
						href={`/${shipName}`}
						key={shipOrder.point}
						className='buy-planet-card-mobile-link'
					>
						{card}
					</a>
				: card;
		});
  }

  private generateList = (): JSX.Element => {
  	const { planets, loadingMore, completedSearch, shipOrders, starMode } = this.state;
  	const { searchRequest } = this.props;
  	const mobileClassname = isMobile && !isTablet ? '-mobile' : '';
  	const sigilSize = isMobile && !isTablet ? 40 : 60;
  	const sigilMargin = Math.round(sigilSize / 6);
  	const headerCells = ['Planet', 'AZP', 'Parent', 'Price', ''].map((header, idx) => 
      isMobile && !isTablet && header === 'AZP'
      	? null
      	: <th 
      			className={`buy-planet-table-header-${header === '' ? 'button' : 'cell'}${mobileClassname}`}
      			key={idx}
      			>
		      	{header}
		      </th>
	  );
	  const ships = starMode ? shipOrders : planets;
  	const rows = (ships as Array<Planet|ShipOrderDetails>).map((ship, idx) => {
  		const shipName = ob.patp(ship.point.toString());
			return (
				<tr key={idx}>
					<td className={`buy-planet-table-sigil-row${mobileClassname}`}>
						<a 
							href={`/${shipName}`}
							className={`buy-planet-table-link${mobileClassname}`}
						>
	  					<div className={`buy-planet-table-sigil-container${mobileClassname}`}>
		  					<Sigil
							  	patp={shipName}
							  	size={sigilSize}
							  	margin={sigilMargin}
							  	color='#000000'
							  />
							</div>
	  					<p className='buy-planet-table-name'>{shipName}</p>
						</a>
					</td>
					{isMobile && !isTablet
						? null 
						: <td className={`buy-planet-table-data-row${mobileClassname}`}>
								<a 
									href={`/${shipName}`}
									className={`buy-planet-table-link${mobileClassname}`}
								>
			  					{this.addCommasToPoint(ship.point.toString())}
			  				</a>
							</td>}
					<td className={`buy-planet-table-data-row${mobileClassname}`}>
						<a 
							href={`/${ob.sein(shipName)}`}
							className={`buy-planet-table-link${mobileClassname}`}
						>
	  					{ob.sein(shipName)}
	  				</a>
					</td>
					<td className={`buy-planet-table-data-row${mobileClassname}`}>
						{`${starMode ? (ship as ShipOrderDetails).priceEth : 0.025} ETH`}
					</td>
					<td className={`buy-planet-table-data-row${mobileClassname}`}>
						{starMode
							? <ZeroExInstantModal 
		  						apiBaseURL={this.props.apiBaseURL}
		  						refreshShipData={this.retrieveShipOrders}
		  						titleLength={'short'}
		  						orderDetails={(ship as ShipOrderDetails)}
		  					/>
		  				: (ship as Planet).available === undefined
								? <Spinner animation='border' variant='light' size='sm'/>
								: (ship as Planet).available
									? <Button 
					  					id={ship.point.toString()}
									  	className={`buy-planet-table-buy-button${mobileClassname}`}
									  	onClick={this.clickedBuyInList}
									  >
									  	Buy
									  	{isMobile && !isTablet
									  		? null
									  		: <i 
											  		style={{ marginLeft: '10px' }}
											  		className='fas fa-shopping-cart'
											  	/>}
									  </Button>
									: <p style={{ marginBottom: '0px' }}>Not available</p>}
					</td>
				</tr>
			);
  	});
  	const displayStyle = isMobile && !isTablet ? 'inline-block' : 'contents';
		return (
			<Container style={{ display: displayStyle }}>
				<Row className='justify-content-md-center'>
					<div className={`buy-planet-table-container${mobileClassname}`}>
						<Table 
							striped 
							hover 
							variant='dark'
							style={{ backgroundColor: 'transparent' }}
						>
							<thead>
						    <tr>
						      {headerCells}
						    </tr>
						  </thead>
						  <tbody style={{ textAlign: 'left', backgroundColor: '#2E2E2E' }}>
						  	{rows}
						  </tbody>
						</Table>
					</div>
				</Row>
	  		{loadingMore
	  			? <Container style={{ marginTop: '25px', marginBottom: '12px' }}>
							<Spinner animation='border' variant='light'/>
						</Container>
	  			: !searchRequest.query || completedSearch.pageCount === Number(searchRequest.page)
  					? <Container style={{ marginTop: '15px', marginBottom: '10px' }}>
					  		<Button
					  			onClick={this.findMoreCandidates}
					  			className='btn-dark btn-lg'
					  			style={{ backgroundColor: '#2E2E2E' }}
					  		>
								  Show more available planets
							  </Button>
						  </Container>
  					: null}
			</Container>
		);
  }

  private generateSearchPagination = (): JSX.Element => {
  	const { completedSearch } = this.state;
  	const maxPaginationButtons = isMobile && !isTablet ? 4 : 8;
  	const paginationButtonCount = completedSearch.pageCount > maxPaginationButtons 
  		? maxPaginationButtons 
  		: completedSearch.pageCount - 1;
  	const currentPageNumber = Number(this.props.searchRequest.page);
  	const startingValue = completedSearch.pageCount < 11 || currentPageNumber < 5
  		? 1
  		: completedSearch.pageCount - currentPageNumber < 5
  			? completedSearch.pageCount - 8
  			: currentPageNumber - (maxPaginationButtons / 2);
		const maxPageNumberDisplayed = startingValue + paginationButtonCount;
		const items = [...Array(paginationButtonCount + 1).keys()].map(idx => {
			const pageNumber = startingValue + idx;
			return (
				<Pagination.Item 
		    	key={pageNumber} 
		    	active={pageNumber === currentPageNumber}
		    	href={`/buy?query=${completedSearch.query}&page=${pageNumber}`}
				>
		      {pageNumber}
		    </Pagination.Item>
		   );
		});
		if (currentPageNumber > 1 && paginationButtonCount > 3 && startingValue !== 1) {
			items.splice(0, 0,
				<Pagination.First 
					key={-2}
					href={`/buy?query=${completedSearch.query}&page=1`}
				/>,
				<Pagination.Prev 
					key={-1}
					href={`/buy?query=${completedSearch.query}&page=${currentPageNumber - 1}`}
				/>
			);
		}
		if (currentPageNumber !== completedSearch.pageCount && paginationButtonCount > 3 && maxPageNumberDisplayed !== completedSearch.pageCount) {
			items.push(
				<Pagination.Next 
					key={maxPageNumberDisplayed + 1}
					href={`/buy?query=${completedSearch.query}&page=${currentPageNumber + 1}`}
				/>,
				<Pagination.Last 
					key={maxPageNumberDisplayed + 2}
					href={`/buy?query=${completedSearch.query}&page=${completedSearch.pageCount}`}
				/>
			);
		}
  	return (
  		<Container>
  			<Row style={{ marginTop: '10px', display: 'inline-block' }}>
		  		<Pagination>
					  {items}
					</Pagination>
				</Row>
		  </Container>
  	)
  }

  private generateHelpModal = (): JSX.Element => {
  	const walletInfo = isMobile
  		? { name: 'Trust Wallet', url: 'https://trustwallet.com/' } 
  		: { name: 'Metamask', url: 'https://metamask.io/' };
  	const planetPrice = 0.045;
  	const circleIcon = 'far fa-circle';
  	const checkIcon = 'far fa-check-circle';
  	const checklistIcon1 = this.props.wallet.web3Enabled ? checkIcon : circleIcon;
  	const checklistIcon2 = circleIcon;
  	const checklistMarginLeft = isMobile && !isTablet ? '40px' : '75px';
  	const signoffMarginLeft = isMobile && !isTablet ? '130px' : '230px';
  	return (
  		<Modal
  			show={true} 
  			onHide={() => this.setState({ showHelpModal: false })}
  		>
        <Modal.Header closeButton>
          <Modal.Title>How to buy a planet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        	<Container>
						<p>
							If you want an Urbit planet you've come to the right place. Here's some info you want to know:
						</p>
						<ul>
							<li>
								{`To purchase a planet you need an Ethereum web3 wallet and ${planetPrice.toString()}* ETH. We recommend `}
								<a 
									href={walletInfo.url} 
									target='_blank'
								>
									{walletInfo.name}
								</a>
								, but there are many options.
							</li>
							<li>
								Urbit set some ground rules for distributing planets.
								<b>{' ~10% of them can be spawned in 2021. '}</b>
								More planets will be available in 2022.
							</li>
						</ul>
						<Col className='checklist-container'>
						  <h3>Your checklist</h3>
						  <div style={{ textAlign: 'left', marginLeft: checklistMarginLeft }}>
						  	<h4>
							  	<i className={`checklist-item ${checklistIcon1}`}/>
							  	&nbsp;{' Ethereum wallet'}
						  	</h4>
						  	<h4>
							  	<i className={`checklist-item ${checklistIcon2}`}/>
							  	&nbsp;{` ${planetPrice.toString()} ETH`}
						  	</h4>
						  </div>
					  </Col>
					  <p style={{ marginTop: '15px' }}>
					  	{'If you\'re completely lost, start '}
					  	<a 
					  		href='https://urbit.org/understanding-urbit'
					  		target='_blank'
					  	>
					  		{'here'}
					  	</a>
					  	{' and join us in our '}
					  	<a 
					  		href='https://t.me/UrbitLiveGroup'
					  		target='_blank'
					  	>
					  		{'Telegram group'}
					  	</a>
					  	{' to get your questions answered.'}
					  </p>
					  <p style={{ fontSize: '1.2rem', marginLeft: signoffMarginLeft }}>
						  -the urbit live team
					  </p>
					</Container>
        </Modal.Body>
        <Modal.Footer>
        	<p style={{ fontSize: '0.7rem' }}>
						* A planet costs 0.025 ETH, but you need enough ETH to cover the transaction fee
					</p>
          <Button
          	variant='secondary' 
          	onClick={() => this.setState({ showHelpModal: false })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  	);
  }

  private generateMyShipsModal = (): JSX.Element => {
  	const { ownedShips, shipOrders, showMyShipsModalBody, retrievedOwnedShips } = this.state;
  	const activeOrders = shipOrders.map(order => order.point);
  	const dashboards = retrievedOwnedShips
  		? ownedShips.length > 0
	  		? ownedShips.map(point => {
			  		return (
			  			<ShipDashboard 
								apiBaseURL={this.props.apiBaseURL}
								networkId={42}
								point={point}
								refreshShipData={this.retrieveShipOrders}
								activeOrder={activeOrders.indexOf(point) !== -1}
								key={point}
							/>
						)
			  	})
		  	: <Container style={{ marginLeft: 'auto', marginRight: 'auto' }}>
						This account does not own a star
					</Container>
	  	: <Container style={{ marginLeft: 'auto', marginRight: 'auto' }}>
					<Spinner animation='border' variant='dark'/>
				</Container>;
  	return (
  		<Modal
  			show={true} 
  			onHide={() => this.setState({ showMyShipsModal: false })}
  		>
  			<Modal.Header closeButton>
          <Modal.Title>My Stars</Modal.Title>
        </Modal.Header>
        <Modal.Body>
	      	<Container>
	      		{dashboards}
					</Container>
	      </Modal.Body>
      </Modal>
  	);
  }

  private addCommasToPoint = (point: string): string => {
  	return point.split('').reverse().map((char, i) => 
  		i % 3 === 0 && i !== 0 ? char + ',' : char
  	).reverse().join('');
  }

  private clickedCard = (e: React.MouseEvent<HTMLElement>) => {
  	const { selectedPlanetPoint, planets, selectedShip, shipOrders, starMode } = this.state;
  	e.preventDefault();
  	const clickedShipPoint = Number(e.currentTarget.id);
  	const update = starMode
  		? {
	  		selectedShip: selectedShip === clickedShipPoint ? -1 : clickedShipPoint,
	  		selectedPlanetPoint
	  	}
	  	: {
	  		selectedPlanetPoint: selectedPlanetPoint === clickedShipPoint ? -1 : clickedShipPoint,
	  		selectedShip
	  	};
  	this.setState(update, () => {
  		if (!starMode && this.state.selectedPlanetPoint !== -1) {
	  		const planet = planets.find(planet => planet.point === this.state.selectedPlanetPoint);
	  		if (!!planet && !planet.available) {
		  		this.retrieveAvailableStatus(planet);
		  	}
		  }
  	});
  	e.stopPropagation();
  }

  private clickedX = (e: React.MouseEvent<HTMLElement>) => {
  	this.setState({ selectedPlanetPoint: -1 });
  }

  private clickedBuy = (e: React.MouseEvent<HTMLElement>) => {
  	this.props.initiateTx(this.state.selectedPlanetPoint.toString());
  }

  private clickedBuyInList = (e: React.MouseEvent<HTMLElement>) => {
  	this.props.initiateTx(e.currentTarget.id);
  }

  private clickedLookup = (e: React.MouseEvent<HTMLElement>) => {
  	const planet = this.state.planets.find(planet => planet.point.toString() === e.currentTarget.id);
  	if (!!planet && !planet.available) {
  		this.retrieveAvailableStatus(planet);
  	}
  }

  private setSortState = (sortIdx: number, sortAsc: boolean) => {
  	const { completedSearch } = this.state;
  	this.setState({ sortIdx, sortAsc }, () => 
  		!(!!completedSearch.query && completedSearch.pageCount > 1)
				? this.sortPlanets()
				: this.searchForPlanets()
		);
  }

  private showMyShips = () => {
  	this.setState({ showMyShipsModal: true });
  	this.retrieveOwnedShips();
  }

  private sortPlanets = () => {
  	const { sortIdx, sortAsc } = this.state;
  	const planets = 
  		this.state.planets.sort((a, b) => 
	  		sortIdx === 0
	  			? sortAsc
						? a.name > b.name
							? 1
							: -1 
						: b.name > a.name
							? 1
							: -1
	  			: sortIdx === 1
						? sortAsc
							? Number(a.point) - Number(b.point)
							: Number(b.point) - Number(a.point)
						: sortAsc
							? ob.sein(a.name) > ob.sein(b.name)
								? 1
								: -1
							: ob.sein(b.name) > ob.sein(a.name)
								? 1
								: -1);
		this.setState({ planets });
  }

  private retrieveSpawnCandidates = () => {
  	this.setState({ planetsLoaded: false }, () => {
    	fetch(`${this.props.apiBaseURL}/spawnCandidates?ship=any&count=16`)
    		.then(res => res.json())
	      .then((planets: Planet[]) => {
	      	this.setState({ 
		      	planetsLoaded: true,
		      	planets
		      }, () => {
		      	this.sortPlanets();
		      	this.setOrientation();
	      	});
	      }).catch((error: Error) => console.log(error));
    });
  }

  private retrieveShipOrders = () => {
  	// console.log('!!this.props.wallet.networkId', !!this.props.wallet.networkId);
  	const wndw = (window as any);
  	// console.log('ethereum', !!wndw.ethereum);
  	// console.log('web3', !!wndw.web3);
  	const networkId = !!this.props.wallet.networkId
  		? this.props.wallet.networkId
  		: this.parseNetworkId();
  	this.setState({ shipOrdersLoaded: false }, () => {
    	fetch(`${this.props.apiBaseURL}/orders?networkId=42`)
    		.then(res => res.json())
	      .then((result: { orders: ShipOrderDetails[] }) => {
	      	if (!!result.orders) {
		      	this.setState({ 
			      	shipOrdersLoaded: true,
			      	shipOrders: result.orders
			      }, () => this.setOrientation());
		      }
	      }).catch((error: Error) => console.log(error));
    });
  }

  private parseNetworkId = (): number => {
  	const wndw = (window as any);
		return !!wndw.ethereum || !!wndw.web3
	  	? !!wndw.ethereum
		  		? !!wndw.ethereum.networkVersion
						? Number(wndw.ethereum.networkVersion)
						: 1
					: !!wndw.web3.currentProvider.networkVersion
						? Number(wndw.web3.currentProvider.networkVersion)
						: 1
			: process.env.REACT_APP_ENV === 'production'
				? 1
				: 42;
  }

  private findMoreCandidates = () => {
  	this.setState({ loadingMore: true }, () => {
  		this.setOrientation();
    	fetch(`${this.props.apiBaseURL}/spawnCandidates?ship=any&count=32`).then(res => res.json())
	      .then((spawnCandidates: Planet[]) => {
	      	this.setState({ 
		      	loadingMore: false,
		      	planets: this.state.planets.concat(spawnCandidates)
		      }, () => this.setOrientation());
	      }).catch((error: Error) => console.log(error));
    });
  }

  private searchForPlanets = () => {
  	const { sortIdx, sortAsc } = this.state;
  	const { searchRequest } = this.props;
  	this.setState({ 
    	planetsLoaded: false,
    	selectedPlanetPoint: -1
    }, () => {
    	const sortBy = sortIdx === 0
    		? 'name'
    		: sortIdx === 1
    			? 'point'
    			: 'parent';
    	const sortDir = sortAsc ? 'asc' : 'desc';
    	fetch(`${this.props.apiBaseURL}/search?scope=${searchRequest.scope}&query=${searchRequest.query}&page=${searchRequest.page}&sortBy=${sortBy}&sortDir=${sortDir}`).then(res => res.json())
	      .then((searchResults: { results: Planet[], resultsCount: number, pageCount: number }) => {
	      	this.setState({ 
		      	planetsLoaded: true,
		      	planets: searchResults.results,
		      	completedSearch: { 
		      		scope: searchRequest.scope,
		      		query: searchRequest.query,
		      		resultsCount: searchResults.resultsCount,
		      		pageCount: searchResults.pageCount
		      	}
		      }, () => {
		      	this.setOrientation();
		      	this.lookupStatus();
		      });
	      }).catch((error: Error) => console.log(error));
    });
  }

  private lookupStatus = () => {
  	const planet = this.state.planets.find(planet => planet.available === undefined);
  	if (!!planet) {
  		this.retrieveAvailableStatus(planet)
	  		.then(() => this.lookupStatus())
	  		.catch((error: Error) => console.log(error));
  	}
  }

  private retrieveAvailableStatus = (selectedPlanet: Planet): Promise<boolean> => {
  	return new Promise((resolve, reject) => {
	  	fetch(`${this.props.apiBaseURL}/available?ship=${selectedPlanet.point}`).then(res => res.json())
	      .then((status: { available: boolean }) => {
	      	const planetRecord = selectedPlanet;
	      	planetRecord.available = status.available;
	      	const planetsCopy = this.state.planets;
	      	planetsCopy.splice(planetsCopy.indexOf(selectedPlanet), 1, planetRecord);
	      	this.setState({ planets: planetsCopy }, () => resolve(status.available));
	      }).catch((error: Error) => reject(error));
    });
  }

  private retrieveOwnedShips = () => {
  	const wndw = (window as any);
  	this.setState({ retrievedOwnedShips: false }, () => {
	  	wndw.ethereum.enable().then((accounts: string[]) => {
				// console.log('Owned ships - account:', this.props.wallet.account, 'networkId:', this.props.wallet.networkId);
		  	fetch(`${this.props.apiBaseURL}/owned?address=${accounts[0]}&networkId=42`).then(res => res.json())
		      .then((res: { ownedPoints: number[] }) => {
		      	this.setState({ 
		      		ownedShips: res.ownedPoints,
		      		retrievedOwnedShips: true 
		      	});
		      }).catch((error: Error) => console.log(error));

			}).catch((error: Error) => console.log(error));
	  });
  }

  private setOrientation = () => {
  	const { planets, planetsLoaded } = this.state;
  	const portrait = (window.innerWidth / window.innerHeight) < 1;
  	const fixFooter = isMobile && !isTablet
  		? !planetsLoaded
  		: !planetsLoaded && planets.length === 0
  			? true
  			: window.innerHeight >= document.body.scrollHeight;
	  this.setState({ portrait, fixFooter });
	}
}

export default Buy;