import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { InputGroup, Button, Form, FormControl, Dropdown, DropdownButton, Navbar, Nav, OverlayTrigger, Popover, ListGroup, Spinner, Table } from 'react-bootstrap';
import { Sigil } from './';
import { HeaderMetadata, SalesSpan, SalesStatsSummary } from './types/header';
import logo from '../urbit-live-logo-png-400.png';
import web3 from 'web3';
import ob from 'urbit-ob';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/Header.css';

interface Props {
	apiBaseURL: string;
	metadata: HeaderMetadata;
	validateName:((input: string) => boolean);
}

interface State {
	currentInput: string;
	searchMode: number;
	modeWasSet: boolean;
	galaxyScope: number;
	portrait: boolean;
	validEntry: boolean;
	showInvalidQueryFeedback: boolean;
	selectedCurrency: number;
	salesStatsSummaryLoaded: boolean;
	salesStatsSummary: SalesStatsSummary;
}

class Header extends Component<Props, State> {
	public state = {
		currentInput: '',
		searchMode: 0, // 0 => search available planets, 1 => search entire network
		modeWasSet: false,
		galaxyScope: 0,
		portrait: true,
		validEntry: true,
		showInvalidQueryFeedback: false,
		selectedCurrency: 0,
		salesStatsSummaryLoaded: false,
		salesStatsSummary: Object() as SalesStatsSummary
	}

	public componentDidMount() {
	  this.setOrientation();
	  window.addEventListener('resize', this.setOrientation);
	}

	public componentDidUpdate = (prevProps: Props) => {
		const { metadata } = this.props;
		if (this.state.searchMode !== metadata.searchMode &&
			 	prevProps.metadata.searchMode !== metadata.searchMode && 
			 	!this.state.modeWasSet) {
			this.setState({ 
				searchMode: metadata.searchMode, 
				modeWasSet: true 
			});
		}
		if (!!metadata.query && 
				prevProps.metadata.query !== metadata.query && 
				metadata.query !== this.state.currentInput) {
			this.setState({ currentInput: metadata.query });
		}
		if (prevProps.metadata.scope !== metadata.scope && 
				metadata.scope !== this.state.galaxyScope) {
			this.setState({ galaxyScope: metadata.scope });
		}
		if (prevProps.metadata.activeLink !== metadata.activeLink &&
				metadata.activeLink === 0) {
			this.retrieveStatsSummary();
		}
	}

	public componentWillUnmount() {
	  window.removeEventListener('resize', this.setOrientation);
	}

  public render() {
  	const { metadata } = this.props;
  	const { portrait, salesStatsSummaryLoaded } = this.state;
  	const navbarDisplay = isMobile && !isTablet 
  		? portrait
  			? 'inline-block'
  			: 'flex'
  		: '';
  	const navbarClass = isMobile && !isTablet ? 'navbar-mobile' : 'justify-content-between navbar-desktop';
  	const desc = 'urbit live is the largest Urbit planet market and network explorer. Over 1 million Urbit planets for sale. Search the entire network from planets all the way up to galaxies!';
  	const titleSuffix = metadata.activeLink === 0
  		? ' | Urbit planet market and network explorer'
  		: ' | urbit live | Urbit planet market and network explorer';
  	const navbarBrand = 
  		<Navbar.Brand
    		href='/'
    		style={{ marginLeft: '12px', marginRight: '6px', marginTop: '15px' }}
    	>
	      <img src={logo} style={{ height: '42px' }} className='navbar-brand-img'/>
	    </Navbar.Brand>;
  	const statsSummary = metadata.activeLink === 0
    	? salesStatsSummaryLoaded
				? this.generateStatsSummaryTable()
				: <div className='stats-summary-spinner'>
						<Spinner animation='border' variant='light' size='sm'/>
					</div>
			: null;
		const searchField = metadata.activeLink === 0 
			? null 
			: this.generateSearchField();
    return (
    	<header>
	    	<Helmet>
	    		<meta property='og:title' content={metadata.title + titleSuffix}/>
	        <meta property='og:description' content={desc}/>
	        <meta property='og:type' content='website'/>
	        <title>{metadata.title + titleSuffix}</title>
	      </Helmet>
			  {isMobile && !isTablet
      		? <Navbar 
		        	className={navbarClass}
		        	style={{ display: navbarDisplay, alignItems: 'normal' }}
		        >
		        	{metadata.activeLink === 0
		        		? <div style={{ display: 'inline-flex' }}>
				        		{navbarBrand}
					        	{statsSummary}
					        </div>
				        : navbarBrand}
		        	{this.generateNavLinks()}
		        	{searchField}
		        </Navbar>
      		: <Navbar 
		        	className={navbarClass}
		        	style={{ display: navbarDisplay, alignItems: 'normal' }}
		        >
			        {navbarBrand}
					    {this.generateNavLinks()}
					    {statsSummary}
					    {searchField}
					  </Navbar>}
		  </header>
    );
  }

  private generateNavLinks = (): JSX.Element => {
  	const { portrait } = this.state;
  	const { metadata } = this.props;
  	const navStyle = isMobile && !isTablet 
  		? portrait
  			? { display: 'inline-flex' }
  			: metadata.activeLink === 0
  				? { display: 'inline-flex', marginLeft: 'auto' }
  				: { display: 'inline-flex' }
  		: { marginRight: 'auto' };
  	const buyClass = metadata.activeLink === 1 ? 'header-nav-link-selected' : 'header-nav-link-hover';
  	const buyLabel = isMobile && !isTablet ? 'Buy' : 'Buy planets';
  	const exploreClass = metadata.activeLink === 2 || metadata.activeLink === 3 ? 'header-nav-link-selected' : 'header-nav-link-hover';
  	const statsClass = metadata.activeLink === 4 ? 'header-nav-link-selected' : 'header-nav-link-hover';
  	return (
  		<Nav style={navStyle}>
  			<Nav.Link 
	      	href='/buy' 
	      	className={buyClass}
	      >
	      	Buy planets
				</Nav.Link>
{/*				<div className='dropdown nav-item'>
					<a
						href='/buy'
						className='header-nav-link-hover dropdown-toggle nav-link'
						role='button'
					>
						Buy	
					</a>	
					<div className='dropdown-menu'>
						<a 	
							href='/buy#planets' 	
							className='dropdown-item header-nav-dropdown-item'	
						>	
							Planets	
						</a>	
						<a 	
							href='/buy#stars'	
							className='dropdown-item header-nav-dropdown-item'	
						>	
							Stars	
						</a>
					</div>
				</div>*/}
				<Nav.Link 
	      	href='/stats' 
	      	className={statsClass}
	      >
	      	Sales stats
				</Nav.Link>
				<Nav.Link 
	      	href='/explore' 
	      	className={exploreClass}
	      >
	      	Explore
				</Nav.Link>
				<Nav.Link 
	      	href='https://blog.urbit.live' 
	      	className='header-nav-link-hover'
	      >
	      	Blog
	      </Nav.Link>
	    </Nav>
  	);
  }

  private generateSearchField = (): JSX.Element => {
  	const { searchMode, galaxyScope, portrait, currentInput, validEntry, showInvalidQueryFeedback } = this.state;
  	const buttonTitle = searchMode === 0
  		? 'For sale '
  		: 'Galaxy ';
  	const availableTitle = (searchMode === 0 ? '\u2713 ' : '') + 'Find planets for sale';
  	const networkTitle = (searchMode === 1 ? '\u2713 ' : '') + 'Search a galaxy ';
  	const groupStyle = isMobile && !isTablet
  		? portrait
  			? { marginRight: 'auto', marginLeft: 'auto', maxWidth: '95vw' } 
  			: { marginRight: 'auto', marginLeft: 'auto', maxWidth: '40vw' } 
  		: { width: '370px', color: 'white', paddingRight: '10px', paddingTop: '20px' };
  	const formStyle = isMobile && !isTablet
  		? portrait
  			? { marginTop: '20px' }
  			: { marginTop: '0', marginLeft: 'auto' }
  		: {};
  	return (
  		<Form 
  			inline
  			onSubmit={this.performSearch}
  			style={formStyle}
  		>
		  	<InputGroup 
		  		size='lg' 
		  		style={groupStyle}
		  	>
		  		<DropdownButton
			      as={InputGroup.Prepend}
			      variant='outline-light'
			      title={buttonTitle}
			      id='dropdown-5'
			    >
			      <Dropdown.Item 
			      	onClick={(e: React.BaseSyntheticEvent) => this.setState({ searchMode: Number(e.currentTarget.id) })}
			      	id='0'
			      >
			      	{availableTitle}
			      </Dropdown.Item>
			      <Dropdown.Divider/>
			      <Dropdown.Item 
			      	onClick={(e: React.BaseSyntheticEvent) => this.setState({ searchMode: Number(e.currentTarget.id) })}
			      	id='1'
			      >
			      	{networkTitle}
			      </Dropdown.Item>
			    </DropdownButton>
			    <FormControl 
			    	type='search'
			    	placeholder='search'
			    	spellCheck={false}
			    	autoCorrect='off'
			    	autoCapitalize='off'
			    	autoComplete='off'
			    	onInput={this.processInput}
			    	defaultValue={currentInput}
			    	isInvalid={!validEntry || showInvalidQueryFeedback}
			    />
		    	{searchMode === 0
		    		? isMobile && !isTablet
		    			? null
		    			: (<InputGroup.Append>
					      	<Button 
					      		onClick={this.performSearch} 
					      		variant='outline-secondary' 
					      	>
						      	<i 
						      		className='fas fa-search' 
						      		style={{ color: 'white' }}
						      	/>
					      	</Button>
						    </InputGroup.Append>)
		    		: (<InputGroup.Append>
			    			<OverlayTrigger
			    				trigger='click'
			    				// placement='bottom' 
			    				overlay={this.generateGalaxyPopover()}
			    			>
						      <Button variant='outline-light' style={{ paddingTop: '6px', paddingLeft: '8px', paddingRight: '8px' }}>
						    		{ob.patp(galaxyScope.toString())}
						    		<i className='fas fa-sort-down' style={{ color: 'white', fontSize: 'large', verticalAlign: 'super', paddingLeft: '8px' }}/>
						    	</Button>
						    </OverlayTrigger>
		    			</InputGroup.Append>)}
				  {showInvalidQueryFeedback
				  	? <Form.Control.Feedback type='invalid'>
						  	Search must be at least three characters
						  </Form.Control.Feedback>
				  	: <Form.Control.Feedback type='invalid'>
						  	{`${currentInput} has no matches `}
						  	<a
						  		href='https://medium.com/@urbitlive/an-intro-to-urbit-names-e54b06ec984b'
						  		target='_blank'
						  		style={{ color: 'white', marginLeft: '5px' }}
						  	>
						  		Learn why
						  	</a>
						  </Form.Control.Feedback>}
			  </InputGroup>
		  </Form>
	  )
  }

  private generateGalaxyPopover = () => {
  	const galaxyRowArray = [...Array(Math.pow(2, 8)).keys()].map((galaxyPoint, idx) => {
  		const idxString = idx.toString();
  		const galaxyName = ob.patp(idxString);
  		return this.state.galaxyScope === idx
				? <ListGroup.Item 
	  				action 
	  				as='button'
	  				id={idxString}
	  				key={idxString}
	  				onClick={() => this.setState({ galaxyScope: idx })}
	  				className='selected-galaxy-item'
	  			>
	  				<div style={{ fontSize: '1.25rem' }}>
					    {'\u2713'}
				    </div>
				    <div style={{ fontSize: '1.75rem', marginLeft: '35px' }}>
					    <b>{galaxyName}</b>
				    </div>
				    <Sigil 
					  	patp={galaxyName}
					  	size={50}
					  	margin={3}
					  	color='#000000'
					  />
				  </ListGroup.Item>
			  : <ListGroup.Item 
	  				action 
	  				as={'button'}
	  				id={idxString}
	  				key={idxString}
	  				onClick={() => this.setState({ galaxyScope: idx })}
	  				className='galaxy-item'
	  			>
	  				<div style={{ fontSize: '1.2rem', marginLeft: '4vmin' }}>
					    {`${idxString}.`}
				    </div>
				    <div style={{ fontSize: '1.2rem', marginLeft: '3vmin' }}>
					    <b>{galaxyName}</b>
				    </div>
				  </ListGroup.Item>;
		});
  	const popoverClass = isMobile ? 'galaxy-popover-mobile' : 'galaxy-popover';
  	return (
  		<Popover 
		  	id='popover' 
		  	title='Select a galaxy to search' 
		  	className={popoverClass}
		  >
		    <ListGroup>
				  {galaxyRowArray}
				</ListGroup>
		  </Popover>
		);
  }

  private generateStatsSummaryTable = (): JSX.Element => {
  	const { selectedCurrency, salesStatsSummary } = this.state;
  	const displayStyle = isMobile && !isTablet 
  		? 'inline-block'
  		: 'block';
		const volumeKey = selectedCurrency === 0
			? 'volumeUsd'
			: selectedCurrency === 1
				? 'volumeBtc'
				: 'volumeEth';
		const unit = selectedCurrency === 0 ? '' : selectedCurrency === 1 ? ' BTC' : ' ETH';
		const currencySymbol = selectedCurrency === 0 ? '$' : '';
		const decimalPlaces = selectedCurrency === 0 ? 2 : selectedCurrency === 1 ? 4 : 2;
		const greenColor = '#00C853';
		const redColor = '#CC0000';
		const infinity = 
			<p style={{ color: greenColor, display: 'inline-flex' }}>
				+
				<p className='stats-summary-table-data-infinity'>
					∞
				</p>
			</p>;
		// planet average
		const planetsAvg = salesStatsSummary.planets.recent.txCount === 0
			? (0).toFixed(decimalPlaces)
			: (salesStatsSummary.planets.recent[volumeKey] / salesStatsSummary.planets.recent.txCount).toFixed(decimalPlaces);
		const formattedPlanetsAvg = salesStatsSummary.planets.recent.txCount === 0 || selectedCurrency !== 0
			? planetsAvg
			: this.addCommasToDollarValue(planetsAvg);
		// planet average delta
		const planetsAvgDeltaValue = salesStatsSummary.planets.recent.txCount === 0 && salesStatsSummary.planets.prior.txCount > 0
			? -100
			: salesStatsSummary.planets.prior.txCount === 0
				? 0
				: (Math.round((((salesStatsSummary.planets.recent[volumeKey] / salesStatsSummary.planets.recent.txCount) - (salesStatsSummary.planets.prior[volumeKey] / salesStatsSummary.planets.prior.txCount)) / (salesStatsSummary.planets.prior[volumeKey] / salesStatsSummary.planets.prior.txCount)) * 10000) / 100).toFixed(0);
		const planetsAvgDeltaEl = salesStatsSummary.planets.prior.txCount === 0
			?	infinity
			: <p style={{ color: (planetsAvgDeltaValue > 0 ? greenColor : redColor), marginBottom: '0px' }}>
					{`${planetsAvgDeltaValue > 0 ? '+' : ''}${planetsAvgDeltaValue}%`}
				</p>;
		// planet volume
		const planetsRecentVolume = salesStatsSummary.planets.recent[volumeKey].toFixed(decimalPlaces);
		const formattedPlanetsVolume = selectedCurrency === 0
			? this.addCommasToDollarValue(planetsRecentVolume)
			: planetsRecentVolume;
		// planet volume delta
		const planetsVolDeltaValue = salesStatsSummary.planets.prior.txCount === 0
			? 0
			: (Math.round(((salesStatsSummary.planets.recent[volumeKey] - salesStatsSummary.planets.prior[volumeKey]) / salesStatsSummary.planets.prior[volumeKey]) * 10000) / 100).toFixed(0);
		const planetsVolDeltaEl = salesStatsSummary.planets.prior.txCount === 0
			?	infinity
			: <p style={{ color: (planetsVolDeltaValue > 0 ? greenColor : redColor), marginBottom: '0px' }}>
					{`${planetsVolDeltaValue > 0 ? '+' : ''}${planetsVolDeltaValue}%`}
				</p>;
		// star average
		const starsAvg = salesStatsSummary.stars.recent.txCount === 0
			? (0).toFixed(decimalPlaces)
			: (salesStatsSummary.stars.recent[volumeKey] / salesStatsSummary.stars.recent.txCount).toFixed(decimalPlaces);
		const formattedStarsAvg = selectedCurrency !== 0
			? starsAvg
			: salesStatsSummary.stars.recent.txCount === 0
				? '-'
				: this.addCommasToDollarValue(starsAvg);
		// star average delta
		const zeroRecentStarTxs = salesStatsSummary.stars.recent.txCount === 0 && salesStatsSummary.stars.prior.txCount > 0;
		const starsAvgDeltaValue = zeroRecentStarTxs
			? -100
			: salesStatsSummary.stars.prior.txCount === 0
				? 0
				: (Math.round((((salesStatsSummary.stars.recent[volumeKey] / salesStatsSummary.stars.recent.txCount) - (salesStatsSummary.stars.prior[volumeKey] / salesStatsSummary.stars.prior.txCount)) / (salesStatsSummary.stars.prior[volumeKey] / salesStatsSummary.stars.prior.txCount)) * 10000) / 100).toFixed(0);
		const starsAvgDeltaElColor = zeroRecentStarTxs
			? '#FFFFFF'
			: starsAvgDeltaValue > 0 
				? greenColor 
				: redColor;
		const starsAvgDeltaString = zeroRecentStarTxs
			? '-'
			: `${starsAvgDeltaValue > 0 ? '+' : ''}${starsAvgDeltaValue}%`;
		const starsAvgDeltaEl = salesStatsSummary.stars.prior.txCount === 0
			?	infinity
			: <p style={{ color: starsAvgDeltaElColor, marginBottom: '0px' }}>
					{starsAvgDeltaString}
				</p>;
		// star volume
		const starsVolume = salesStatsSummary.stars.recent[volumeKey].toFixed(decimalPlaces);
		const formattedStarsVolume = selectedCurrency === 0
			? this.addCommasToDollarValue(starsVolume)
			: starsVolume;
		// star volume delta
		const starsVolDeltaValue = salesStatsSummary.stars.prior.txCount === 0
			? 0
			: (Math.round(((salesStatsSummary.stars.recent[volumeKey] - salesStatsSummary.stars.prior[volumeKey]) / salesStatsSummary.stars.prior[volumeKey]) * 10000) / 100).toFixed(0);
		const starsVolDeltaEl = salesStatsSummary.stars.prior.txCount === 0
			?	infinity
			: <p style={{ color: starsVolDeltaValue > 0 ? greenColor : redColor, marginBottom: '0px' }}>
					{`${starsVolDeltaValue > 0 ? '+' : ''}${starsVolDeltaValue}%`}
				</p>;
		return (
			<div className='stats-summary-table-container notranslate' lang='en-US'>
				<Table 
					hover 
					variant='dark'
					style={{ backgroundColor: 'transparent', marginBottom: '2px' }}
					lang='en-US'
				>
					<thead>
				    <tr>
				      <th className='stats-summary-table-header-row notranslate' lang='en-US'></th>
				      <th className='stats-summary-table-header-row notranslate' lang='en-US'>Avg (7d)</th>
				      <th 
				      	className='stats-summary-table-header-row notranslate' 
				      	lang='en-US'
				      >
				      	Avg 𝚫
				      </th>
				      <th 
				      	className='stats-summary-table-header-row notranslate'
				      	lang='en-US'
				      >
				      	Vol (7d)
				      </th>
				      <th 
				      	className='stats-summary-table-header-row notranslate'
				      	lang='en-US'
				      >
				      	Vol 𝚫
				      </th>
				    </tr>
				  </thead>
				  <tbody style={{ backgroundColor: 'transparent' }}>
				  	<tr>
		  				<td 
		  					className='stats-summary-table-data-row notranslate'
		  					style={{ textAlign: 'right' }}
		  					lang='en-US'
		  				>
		  					Planets:
		  				</td>
		  				<td 
		  					className='stats-summary-table-data-row notranslate'
		  					style={{ textAlign: 'left' }}
		  					lang='en-US'
		  				>
		  					<a 
		  						onClick={this.clickedCurrencyButton}
		  						style={{ cursor: 'pointer' }}
		  						lang='en-US'
		  						className='notranslate'
		  					>
			  					{`${currencySymbol}${formattedPlanetsAvg}${unit}`}
			  				</a>
		  				</td>
		  				<td 
		  					className='stats-summary-table-data-row notranslate'
		  					style={{ textAlign: 'center' }}
		  					lang='en-US'
		  				>
			  				{planetsAvgDeltaEl}
		  				</td>
		  				<td 
		  					className='stats-summary-table-data-row notranslate'
		  					style={{ textAlign: 'left' }}
		  					lang='en-US'
		  				>
		  					<a 
		  						onClick={this.clickedCurrencyButton}
		  						style={{ cursor: 'pointer' }}
		  						lang='en-US'
		  						className='notranslate'
		  					>
			  					{`${currencySymbol}${formattedPlanetsVolume}${unit}`}
			  				</a>
		  				</td>
		  				<td 
		  					className='stats-summary-table-data-row notranslate'
		  					style={{ textAlign: 'center' }}
		  					lang='en-US'
		  				>
			  				{planetsVolDeltaEl}
		  				</td>
		  			</tr>
		  			<tr>
		  				<td 
		  					className='stats-summary-table-data-row notranslate'
		  					style={{ textAlign: 'right' }}
		  					lang='en-US'
		  				>
		  					Stars:
		  				</td>
		  				<td 
		  					className='stats-summary-table-data-row notranslate'
		  					style={{ textAlign: zeroRecentStarTxs ? 'center' : 'left' }}
		  					lang='en-US'
		  				>
		  					<a 
		  						onClick={this.clickedCurrencyButton}
		  						style={{ cursor: 'pointer' }}
		  						lang='en-US'
		  						className='notranslate'
		  					>
			  					{`${zeroRecentStarTxs ? '' : currencySymbol}${formattedStarsAvg}${unit}`}
			  				</a>
		  				</td>
		  				<td 
		  					className='stats-summary-table-data-row notranslate'
		  					style={{ textAlign: 'center' }}
		  					lang='en-US'
		  				>
		  					{starsAvgDeltaEl}
		  				</td>
		  				<td 
		  					className='stats-summary-table-data-row notranslate'
		  					style={{ textAlign: 'left' }}
		  					lang='en-US'
		  				>
		  					<a 
		  						onClick={this.clickedCurrencyButton}
		  						style={{ cursor: 'pointer' }}
		  						lang='en-US'
		  						className='notranslate'
		  					>
			  					{`${currencySymbol}${formattedStarsVolume}${unit}`}
			  				</a>
		  				</td>
		  				<td 
		  					className='stats-summary-table-data-row notranslate'
		  					style={{ textAlign: 'center' }}
		  					lang='en-US'
		  				>
		  					{starsVolDeltaEl}
		  				</td>
		  			</tr>
				  </tbody>
				</Table>
			</div>
		);
  }

  private clickedCurrencyButton = () => {
  	const selectedCurrency = this.state.selectedCurrency === 2
  		? 0
  		: this.state.selectedCurrency + 1;
  	this.setState({ selectedCurrency });
  }

  private addCommasToDollarValue = (amount: string): string => {
  	const decimalValue = amount.split('.');
  	return decimalValue[0].split('').reverse().map((char, i) => 
  		char + (i % 3 === 0 && i !== 0 ? ',' : '')
  	).reverse().join('') + '.' + decimalValue[1];
  }

  private processInput = (e: React.FormEvent<HTMLInputElement>) => {
  	e.preventDefault();
  	this.cleanSearchInput(e);
  }

  private cleanSearchInput = (input: React.BaseSyntheticEvent<Event, EventTarget & HTMLInputElement, EventTarget>) => {
  	/[\d]/.test(input.currentTarget.value)
  		? this.cleanPoint(input)
  		: this.cleanName(input);
	};

	private cleanName = (input: React.BaseSyntheticEvent<Event, EventTarget & HTMLInputElement, EventTarget>) => {
		if (input.currentTarget.value.length >= 1) {
			const currentInput = input.currentTarget.value.substring(0, 14);
  		this.setState({ 
  			currentInput,
  			validEntry: this.props.validateName(currentInput)
  		});
  		input.currentTarget.value = currentInput;
	  } else {
	  	this.setState({ 
	  		currentInput: '',
	  		validEntry: true
	  	});
	  }

	  if (this.state.showInvalidQueryFeedback && 
	  		input.currentTarget.value.length > 2) {
	  	this.setState({ showInvalidQueryFeedback: false });
	  }
	}

	private cleanPoint = (input: React.BaseSyntheticEvent<Event, EventTarget & HTMLInputElement, EventTarget>) => {
		const shipPoint = input.currentTarget.value.replace(/[^0-9]{0,10}/g, '');
		if (input.currentTarget.value.length > 1 && 
				input.currentTarget.value.substring(0, 2) === '0x') {
			web3.utils.isAddress(input.currentTarget.value)
				? window.open(`/address/${input.currentTarget.value}`, '_self')
				: this.setState({ currentInput: input.currentTarget.value });
		} else if (!!shipPoint && input.currentTarget.value.length > 0) {
			const maxPoint = Math.pow(2, 32) - 1;
			const shipPointNumber = Number(shipPoint);
			const cleanShipPointNumber = shipPointNumber < 0
				? 0
				: shipPointNumber > maxPoint
					? maxPoint
					: !Number.isInteger(shipPointNumber)
						? Math.floor(shipPointNumber)
						: shipPointNumber;
	  	this.setState({ currentInput: cleanShipPointNumber.toString() });
	  	input.currentTarget.value = cleanShipPointNumber.toString();
	  }
	}

 	private retrieveStatsSummary = () => {
  	fetch(`${this.props.apiBaseURL}/statsSummary`).then(res => res.json())
      .then((stats: { salesStatsSummary: SalesStatsSummary }) => {
      	if (!!stats.salesStatsSummary && !!stats.salesStatsSummary.planets) {
      		this.setState({ 
	    			salesStatsSummary: stats.salesStatsSummary,
	    			salesStatsSummaryLoaded: true
	    		});
      	}
	    }).catch((error: Error) => console.log(error));
  }

	private performSearch = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>) => {
		const { currentInput, searchMode } = this.state;
  	e.preventDefault();
  	const query = currentInput.toLowerCase();
  	if (query.length === 0) {
  		return;
  	}
  	const queryIsText = /[^\d]/.test(query);
  	if (!queryIsText || query.length > 2) {
	  	const nameWithTilde = query.charAt(0) === '~' 
	  		? query 
	  		: '~' + query;
	  	const queryIsPlanetName = queryIsText && query.length > 8 && ob.isValidPatp(nameWithTilde);
	  	if (!queryIsText) {
	  		window.open(`/${query}`, '_self');
	  	} else if (queryIsPlanetName) {
	  		window.open(`/${nameWithTilde}`, '_self');
	  	} else if (searchMode === 0) {
	  		window.open(`/buy?query=${query}`, '_self');
	  	} else {
	  		window.open(`/explore?scope=[${this.state.galaxyScope}]&query=${query}`, '_self');
	  	}
	  } else {
	  	this.setState({ showInvalidQueryFeedback: true });
	  }
  }

  private setOrientation = () => {
  	const portrait = (window.innerWidth / window.innerHeight) < 1;
	  this.setState({ portrait });
	}
  				    	
}

export default Header;
