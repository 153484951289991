import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Col, Container, Jumbotron } from 'react-bootstrap';
import { Footer } from './';
import { HeaderMetadata } from './types/header';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/Help.css';

interface Props {
	web3Enabled: boolean;
	updateHeader:((metadata: HeaderMetadata) => void);
}

interface State {
	portrait: boolean;
	fixFooter: boolean;
}

class Help extends Component<Props, State> {
	public state = {
		portrait: true,
		fixFooter: true
	}

	public componentDidMount = () => {
	  window.addEventListener('resize', this.setOrientation);
		this.props.updateHeader({
			activeLink: -1,
			searchMode: 0,
			title: 'Help',
			scope: 0
		});
		setTimeout(this.setOrientation, 500);
	}

	public componentWillUnmount() {
	  window.removeEventListener('resize', this.setOrientation);
	}

  public render() {
  	const { portrait, fixFooter } = this.state;
  	const helpMarginTop = isMobile && !isTablet
  		? portrait
  			? '20px'
  			: '10px'
  		: '10px';
  	const scrollStyle = isMobile || isTablet
  		? 'touch'
  		: 'auto';
  	const helpStyle = {
  		paddingTop: helpMarginTop,
  		WebkitOverflowScrolling: scrollStyle,
  		overflowScrolling: scrollStyle,
  		backgroundColor: '#212121'
  	} as React.CSSProperties;
    return (
    	<div style={helpStyle}>
	    	{this.generateLetter()}
	    	<Footer fixedToBottom={fixFooter}/>
    	</div>
    );
  }

  private generateLetter = (): JSX.Element => {
  	const letterClassname = 'help-letter' + (isMobile && !isTablet ? '-mobile' : '');
		const letterContainerClassname = 'help-letter-container' + (isMobile && !isTablet ? '-mobile' : '');
  	const walletInfo = isMobile
  		? { name: 'Trust Wallet', url: 'https://trustwallet.com' } 
  		: { name: 'Metamask', url: 'https://metamask.io' };
  	const planetPrice = 0.045;
  	const circleIcon = 'far fa-circle';
  	const checkIcon = 'far fa-check-circle';
  	const checklistIcon1 = this.props.web3Enabled ? checkIcon : circleIcon;
  	const checklistIcon2 = circleIcon;
  	const checklistMarginLeft = isMobile && !isTablet ? '20px' : '30%';
  	const signoffMarginLeft = isMobile && !isTablet ? '40%' : '60%';
  	return (
  		<Jumbotron className={letterContainerClassname}>
  			<Container className={letterClassname}>
					<p>
						If you want an Urbit planet you've come to the right place. Here's some info you want to know:
					</p>
					<ul>
						<li>
							{`To purchase a planet you need an Ethereum web3 wallet and ${planetPrice.toString()}* ETH. We recommend `}
							<a 
								href={walletInfo.url} 
								target='_blank'
							>
								{walletInfo.name}
							</a>
							, but there are many options.
						</li>
						<li>
							Urbit set some ground rules for distributing planets.
							<b>{' ~10% of them can be spawned through 2021. '}</b>
							More planets will be available in 2022.
						</li>
					</ul>
					<Col 
						className='checklist-container'
						// md={{ span: 8, offset: 2 }}
					>
					  <h3>Your checklist</h3>
					  <div style={{ textAlign: 'left', marginLeft: checklistMarginLeft }}>
					  	<h4>
						  	<i className={`checklist-item ${checklistIcon1}`}/>
						  	&nbsp;{' Ethereum wallet'}
					  	</h4>
					  	<h4>
						  	<i className={`checklist-item ${checklistIcon2}`}/>
						  	&nbsp;{` ${planetPrice.toString()} ETH`}
					  	</h4>
					  </div>
				  </Col>
				  <p style={{ marginTop: '15px' }}>
				  	{'If you\'re completely lost, start '}
				  	<a 
				  		href='https://urbit.org/understanding-urbit'
				  		target='_blank'
				  	>
				  		{'here'}
				  	</a>
				  	{' and join us in our '}
				  	<a 
				  		href='https://t.me/UrbitLiveGroup'
				  		target='_blank'
				  	>
				  		{'Telegram group'}
				  	</a>
				  	{' to get your questions answered.'}
				  </p>
				  <p style={{ fontSize: '1.2rem', marginLeft: signoffMarginLeft }}>
					  -the urbit live team
				  </p>
				  <p style={{ fontSize: '0.7rem', marginTop: '50px' }}>
						* A planet costs 0.025 ETH, but you need enough ETH to cover the transaction fee
					</p>
				</Container>
			</Jumbotron>
		);
  }

  private setOrientation = () => {
  	const portrait = (window.innerWidth / window.innerHeight) < 1;
  	const fixFooter = window.innerHeight >= document.body.scrollHeight;
	  this.setState({ portrait, fixFooter });
	}

}

export default Help;
