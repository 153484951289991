import React, { Component } from 'react';
import { isMobile, isTablet, isIOS } from 'react-device-detect';
import { Row, Container, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/Footer.css';

interface Props {
	fixedToBottom: boolean;
}

interface State {
	emailAddressCopied: boolean;
}

class Footer extends Component<Props, State> {
	public state = {
		emailAddressCopied: false
	}

	private constants = {
		emailAddress: 'hi@urbit.live',
		twitterURL: 'https://twitter.com/urbitlive',
		telegramURL: 'https://t.me/UrbitLiveGroup',
		mediumURL: 'https://medium.com/@urbitlive',
		githubURL: 'https://github.com/urbitlive'
	};

  public render() {
  	const { emailAddressCopied } = this.state;
  	const { emailAddress, twitterURL, telegramURL, mediumURL } = this.constants;
  	const fixed = this.props.fixedToBottom
  		? { position: 'fixed' } as React.CSSProperties
  		: { position: 'static' } as React.CSSProperties;
    return (
    	<div
    		id='footer'
    		className='contact-footer'
    		style={fixed}
    	>
    		{isMobile && !isTablet
    			? this.generateMobileFooter()
    			: this.generateDesktopFooter()}
      </div>
    );
  }

  private generateDesktopFooter = (): JSX.Element => {
  	const { emailAddressCopied } = this.state;
  	const { emailAddress, twitterURL, telegramURL, mediumURL, githubURL } = this.constants;
  	return (
  		<Container className='footer-container'>
			  <Row className='icon-group'>
				  <div className='email-group'>
	    			<p className={emailAddressCopied ? 'email-address-copied' : 'email-address'}>
	      			{emailAddressCopied
	      				? <i className='fas fa-check'/>
	      				: emailAddress}
	    			</p>
					  <button 
				  		onClick={this.copyEmailAddressTapped}
				  		className='copy-button'
				  	>
				  		<i className='far fa-copy'/>
			  		</button>
		  		</div>
		  		<a 
				  	className='media-icon'
				  	href={githubURL}
				  	target='_blank'
				  >
					  <i className='fab fa-github'/>
				  </a>
				  <a 
				  	className='media-icon'
				  	href={twitterURL}
				  	target='_blank'
				  >
					  <i className='fab fa-twitter'/>
				  </a>
				  <a 
				  	className='media-icon'
				  	href={telegramURL}
				  	target='_blank'
				  	style={{ marginRight: '20px' }}
				  >
					  <i className='fab fa-telegram'/>
				  </a>
			  </Row>
	  	</Container>
  	);
  }

  private generateMobileFooter = (): JSX.Element => {
  	const { emailAddressCopied } = this.state;
  	return (
    	<Container className='footer-container-mobile'>
	  		<Row className='icon-group-mobile'>
	  			<div className='email-group-mobile'>
	    			<p className={emailAddressCopied ? 'email-address-copied' : 'email-address'}>
	      			{emailAddressCopied
	      				? <i className='fas fa-check'/>
	      				: this.constants.emailAddress}
	    			</p>
					  <button 
				  		onClick={this.copyEmailAddressTapped}
				  		className='copy-button'
				  	>
				  		<i className='far fa-copy'/>
			  		</button>
		  		</div>
	  			<a 
				  	className='media-icon-mobile'
				  	href={this.constants.githubURL}
				  	target='_blank'
				  >
					  <i className='fab fa-github'/>
				  </a>
				  <a 
				  	className='media-icon-mobile'
				  	href={this.constants.twitterURL}
				  	target='_blank'
				  >
					  <i className='fab fa-twitter'/>
				  </a>
				  <a 
				  	className='media-icon-mobile'
				  	href={this.constants.telegramURL}
				  	target='_blank'
				  	style={{ marginRight: '0' }}
				  >
					  <i className='fab fa-telegram'/>
				  </a>
			  </Row>
		  </Container>
	  );
  }

  private copyEmailAddressTapped = () => {
  	if (document.queryCommandSupported('copy') &&
  			!this.state.emailAddressCopied) {
  		const emailAddressCopyEl = document.createElement('textarea');
	  	emailAddressCopyEl.innerText = this.constants.emailAddress;

	  	(document.getElementById('footer') as HTMLElement).appendChild(emailAddressCopyEl);
	  	emailAddressCopyEl.style.position = 'absolute';
	  	emailAddressCopyEl.style.height = '10px';
	  	emailAddressCopyEl.style.bottom = `${window.innerHeight / 2}`;
	  	emailAddressCopyEl.style.left = '0';
	  	emailAddressCopyEl.contentEditable = 'true';
	  	emailAddressCopyEl.readOnly = true;

	  	if (isIOS) {
	  		emailAddressCopyEl.focus();
	  		emailAddressCopyEl.select();
		  	const range = document.createRange();
	      range.selectNodeContents(emailAddressCopyEl);
		  	const selection = window.getSelection();
	      (selection as Selection).removeAllRanges();
	      (selection as Selection).addRange(range);
	      emailAddressCopyEl.setSelectionRange(0, 999999);
	    } else {
	  		emailAddressCopyEl.select();
	  	}

	    document.execCommand('copy');
	    emailAddressCopyEl.remove();

	    this.setState({ emailAddressCopied: true }, () => setTimeout(() => this.setState({ emailAddressCopied: false }), 3000));
	  }
  }

}

export default Footer;
