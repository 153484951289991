import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Container, Row, Button } from 'react-bootstrap';
import { Sigil } from './';
import { ShipSale, SalesStats } from './types/stats';
import ob from 'urbit-ob';

interface Props {
	selectedShipSale: ShipSale;
	selectedCurrency: number;
	salesStats: SalesStats;
	priceKey: string;
	active: any;
	payload: any;
}

class ShipSaleTooltip extends Component<Props, {}> {

  public render() {
  	const { selectedShipSale, active, payload } = this.props;
  	const data = active;  // due to a bug in Recharts, we are required to name the props 'active' and 'paylod'. 'active' is actually an object with props { active: Boolean, payload: any[] }
  	if (!data.active || (isMobile && !selectedShipSale.date)) {
  		return null;
  	} else {
  		const sale = isMobile ? selectedShipSale : data.payload[0].payload;
  		return (
				<div className='graph-tooltip'>
					{this.generateTop(sale)}
					{this.generateTooltip(sale)}
					{this.generateBottom(sale)}
				</div>
			);
  	}
		
  }

  private generateTop = (sale: any) => {
  	const { selectedCurrency, priceKey } = this.props;
		const unit = selectedCurrency === 0 ? 'USD' : selectedCurrency === 1 ? 'BTC' : 'ETH';
		const decimalPlaces = selectedCurrency === 0 ? 2 : selectedCurrency === 1 ? 5 : 3;
		const price = sale[priceKey].toFixed(decimalPlaces);
		const formattedPrice = selectedCurrency === 0 ? this.addCommasToDollarValue(price) : price;
		const displayPrice = `${selectedCurrency === 0 ? '$' : ''}${formattedPrice} ${unit}`;
  	return (
			<div className='tooltip-date-price-container'>
        <p className='tooltip-date'>
        	{`${sale.date}`}
        </p>
        <p className='tooltip-price'>
        	{displayPrice}
        </p>
      </div>
  	);
  }

  private generateTooltip = (sale: any) => {
		const planetName = ob.patp(sale.points[0]);
		return (
    	<div className='tooltip-name-sigil-container'>
    		<div className='tooltip-sigil-container'>
    			<a href={`/${planetName}`}>
		        <Sigil
		  				patp={planetName}
		  				size={40}
		  				margin={8}
		  				color='#000000'
		  			/>
	  			</a>
  			</div>
				<Button
					id={planetName}
					className='tooltip-name'
					onClick={this.clickedShipInTooltip}
				>
        	{planetName}
      	</Button>
			</div>
    );
	}

	private generateBottom = (sale: any) => {
		const othersCount = sale.points.length - 1;
		return (
			<div>
				{othersCount > 0
					? <div className='tooltip-others-container'>
			        <p className='tooltip-others'>
			        	{`+${othersCount} other${othersCount > 1 ? 's' : ''}`}
			        </p>
						</div>
					: null}
		  	{isMobile && !isTablet
		  		? null
		  		: <p className='tooltip-tip'>
		        	(click dot for details)
		        </p>}
	    </div>
		);
	}

	private clickedShipInTooltip = (e: React.MouseEvent<HTMLElement>) => {
  	window.open(`/${e.currentTarget.id}`, '_self')
  }

	private addCommasToDollarValue = (amount: string): string => {
  	const decimalValue = amount.split('.');
  	return decimalValue[0].split('').reverse().map((char, idx) => 
  		`${char}${idx % 3 === 0 && idx !== 0 ? ',' : ''}`
  	).reverse().join('') + '.' + decimalValue[1];
  }

}

export default ShipSaleTooltip;