import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Container, Card, CardDeck, Spinner } from 'react-bootstrap';
import { Footer, Sigil } from './';
import { HeaderMetadata } from './types/header';
import web3 from 'web3';
import ob from 'urbit-ob';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/ShipView.css';

interface Props {
	apiBaseURL: string;
	updateHeader:((metadata: HeaderMetadata) => void);
}

interface State {
	address: string;
	ownedPoints: number[];
	retrievedOwnedData: boolean;
	portrait: boolean;
	fixFooter: boolean;
}

class AddressView extends Component<Props, State> {
	public state = {
		address: '',
		ownedPoints: Array<number>(),
		retrievedOwnedData: false,
		portrait: true,
		fixFooter: true
	}

	public componentDidMount = () => {
		this.setOrientation();
	  window.addEventListener('resize', this.setOrientation);
		this.handlePassedAddressInfo(window.location.pathname.substring(9, window.location.pathname.length));
	}

	public componentWillUnmount() {
	  window.removeEventListener('resize', this.setOrientation);
	}

  public render() {
  	const { ownedPoints, retrievedOwnedData, address, portrait, fixFooter } = this.state;
  	const ownedTitle = retrievedOwnedData
  		? `owns ${ownedPoints.length} ship${ownedPoints.length === 1 ? '' : 's'}`
  		: '';
  	const addressViewMarginTop = isMobile && !isTablet 
  		? portrait
  			? '20px' 
  			: '10px'
  		: '10px';
  	const scrollStyle = isMobile || isTablet 
  		? 'touch' 
  		: 'auto';
  	const addressViewStyle = { 
  		paddingTop: addressViewMarginTop, 
  		WebkitOverflowScrolling: scrollStyle, 
  		overflowScrolling: scrollStyle,
  		backgroundColor: '#212121'
  	} as React.CSSProperties;
    return (
      <div style={addressViewStyle}>
      	{retrievedOwnedData
	      	? <div>
		      		<Container>
							  <h5 style={{ color: 'white', marginTop: '5vmin', wordBreak: 'break-all' as 'break-all' }}>{address}</h5>
								<h2 style={{ color: 'white' }}>{ownedTitle}</h2>
							</Container>
	        		<Container>
				  			{this.buildDecks(ownedPoints)}
					  	</Container> 
				  	</div>
			  	: <Container>
				  		<h2 style={{ color: 'white', marginTop: '5vmin', wordBreak: 'break-all' as 'break-all' }}>Loading</h2>
				  		<Spinner animation='border' variant='light'/>
		  			</Container>}
		  	<Footer fixedToBottom={fixFooter}/>
      </div>
    );
  }

  private buildDecks = (ownedPoints: number[]): JSX.Element[] => {
  	const cardsPerRow = isMobile && !isTablet 
  		? 2 
  		: 4;
  	const subset = ownedPoints.filter((ownedPoint, i) => i % cardsPerRow === 0);
  	return subset.map((ownedPoint, i) => {
  		const rowLeadIdx = ownedPoints.indexOf(ownedPoint);
  		const cardCount = rowLeadIdx + cardsPerRow >= ownedPoints.length 
  			? ownedPoints.length - rowLeadIdx
  			: cardsPerRow;
			const cards = this.buildCards(ownedPoints, rowLeadIdx, cardCount);
			const deckClass = rowLeadIdx === 0 
				? 'spawned-deck spawned-deck-top' 
				: rowLeadIdx + cardsPerRow >= ownedPoints.length 
					? 'spawned-deck spawned-deck-bottom' 
					: 'spawned-deck spawned-deck-other';
			return (
				<CardDeck
					className={deckClass}
					key={i}
				>
					{cards}
				</CardDeck>
			);
		});
  }

  private buildCards = (ownedPoints: number[], startingValue: number, count: number): JSX.Element[] => {
  	const subset = ownedPoints.filter((ownedPoint, i) => i >= startingValue && i < (startingValue + count));
  	return subset.map((ownedPoint, idx) => {
			const spawnedName = ob.patp(ownedPoint.toString());
			const spawnedLink = `/${spawnedName}`;
			return (
				<a 
					href={spawnedLink}
					className='spawned-card-link'
					key={idx}
				>
			  	<Card 
			  		key={ownedPoint} 
			  		bg='dark' 
			  		id={ownedPoint.toString()}
			  		className='spawned-card'
			  	>
			  		<Card.Header as='h6' className='spawned-card-header'>
			  			{spawnedName}
			  		</Card.Header>
			  		<div 
			  			className='ship-view-card-sigil-container'
			  			key={ownedPoint.toString()}
			  		>
						  <Sigil 
						  	patp={spawnedName}
						  	size={160}
						  	margin={20}
						  	color='#000000'
						  />
					  </div>
					</Card>
				</a>
			);
		});
  }

  private handlePassedAddressInfo = (addressInfo: string) => {
  	web3.utils.isAddress(addressInfo)
			? this.retrieveOwnedPoints(addressInfo)
			: window.open(`/~zod`, '_self');
  }

  private retrieveOwnedPoints = (address: string) => {
  	this.props.updateHeader({
  		activeLink: 2,
  		searchMode: 0,
  		title: address.substring(0, 12) + '...',
  		scope: 0
  	});
		this.setState({ 
			address,
			retrievedOwnedData: false
		}, () => {
	  	fetch(`${this.props.apiBaseURL}/owned?address=${this.state.address}`).then(res => res.json())
	      .then((ownedData: { ownedPoints: number[] }) => {
		      this.setState({ 
		      	retrievedOwnedData: true, 
		      	ownedPoints: ownedData.ownedPoints
		      }, () => this.setOrientation());
	      }).catch(error => console.log(error));
    });
  }

  private setOrientation = () => {
  	const portrait = (window.innerWidth / window.innerHeight) < 1;
  	const fixFooter = window.innerHeight >= document.body.scrollHeight;
	  this.setState({ portrait, fixFooter });
	}
}

export default AddressView;
