import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Container, Row, Button, Card, CardDeck, Spinner, Pagination, Col, Jumbotron, Table, Modal } from 'react-bootstrap';
import { Footer, Sigil, BuyControlPanel } from './';
import { Planet, SearchRequest, SearchResult, Ship } from './types/buy';
import ob from 'urbit-ob';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/Buy.css';

interface Props {
	loadingMore: boolean;
	planets: Planet[];
	selectedPlanetPoint: number;
	completedSearch: SearchResult;
	searchRequest: SearchRequest;
	findMoreCandidates:(() => void);
	clickedX:((e: React.MouseEvent<HTMLElement>) => void);
	clickedCard:((e: React.MouseEvent<HTMLElement>) => void);
	clickedBuy:((e: React.MouseEvent<HTMLElement>) => void);
}

class ShipCardDeck extends Component<Props, {}> {

  public render() {
  	const { searchRequest, loadingMore, completedSearch, findMoreCandidates } = this.props;
		return (
			<div style={{ paddingBottom: '15px' }}>
  			{this.buildDecks()}
  			{loadingMore
  				? <Container style={{ marginTop: '25px', marginBottom: '12px' }}>
							<Spinner animation='border' variant='light'/>
						</Container>
  				: !searchRequest.query || completedSearch.pageCount === Number(searchRequest.page)
  					? <Container style={{ marginTop: '15px', marginBottom: '10px' }}>
					  		<Button
					  			onClick={findMoreCandidates}
					  			className='btn-dark btn-lg'
					  			style={{ backgroundColor: '#2E2E2E' }}
					  		>
								  Show more available planets
							  </Button>
						  </Container>
  					: null}
	  	</div>
		);
  }

  private buildDecks = (): JSX.Element[] => {
  	const { planets } = this.props;
  	const cardsPerRow = isMobile && !isTablet ? 2 : 4;
  	const baseClass = `buy-planet-deck${isMobile && !isTablet ? '-mobile' : ''}`;
  	return planets.filter((planet, i) => i % cardsPerRow === 0).map((planet, i) => {
  		const rowLeadIdx = planets.indexOf(planet);
  		const cardCount = rowLeadIdx + cardsPerRow >= planets.length 
  			? planets.length - rowLeadIdx
  			: cardsPerRow;
			const type = rowLeadIdx === 0
				? 'top' 
				: rowLeadIdx + cardsPerRow >= planets.length 
					? 'bottom' + (cardCount < cardsPerRow ? '-straggler' : '') 
					: 'other';
			return (
				<CardDeck
					className={`${baseClass} buy-planet-deck-${type}`}
					key={i}
				>
					{this.buildCards(rowLeadIdx, cardCount)}
				</CardDeck>
			);
		});
  }

  private buildCards = (startingValue: number, count: number): JSX.Element[] => {
  	const { planets, selectedPlanetPoint } = this.props;
  	return planets.filter((planet, i) => i >= startingValue && i < (startingValue + count)).map(planet => {
			const selected = selectedPlanetPoint === Number(planet.point);
			const xButton = selected
				? <Button 
	      		onClick={this.props.clickedX}
	      		variant='dark'
	      		className='x-button'
	      	>
						<i className='fas fa-times-circle'/>
					</Button>
			  : null;
			const infoButton = 
			  <a 
			  	className='buy-planet-card-learn-more-button' 
			  	href={`/${planet.name}`}
			  	role='button' 
			  >
			  	More info
			  	<i 
			  		style={{ marginLeft: '12px' }}
			  		className='fas fa-sign-in-alt'
			  	/>
			  </a>;
			const buyButton = planet.available === undefined
			  ? <div style={{ display: 'inline-block', marginLeft: '35px', verticalAlign: 'middle' }}>
						<Spinner animation='border' variant='light' size='sm'/>
					</div>
				: planet.available
					? <Button 
					  	className='buy-planet-card-buy-button' 
					  	onClick={this.props.clickedBuy}
					  >
					  	Buy
					  	<i 
					  		style={{ marginLeft: '12px' }}
					  		className='fas fa-shopping-cart'
					  	/>
					  </Button>
					: <div style={{ display: 'inline-block', marginLeft: '25px', verticalAlign: 'middle' }}>
							<p style={{ marginBottom: '10px', fontSize: '1.1rem' }}>Not available</p>
						</div>;
			const sponsorName = ob.sein(planet.name);
			const sigilSize = isMobile && !isTablet ? 125 : 160;
			const mobileMod = isMobile && !isTablet ? '-mobile' : '';
			const card = 
				<Card 
		  		key={planet.point} 
		  		onClick={(isMobile && !isTablet) || selected ? undefined : this.props.clickedCard}
		  		id={planet.point.toString()}
		  		className={`buy-planet-card${selected ? '-selected' : ''}${mobileMod}`}
		  		style={{ backgroundColor: '#2E2E2E' }}
		  	>
		  		<Card.Header as='h4' className={selected ? 'buy-planet-card-selected-header' : 'buy-planet-card-header'}>
		  			{xButton}
		  			{planet.name}
		  		</Card.Header>
		  		<div className='buy-planet-card-content-container'>
			  		<div 
			  			className={`buy-planet-card-sigil-container${mobileMod}`}
			  			key={planet.point}
			  		>
						  <Sigil 
						  	patp={planet.name}
						  	size={sigilSize}
						  	margin={20}
						  	color='#000000'
						  />
					  </div>
					  {selected 
					  	? <div className={`buy-planet-card-info-container${mobileMod}`}>
							  	<h4><b>{planet.available == undefined ? ' ' : planet.available ? 'Available' : 'Unavailable'}</b></h4>
							  	<h5>Price: 0.025 ETH</h5>
							  	<h5>
							  		Parent:
							  		<a
							  			className='buy-planet-card-sponsor-link' 
									  	href={`/${sponsorName}`}
							  		>
							  			{sponsorName}
							  		</a>
								  </h5>
								  <h5>AZP: {this.addCommasToPoint(planet.point.toString())}</h5>
							  </div>
							: null}
				  </div>
				  {selected 
				  	? <Card.Footer>
				  			<Row style={{ display: 'inline-block' }}>
					  			{infoButton}
					  			{buyButton}
				  			</Row>
				  		</Card.Footer>
				  	: null}
				</Card>;
			return isMobile && !isTablet
				? <a
						href={`/${planet.name}`}
						key={planet.point}
						className='buy-planet-card-mobile-link'
					>
						{card}
					</a>
				: card;
		});
  }

  private addCommasToPoint = (point: string): string => {
  	return point.split('').reverse().map((char, i) => 
  		i % 3 === 0 && i !== 0 ? char + ',' : char
  	).reverse().join('');
  }

}

export default ShipCardDeck;