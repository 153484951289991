import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Container, Col, Row, Button, Card, CardDeck, ProgressBar, Pagination, Jumbotron, Spinner } from 'react-bootstrap';
import { Footer, Sigil } from './';
import { SearchRequest, SearchResult } from './types/buy';
import { HeaderMetadata } from './types/header';
import ob from 'urbit-ob';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/Explore.css';

interface Props {
	apiBaseURL: string;
	updateHeader:((metadata: HeaderMetadata) => void);
	searchRequest: SearchRequest;
}

interface Planet {
	name: string;
	point: string;
	available?: boolean;
}

interface State {
	planetsLoaded: boolean;
	planets: Planet[];
	percentComplete: number;
	completedSearch: SearchResult;
	loadingMore: boolean;
	portrait: boolean;
	fixFooter: boolean;
}

class Explore extends Component<Props, State> {
	public state = {
		planetsLoaded: false,
		planets: Array<Planet>(),
		percentComplete: 0,
		completedSearch: Object() as SearchResult,
		loadingMore: false,
		portrait: true,
		fixFooter: false
	}

	public componentDidMount = () => {
	  window.addEventListener('resize', this.setOrientation);
		if (!!this.props.searchRequest.query && this.props.searchRequest.query.length > 2) {
			this.props.updateHeader({
				activeLink: 2, 
				searchMode: 1, 
				title: 'Network explorer', 
				scope: 0,
				query: this.props.searchRequest.query
			});
	  	this.searchForPlanets();
		} else {
			this.props.updateHeader({
				activeLink: 2, 
				searchMode: 1, 
				title: 'Network explorer', 
				scope: 0,
				query: ''
			});
		}
	}

	public componentWillUnmount() {
	  window.removeEventListener('resize', this.setOrientation);
	}

  public render() {
  	const { planetsLoaded, completedSearch, planets, portrait, fixFooter } = this.state;
  	const { searchRequest } = this.props;
  	const paddingTop = isMobile && !isTablet 
  		? portrait
  			? '20px' 
  			: '10px'
  		: '30px';
  	const scrollStyle = isMobile || isTablet 
  		? 'touch' 
  		: 'auto';
  	const exploreStyle = { 
  		paddingTop, 
  		WebkitOverflowScrolling: scrollStyle, 
  		overflowScrolling: scrollStyle,
  		backgroundColor: '#212121'
  	} as React.CSSProperties;
  	const pagination = planetsLoaded &&
     !!completedSearch.query &&
     completedSearch.pageCount > 1 
    	? this.generateSearchPagination() 
    	: null;
    return (
    	<div style={exploreStyle}>
    		{!!searchRequest.query &&
    			searchRequest.query.length > 0 &&
        	ob.isValidPatp(`${searchRequest.query.substring(0,1) === '~' ? '' : '~'}${searchRequest.query}`)
	        	? isMobile && !isTablet
	        		? this.generateStarBannerMobile(searchRequest.query, true)
	        		: this.generateStarBannerDesktop(searchRequest.query, true)
	        	: null}
        {planetsLoaded &&
         !!completedSearch.query 
        	? this.generateSearchResultsTitle() 
        	: null}
        {pagination}
        {planetsLoaded && planets.length === 0
        	? this.generateSuggestionPrompt() 
        	: null}
        {planetsLoaded 
        	? this.generateCards() 
        	: !!searchRequest.query && searchRequest.query.length > 2
        		? this.generateLoadingBar() 
        		: this.generateGalaxiesList()}
        {pagination}
        <Footer fixedToBottom={fixFooter}/>
      </div>
    );
  }

  private generateGalaxiesList = (): JSX.Element => {
  	const prefixes = ['doz','mar','bin','wan','sam','lit','sig','hid','fid','lis','sog','dir','wac','sab','wis','sib','rig','sol','dop','mod','fog','lid','hop','dar','dor','lor','hod','fol','rin','tog','sil','mir','hol','pas','lac','rov','liv','dal','sat','lib','tab','han','tic','pid','tor','bol','fos','dot','los','dil','for','pil','ram','tir','win','tad','bic','dif','roc','wid','bis','das','mid','lop','ril','nar','dap','mol','san','loc','nov','sit','nid','tip','sic','rop','wit','nat','pan','min','rit','pod','mot','tam','tol','sav','pos','nap','nop','som','fin','fon','ban','mor','wor','sip','ron','nor','bot','wic','soc','wat','dol','mag','pic','dav','bid','bal','tim','tas','mal','lig','siv','tag','pad','sal','div','dac','tan','sid','fab','tar','mon','ran','nis','wol','mis','pal','las','dis','map','rab','tob','rol','lat','lon','nod','nav','fig','nom','nib','pag','sop','ral','bil','had','doc','rid','moc','pac','rav','rip','fal','tod','til','tin','hap','mic','fan','pat','tac','lab','mog','sim','son','pin','lom','ric','tap','fir','has','bos','bat','poc','hac','tid','hav','sap','lin','dib','hos','dab','bit','bar','rac','par','lod','dos','bor','toc','hil','mac','tom','dig','fil','fas','mit','hob','har','mig','hin','rad','mas','hal','rag','lag','fad','top','mop','hab','nil','nos','mil','fop','fam','dat','nol','din','hat','nac','ris','fot','rib','hoc','nim','lar','fit','wal','rap','sar','nal','mos','lan','don','dan','lad','dov','riv','bac','pol','lap','tal','pit','nam','bon','ros','ton','fod','pon','sov','noc','sor','lav','mat','mip','fip'];
		const showPrefixes = window.location.hash.indexOf('#prefixes') > -1;
  	const galaxyComponentArray = [...Array(Math.pow(2, 8)).keys()].map(i => {
  		const galaxyName = showPrefixes ? prefixes[i] : ob.patp(i.toString());
  		return isMobile && !isTablet
  			? this.generateStarBannerMobile(galaxyName, !showPrefixes, i)
  			: this.generateStarBannerDesktop(galaxyName, !showPrefixes, i)
		});
		return (
			<Container style={{ paddingBottom: '10px' }}>
				{galaxyComponentArray}
			</Container>
		);
  }

  private generateStarBannerDesktop = (query: string, showButton: boolean, idx?: number): JSX.Element => {
  	const starName = `${query.substring(0,1) === '~' ? '' : '~'}${query}`;
  	const clan = showButton ? ob.clan(starName) : 'Prefix';
  	const capClan = clan.charAt(0).toUpperCase() + clan.slice(1);
		return (
			<div className='star-banner' key={starName}>
		  	<Jumbotron style={{ marginBottom: '10px', paddingTop: '25px', paddingBottom: '25px' }}>
		  		<Row>
		  			<Col style={{ marginLeft: '15px', marginRight: '20px' }}>
						  <h1 style={{ fontSize: '3.5rem', marginTop: '10px' }}>{starName}</h1>
						  {showButton
						  	? <h5>Azimuth point {this.addCommasToPoint(ob.patp2dec(starName))}</h5>
						  	: <h5 style={{ marginLeft: '75px', marginRight: '75px' }}>Prefix {idx}</h5>}
						  {showButton
						  	? <a 
								  	className='btn btn-lg btn-dark'
								  	href={`/${starName}`} 
								  	role='button' 
								  	style={{ margin: '25px' }}
								  >
							  		More info
							  		<i 
								  		style={{ marginLeft: '12px' }}
								  		className='fas fa-sign-in-alt'
								  	/>
								  </a>
								  : null}
					  </Col>
					  <Col style={{ display: 'inline-flex' }}>
					  	<Sigil
						  	patp={starName} 
						  	size={250}
						  	margin={35}
						  	color='#000000'
						  />
					  </Col>
				  </Row>
				</Jumbotron>
			</div>
		);
  }

  private generateStarBannerMobile = (query: string, showButton: boolean, idx?: number): JSX.Element => {
  	const starName = `${query.substring(0,1) === '~' ? '' : '~'}${query}`;
  	const clan = showButton ? ob.clan(starName) : 'Prefix';
  	const capClan = clan.charAt(0).toUpperCase() + clan.slice(1);
		return (
			<div className='star-banner-mobile' key={starName}>
		  	<Jumbotron style={{ marginBottom: '10px', paddingBottom: '30px', paddingTop: '30px' }}>
		  		<Row style={{ display: 'inline-flex' }}>
		  			<div style={{ marginRight: '15px', marginLeft: '20px', }}>
						  <h1 style={{ fontSize: '1.8rem' }}>{starName}</h1>
						  {showButton
						  	? <h3 style={{ fontSize: '1.1rem' }}>{`${capClan} | AZP ${this.addCommasToPoint(ob.patp2dec(starName))}`}</h3>
						  	: <h3>Prefix {idx}</h3>}
						  {showButton
						  	? <a 
								  	className='btn btn-lg btn-dark'
								  	href={`/${starName}`} 
								  	role='button' 
								  	style={{ marginTop: '5px', fontSize: '0.9rem', paddingLeft: '15px', paddingRight: '15px' }}
								  >
							  		More info
							  		<i 
								  		style={{ marginLeft: '5px' }}
								  		className='fas fa-sign-in-alt'
								  	/>
								  </a>
								  : null}
					  </div>
					  <div style={{ paddingTop: '10px', marginLeft: '15px', marginRight: '20px' }}>
					  	<Sigil
						  	patp={starName} 
						  	size={100} 
						  	color='#000000'
						  />
					  </div>
				  </Row>
				</Jumbotron>
			</div>
		);
  }

  private generateSearchResultsTitle = (): JSX.Element => {
  	const { completedSearch, planets } = this.state;
  	const { searchRequest } = this.props;
  	const searchResultsLabel = `${this.addCommasToPoint(completedSearch.resultsCount.toString())} result${completedSearch.resultsCount !== 1 ? 's' : ''} for \'${completedSearch.query}\' found in ${completedSearch.scope !== 'spawning' ? ob.patp(JSON.parse(completedSearch.scope)[0]) : 'spawning stars'}`;
  	const firstResultNumber = (Number(searchRequest.page) - 1) * 100 + 1;
  	const lastResultNumber = firstResultNumber + planets.length - 1;
  	const displayingLabel = 'Displaying results ' + firstResultNumber + ' to ' + lastResultNumber;
		return (
			<div>
				{planets.length > 0 && completedSearch.resultsCount === 0 
					? null
					: <p style={{ color: 'white', marginTop: '20px', marginBottom: '5px', fontSize: '1.3rem' }}>
							{searchResultsLabel}
						</p>}
				{completedSearch.pageCount > 1 
					? <p style={{ color: 'white', marginTop: '5px', marginBottom: '5px', fontSize: '1.1rem' }}>
							{displayingLabel}
						</p> 
					: null}
			</div>
		);
  }

  private generateSuggestionPrompt = (): JSX.Element => {
  	const { completedSearch } = this.state;
  	const buttonStyle = isMobile && !isTablet
  		? 'search-suggestion-button-mobile btn btn-lg btn-dark'
  		: 'search-suggestion-button btn btn-lg btn-dark';
  	const suggestionRows = [
	  	['faster', 'walled', 'differ', 'dotnet'],
	  	['harbur', 'firnus', 'watter', 'pilled'],
	  	['docter', 'master', 'molten', 'botnet'],
	  	['bitter', 'poster', 'listen', 'massed'],
	  	['winner', 'donnut', 'doller', 'magnet'],
	  	['winter', 'middel', 'midnyt', 'wallet']
  	];
		return (
			<Container style={{ marginBottom: '25px' }}>
				<h5 style={{ color: 'white', marginTop: '10px' }}>
					Want some suggestions? These are popular search phrases:
				</h5>
				{suggestionRows.map(row => 
					<div className='justify-content-md-center'>
						{row.map((suggestion, idx) => 
							idx === 3 && isMobile && !isTablet
								? null
								: <a 
										className={buttonStyle} 
										href={`/explore?scope=${completedSearch.scope}&query=${suggestion}`} 
										role='button'
									>
										{suggestion}
									</a>
						)}
					</div>
				)}
			</Container>
		);
  }

  private generateSearchPagination = (): JSX.Element => {
  	const { completedSearch } = this.state;
  	const maxPaginationButtons = isMobile && !isTablet ? 4 : 8;
  	const paginationButtonCount = completedSearch.pageCount > maxPaginationButtons 
  		? maxPaginationButtons 
  		: completedSearch.pageCount - 1;
  	const currentPageNumber = Number(this.props.searchRequest.page);
  	const startingValue = completedSearch.pageCount < 11 || currentPageNumber < 5
  		? 1
  		: completedSearch.pageCount - currentPageNumber < 5
  			? completedSearch.pageCount - 8
  			: currentPageNumber - (maxPaginationButtons / 2);
		const maxPageNumberDisplayed = startingValue + paginationButtonCount;
		const items = [...Array(paginationButtonCount + 1).keys()].map(idx => {
			const pageNumber = startingValue + idx;
			return (
				<Pagination.Item 
		    	key={pageNumber} 
		    	active={pageNumber === currentPageNumber}
		    	href={`/explore?scope=${completedSearch.scope}&query=${completedSearch.query}&page=${pageNumber}`}
				>
		      {pageNumber}
		    </Pagination.Item>
			);
		});
		if (currentPageNumber > 1 && paginationButtonCount > 3 && startingValue !== 1) {
			items.splice(0, 0,
				<Pagination.First 
					key={-2}
					href={`/explore?scope=${completedSearch.scope}&query=${completedSearch.query}&page=1`}
				/>,
				<Pagination.Prev 
					key={-1}
					href={`/explore?scope=${completedSearch.scope}&query=${completedSearch.query}&page=${currentPageNumber - 1}`}
				/>
			);
		}
		if (currentPageNumber !== completedSearch.pageCount && paginationButtonCount > 3 && maxPageNumberDisplayed !== completedSearch.pageCount) {
			items.push(
				<Pagination.Next 
					key={maxPageNumberDisplayed + 1}
					href={`/explore?scope=${completedSearch.scope}&query=${completedSearch.query}&page=${currentPageNumber + 1}`}
				/>,
				<Pagination.Last 
					key={maxPageNumberDisplayed + 2}
					href={`/explore?scope=${completedSearch.scope}&query=${completedSearch.query}&page=${completedSearch.pageCount}`}
				/>
			);
		}
  	return (
  		<Container>
  			<Row style={{ marginTop: '15px', display: 'inline-block' }}>
		  		<Pagination>
					  {items}
					</Pagination>
				</Row>
		  </Container>
  	)
  }

  private generateCards = (): JSX.Element => {
  	const { planets, loadingMore } = this.state;
		return (
			<Container style={{ marginTop: '20px', paddingBottom: '20px' }}>
  			{this.buildDecks()}
  			{loadingMore
  				? <Container>
							<Spinner animation='border' variant='light'/>
						</Container>
  				: null}
	  	</Container>
		);
  }

  private buildDecks = (): JSX.Element[] => {
  	const { planets } = this.state;
  	const cardsPerRow = isMobile && !isTablet ? 2 : 4;
  	const baseClass = `planet-deck${isMobile && !isTablet ? '-mobile' : ''}`;
  	return planets.filter((planet, i) => i % cardsPerRow === 0).map((planet, i) => {
  		const rowLeadIdx = planets.indexOf(planet);
  		const cardCount = rowLeadIdx + cardsPerRow >= planets.length 
  			? planets.length - rowLeadIdx
  			: cardsPerRow;
			const deckClass = rowLeadIdx === 0 
				? 'top' 
				: rowLeadIdx + cardsPerRow >= planets.length 
					? 'bottom' 
					: 'other';
			return (
				<CardDeck 
					className={`${baseClass} planet-deck-${deckClass}`} 
					key={i} 
				>
					{this.buildCards(rowLeadIdx, cardCount)}
				</CardDeck>
			);
		});
  }

  private buildCards = (startingValue: number, count: number): JSX.Element[] => {
  	const { planets } = this.state;
  	return planets.filter((planet, i) => i >= startingValue && i < (startingValue + count)).map(planet => {
			const sigilSize = isMobile && !isTablet ? 125 : 160;
			const cardClass = isMobile && !isTablet ? 'planet-card-mobile' : 'planet-card';
			return (
				<a 
					href={`/${planet.name}`}
					className='planet-card-link'
					key={planet.point} 
				>
			  	<Card 
			  		id={planet.point}
			  		className={cardClass}
			  		style={{ backgroundColor: '#2E2E2E' }}
			  	>
			  		<Card.Header as='h6' className='planet-card-header'>
			  			{planet.name}
			  		</Card.Header>
			  		<div 
			  			className='planet-card-sigil-container'
			  			key={planet.point}
			  		>
						  <Sigil
						  	patp={planet.name} 
						  	size={sigilSize}
						  	color='#000000'
						  	margin={20}
						  />
					  </div>
					</Card>
				</a>
			);
		});
  }

  private generateLoadingBar = (): JSX.Element => {
  	const { percentComplete } = this.state;
  	const { searchRequest } = this.props;
  	const label = !!searchRequest.query && searchRequest.query.length > 0 
  		? `Finding planets with \'${searchRequest.query}\' ${searchRequest.scope !== 'spawning' ? (' in ' + ob.patp(JSON.parse(searchRequest.scope)[0])) : ''}` 
  		: 'Finding available planets';
  	return (
  		<Container style={{ marginTop: '120px' }}>
	  		<div className='loading-bar'>
		  		<ProgressBar 
		  			animated 
		  			now={percentComplete} 
		  			label={label} 
		  		/> 
	  		</div>
  		</Container>
		);
  }

  private addCommasToPoint = (point: string): string => {
  	return point.split('').reverse().map((char, i) => 
  		i % 3 === 0 && i !== 0 ? char + ',' : char
  	).reverse().join('');
  }

  private searchForPlanets = () => {
  	const { searchRequest } = this.props;
  	this.setOrientation();
  	this.setState({ 
  		percentComplete: 0,
    	planetsLoaded: false
    }, () => {
    	this.incrementLoadingBar(searchRequest.scope === 'spawning' 
    		? 1000 
    		: !!searchRequest.query && searchRequest.query.length > 3 
    			? 6000 
    			: 8000);
    	fetch(`${this.props.apiBaseURL}/search?scope=${searchRequest.scope}&query=${searchRequest.query}&page=${searchRequest.page}`).then(res => res.json())
	      .then((searchResults: { results: Planet[], resultsCount: number, pageCount: number }) => {
	      	this.setState({ 
		      	planetsLoaded: true,
		      	planets: searchResults.results,
		      	completedSearch: { 
		      		scope: searchRequest.scope,
		      		query: searchRequest.query,
		      		resultsCount: searchResults.resultsCount,
		      		pageCount: searchResults.pageCount
		      	}
		      }, () => this.setOrientation());
	      }).catch((error: Error) => console.log(error));
    });
  }

  private incrementLoadingBar = (estimatedCompletionTime: number) => {
  	const { percentComplete } = this.state;
  	const timeout = 250;
  	const increment = 100 / (estimatedCompletionTime / timeout);
  	this.setState({ percentComplete: percentComplete + increment }, () => {
  		if (percentComplete < 100) {
  			setTimeout(this.incrementLoadingBar, timeout, estimatedCompletionTime);
  		}
  	})
  }

  private setOrientation = () => {
  	const { planets, planetsLoaded } = this.state;
  	const portrait = (window.innerWidth / window.innerHeight) < 1;
  	const fixFooter = isMobile && !isTablet
  		? !!this.props.searchRequest.query && this.props.searchRequest.query.length > 2
  			? planets.length === 0
  			: false
  		: !planetsLoaded && planets.length === 0
  			? true
  			: window.innerHeight >= document.body.scrollHeight;
	  this.setState({ portrait, fixFooter });
	}

}

export default Explore;