import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Container, Row, Button } from 'react-bootstrap';
import { XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Line, ReferenceLine, ResponsiveContainer, AxisDomain } from 'recharts';
import { SpawnedShips } from './types/stats';
import 'bootstrap/dist/css/bootstrap.css';

interface Props {
	isPortrait: boolean;
	isStarSpawns: boolean;
	shipSpawns: SpawnedShips[]
	selectedScale: number;
}

class ShipSpawnsChart extends Component<Props, {}> {

  public render() {
  	return (
  		<Row 
				className='justify-content-md-center'
				style={{ marginLeft: 'auto', marginRight: 'auto', display: 'inline-block' }}
			>
				{this.generateSpawnedChart()}
			</Row>
		);
  }

  private generateSpawnedChart = (): JSX.Element => {
		const { isPortrait, isStarSpawns, shipSpawns, selectedScale } = this.props;
		const chartMargin = isMobile && !isTablet
			? { top: 20, right: 10, bottom: 5, left: 10 }
			: { top: 5, right: 20, bottom: 15, left: 10 };
		const yAxisWidth = isMobile && !isTablet ? 50 : 100;
		const height = isMobile && !isTablet 
			? isPortrait
				? 500 
				: 300
			: 450;
		const aspect = isMobile && !isTablet 
			? isPortrait
				? 0.7 
				: 2.25
			: 2;
		const domain = (!isStarSpawns || selectedScale === 0)
			? ['dataMin - 1000', 'dataMax + 2500']
			: ['auto', 'auto'];
  	return (
	  	<Container style={{ paddingLeft: '5px', paddingRight: '5px' }}>
	  		<ResponsiveContainer 
	  			height={height}
	  			aspect={aspect}
	  		>
	        <LineChart
		        data={shipSpawns}
		        margin={chartMargin}
		      >
		        <CartesianGrid 
				    	strokeDasharray='3 3'
				    	stroke='rgb(102, 102, 102)'
				    />
		        <XAxis dataKey='date'/>
		        <YAxis 
		        	type='number' 
		        	dataKey='count'
		        	tickFormatter={tick => this.addCommasToPoint(tick.toString())}
		        	scale={selectedScale === 0 ? 'auto' : 'log'}
		        	domain={domain as [AxisDomain, AxisDomain]}
		        />
		        <Tooltip 
				    	cursor={{ strokeDasharray: '3 3' }}
				    	content={(props: any) => this.SpawnTooltip(props)}
				    />
		        <Line 
		        	type='monotone' 
		        	dataKey='count' 
		        	stroke='#FFFFFF'
		        	dot={false}
		        />
		        {isStarSpawns
		        	? <ReferenceLine 
				        	y={65280} 
				        	stroke='#00C49F' 
				        	strokeDasharray='3 3'
				        	label={props => this.MaxStarCountLabel(props)}
				        />
				      : null}
		      </LineChart>
	      </ResponsiveContainer>
			</Container>
		);
	}

	private MaxStarCountLabel = (props: any): JSX.Element => {
		return (
			<text 
				x={((props.viewBox.x + props.viewBox.width) / 2) - 50} 
				y={props.viewBox.y - 4} 
				fontSize='1.1rem' 
				fill='#FFFFFF'
			>
				Max star count 65,280
			</text>
		);
	}

	private SpawnTooltip = (props: any): JSX.Element => {
		const { payload, label } = props;
		if (!props.active) {
			return null as any;
		} else {
			const shipClass = this.props.isStarSpawns ? 'stars' : 'planets';
			const formattedCount = this.addCommasToPoint(payload[0].value.toString());
			return (
				<div className='spawn-tooltip'>
	  			<div style={{ display: 'flex' }}>
		        <p className='spawn-tooltip-date'>
		        	{label}
		        </p>
		        <p className='spawn-tooltip-count'>
		        	{`${formattedCount} ${shipClass}`}
		        </p>
	        </div>
	      </div>
      );
		}
	}

  private addCommasToPoint = (point: string): string => {
  	return point.split('').reverse().map((char, i) => 
  		i % 3 === 0 && i !== 0 ? char + ',' : char
  	).reverse().join('');
  }

}

export default ShipSpawnsChart;