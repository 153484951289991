import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Container, Row, Spinner, Table, Button } from 'react-bootstrap';
import { StatsControlPanel, ShipSalesChart, ShipSalesTable, StarDistroChart, ShipSpawnsChart, Footer } from './';
import { ShipSale, SalesStats, SpawnedShips, StarDistro } from './types/stats';
import { HeaderMetadata } from './types/header';
import ob from 'urbit-ob';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/Stats.css';

interface Props {
	apiBaseURL: string;
	updateHeader:((metadata: HeaderMetadata) => void);
}

interface ActiveStars {
	date: string;
	count: string;
}

interface State {
	portrait: boolean;
	fixFooter: boolean;
	planetSalesStats: SalesStats;
	planetSalesLoaded: boolean;
	starSalesStats: SalesStats;
	starSalesLoaded: boolean;
	planetSpawns: SpawnedShips[];
	planetSpawnsLoaded: boolean;
	starSpawns: SpawnedShips[];
	starSpawnsLoaded: boolean;
	starDistro: StarDistro;
	starDistroLoaded: boolean;
	starSetKeys: ActiveStars[];
	starSetKeysLoaded: boolean;
	selectedScale: number;
	selectedCurrency: number;
	selectedPlanetSale: ShipSale;
	selectedStarSale: ShipSale;
	startDate: Date;
	endDate: Date;
	selectedStarStatusIdx: number;
	priceKey: string;
}

class Stats extends Component<Props, State> {
	public state = {
		portrait: true,
		fixFooter: true,
		planetSalesStats: Object() as SalesStats,
		planetSalesLoaded: false,
		starSalesStats: Object() as SalesStats,
		starSalesLoaded: false,
		planetSpawns: Array<SpawnedShips>(),
		planetSpawnsLoaded: false,
		starSpawns: Array<SpawnedShips>(),
		starSpawnsLoaded: false,
		starDistro: Object() as StarDistro,
		starDistroLoaded: false,
		starSetKeys: Array<ActiveStars>(),
		starSetKeysLoaded: false,
		selectedScale: 0,
		selectedCurrency: 0,
		selectedPlanetSale: Object() as ShipSale,
		selectedStarSale: Object() as ShipSale,
		startDate: new Date(Date.now() - 60 * 24 * 60 * 60 * 1000),
		endDate: new Date(),
		selectedStarStatusIdx: -1,
		priceKey: 'priceUsd'
	}

	public componentDidMount = () => {
		this.setOrientation();
	  window.addEventListener('resize', this.setOrientation);
		this.props.updateHeader({
			activeLink:	4,
			searchMode: 0, 
			title: 'Urbit stats',
			scope: 0
		});
		this.retrieveStats(0);
	}

	public componentWillUnmount = () => {
	  window.removeEventListener('resize', this.setOrientation);
	}

  public render() {
  	const { 
  		fixFooter, 
  		portrait, 
  		planetSalesStats, 
  		starSalesStats,  
  		planetSalesLoaded,
  		starSalesLoaded, 
  		selectedPlanetSale, 
  		selectedStarSale,
  		starDistro,
  		starDistroLoaded, 
  		planetSpawns,
  		planetSpawnsLoaded, 
  		starSpawns,
  		starSpawnsLoaded,
  		starSetKeys,
  		starSetKeysLoaded,
  		selectedScale,
  		selectedCurrency,
  		startDate,
  		endDate,
  		selectedStarStatusIdx,
  		priceKey
  	} = this.state;
  	const scrollStyle = isMobile || isTablet ? 'touch' : 'auto';
  	const paddingTop = isMobile && !isTablet 
  		? portrait
  			? '20px'
  			: '10px'
  		: '10px';
  	const activityViewStyle = { 
  		WebkitOverflowScrolling: scrollStyle, 
  		overflowScrolling: scrollStyle,
  		backgroundColor: '#212121',
  		paddingTop
  	} as React.CSSProperties;
  	const titleFontSize = isMobile && !isTablet ? '1.7rem' : '2.8rem';
    return (
      <div style={activityViewStyle}>
      	<h1 style={{ color: 'white', fontSize: titleFontSize }}>Urbit sales stats</h1>
      	<StatsControlPanel
	      	selectedScale={selectedScale}
	      	selectedCurrency={selectedCurrency}
	      	startDate={startDate}
	      	endDate={endDate}
      		setStartDate={this.setStartDate}
      		setEndDate={this.setEndDate}
      		clickedScaleButton={this.clickedScaleButton}
      		clickedCurrencyButton={this.clickedCurrencyButton}
      	/>
        {!planetSalesLoaded
      		? this.generateSpinnerForChart('planet sales')
					: <div>
							<h3 style={{ color: 'white' }}>Planet sales</h3>
							{this.generateSnapshotValues(planetSalesStats)}
							<ShipSalesChart
								isPortrait={portrait}
								isStarSales={false}
								clickedShipSale={this.clickedPlanetSale}
								salesStats={planetSalesStats}
								selectedScale={selectedScale}
								selectedCurrency={selectedCurrency}
								priceKey={priceKey}
							/>
							{!!selectedPlanetSale.date 
								? <ShipSalesTable
										selectedCurrency={selectedCurrency}
										salesStats={planetSalesStats}
										selectedShipSale={selectedPlanetSale}
										priceKey={priceKey}
									/>
								: null}
						</div>}
				{!starSalesLoaded
      		? planetSalesLoaded ? this.generateSpinnerForChart('star sales') : null
					: <div style={{ marginTop: '25px' }}>
							<h3 style={{ color: 'white' }}>Star sales</h3>
							{this.generateSnapshotValues(starSalesStats)}
							<ShipSalesChart
								isPortrait={portrait}
								isStarSales={true}
								clickedShipSale={this.clickedStarSale}
								salesStats={starSalesStats}
								selectedScale={selectedScale}
								selectedCurrency={selectedCurrency}
								priceKey={priceKey}
							/>
							{!!selectedStarSale.date 
								? <ShipSalesTable
										selectedCurrency={selectedCurrency}
										salesStats={starSalesStats}
										selectedShipSale={selectedStarSale}
										priceKey={priceKey}
									/>
								: null}
						</div>}
{/*				{!starDistroLoaded
      		? starSalesLoaded ? this.generateSpinnerForChart('star distribution') : null
      		: !!starDistro
						? <div style={{ marginTop: '25px' }}>
								<h3 style={{ color: 'white', marginBottom: '3px' }}>Star distribution</h3>
								<StarDistroChart
									portrait={portrait}
									starDistro={starDistro}
									selectedStarStatusIdx={selectedStarStatusIdx}
									// clickedPieSector={this.clickedPieSector}
								/>
							</div>
						: null}
				{!planetSpawnsLoaded
      		? starDistroLoaded ? this.generateSpinnerForChart('spawned planet') : null
					: <div style={{ marginTop: '25px' }}>
							<h3 style={{ color: 'white' }}>Spawned planets</h3>
							<ShipSpawnsChart
								isPortrait={portrait}
								isStarSpawns={false}
								shipSpawns={planetSpawns}
								selectedScale={selectedScale}
							/>
						</div>}
				{!starSpawnsLoaded
      		? planetSpawnsLoaded ? this.generateSpinnerForChart('spawned star') : null
					: <div style={{ marginTop: '25px', marginBottom: '50px' }}>
							<h3 style={{ color: 'white' }}>Spawned stars</h3>
							<ShipSpawnsChart
								isPortrait={portrait}
								isStarSpawns={true}
								shipSpawns={starSpawns}
								selectedScale={selectedScale}
							/>
						</div>}*/}
        <Footer fixedToBottom={fixFooter}/>
      </div>
    );
  }

	private generateSpinnerForChart = (label: string): JSX.Element => {
  	return (
  		<Container style={{ marginTop: '60px' }}>
  			<p style={{ color: 'white', marginRight: '10px', display: 'inline-block' }}>
  				{`Retrieving ${label} data`}
  			</p>
				<Spinner animation='border' variant='light' size='sm'/>
			</Container>
  	);
  }

	private generateSnapshotValues = (salesStats: SalesStats) => {
		const { selectedCurrency } = this.state;
		const volumeLabel = isMobile && !isTablet ? 'Vol' : 'Volume';
		const countLabel = isMobile && !isTablet ? 'Count' : 'Transaction count';
		const avgLabel = isMobile && !isTablet ? 'Avg' : 'Average price';
		const volumeKey = selectedCurrency === 0 ? 'volumeUsd' : selectedCurrency === 1 ? 'volumeBtc' : 'volumeEth';
		const unit = selectedCurrency === 0 ? 'USD' : selectedCurrency === 1 ? 'BTC' : 'ETH';
		const decimalPlaces = selectedCurrency === 0 || selectedCurrency === 2 ? 2 : 5;
		const volumeValue = salesStats[volumeKey].toFixed(decimalPlaces);
		const volumeValueDisplay = `${selectedCurrency === 0 ? '$' : ''}${selectedCurrency === 0 ? this.addCommasToDollarValue(volumeValue) : volumeValue} ${unit}`;
		const avgValue = (salesStats[volumeKey] / salesStats.count).toFixed(decimalPlaces);
		const avgValueDisplay = `${selectedCurrency === 0 ? '$' : ''}${selectedCurrency === 0 ? this.addCommasToDollarValue(avgValue) : avgValue} ${unit}`; 
		const classNameSuffix = isMobile && !isTablet ? '-mobile' : '';
		return (
			<Row 
				className='justify-content-md-center'
				style={{ marginLeft: 'auto', marginRight: 'auto' }}
			>
		  	<Container style={{ display: 'contents' }}>
		  		<div className={`stats-snapshot-container${classNameSuffix}`}>
		        <p className={`stats-snapshot-volume${classNameSuffix}`}>
		        	{`${volumeLabel}: ${volumeValueDisplay}`}
		        </p>
		        {isMobile && !isTablet 
		        	? null
		        	: <p className={`stats-snapshot-count${classNameSuffix}`}>
				        	{`${countLabel}: ${salesStats.count}`}
				        </p>}
		        <p className={`stats-snapshot-average${classNameSuffix}`}>
		        	{`${avgLabel}: ${avgValueDisplay}`}
		        </p>
	        </div>
	  		</Container>
	  	</Row>
		);
	}

  private clickedPlanetSale = (dot: any) => {
		if (!!dot && !!dot.points && dot.points.length > 0) {
			const selectedPlanetSale = dot.date === this.state.selectedPlanetSale.date && dot.priceEth === this.state.selectedPlanetSale.priceEth
				? Object() as ShipSale
				: {
					date: dot.date,
					priceEth: dot.priceEth,
					priceBtc: dot.priceBtc,
					priceUsd: dot.priceUsd,
					txHashes: dot.txHashes,
					points: dot.points
				} as ShipSale;
	  	this.setState({ selectedPlanetSale }, () => this.setOrientation());
	  }
  }

  private clickedStarSale = (dot: any) => {
		if (!!dot && !!dot.points && dot.points.length > 0) {
			const selectedStarSale = dot.date === this.state.selectedStarSale.date && dot.priceEth === this.state.selectedStarSale.priceEth
				? Object() as ShipSale
				: {
					date: dot.date,
					priceEth: dot.priceEth,
					priceBtc: dot.priceBtc,
					priceUsd: dot.priceUsd,
					txHashes: dot.txHashes,
					points: dot.points
				} as ShipSale;
	  	this.setState({ selectedStarSale }, () => this.setOrientation());
	  }
  }

  private setStartDate = (startDate: Date) => {
  	this.setState({ startDate }, () => this.retrieveStats(0));
  }

  private setEndDate = (endDate: Date) => {
  	this.setState({ endDate }, () => this.retrieveStats(0));
  }

  private clickedScaleButton = (e: React.MouseEvent<HTMLElement>) => {
  	this.setState({ selectedScale: Number(e.currentTarget.id) });
  }

  private clickedCurrencyButton = (e: React.MouseEvent<HTMLElement>) => {
  	const selectedCurrency = Number(e.currentTarget.id);
  	const priceKey = selectedCurrency === 0 ? 'priceUsd' : selectedCurrency === 1 ? 'priceBtc' : 'priceEth';
  	this.setState({ selectedCurrency, priceKey });
  }

  private clickedPieSector = (data: any, index: number) => {
  	const selectedStarStatusIdx = index === this.state.selectedStarStatusIdx ? -1 : index;
  	this.setState({ selectedStarStatusIdx });
  }

  private addCommasToDollarValue = (amount: string): string => {
  	const decimalValue = amount.split('.');
  	return decimalValue[0].split('').reverse().map((char, idx) => 
  		`${char}${idx % 3 === 0 && idx !== 0 ? ',' : ''}`
  	).reverse().join('') + '.' + decimalValue[1];
  }

  private retrieveStats = (chart: number) => {
  	const { startDate, endDate, planetSalesLoaded, starSalesLoaded, planetSpawnsLoaded, starSpawnsLoaded, starSetKeysLoaded } = this.state;
  	const { apiBaseURL } = this.props;
  	const startDateFormatted = `${startDate.getUTCDate()}-${startDate.getUTCMonth() + 1}-${startDate.getUTCFullYear()}`;
  	const endDateFormatted = `${endDate.getUTCDate()}-${endDate.getUTCMonth() + 1}-${endDate.getUTCFullYear()}`;
  	if (chart === 0) {
	  	this.setState({ 
	  		planetSalesLoaded: false,
	  		starSalesLoaded: false,
	  		planetSpawnsLoaded: false,
	  		starSpawnsLoaded: false,
	  		starDistroLoaded: false,
	  		selectedPlanetSale: Object() as ShipSale,
	  		selectedStarSale: Object() as ShipSale,
	  		selectedStarStatusIdx: -1
	  	}, () => {
	  		this.setOrientation();
		  	fetch(`${apiBaseURL}/stats?topic=${chart}&fromDate=${startDateFormatted}&toDate=${endDateFormatted}`).then(res => res.json())
		      .then((salesStats: SalesStats) => {
		      	this.setState({ 
		      		planetSalesStats: salesStats,
		      		planetSalesLoaded: true 
		      	}, () => {
		      		this.setOrientation();
		      		this.retrieveStats(1);
		      	});
			    }).catch((error: Error) => console.log(error));
		  });
	  } else if (chart === 1) {
	  	fetch(`${apiBaseURL}/stats?topic=${chart}&fromDate=${startDateFormatted}&toDate=${endDateFormatted}`).then(res => res.json())
	      .then((salesStats: SalesStats) => {
	      	this.setState({ 
	      		starSalesStats: salesStats,
	      		starSalesLoaded: true 
	      	}, () => {
	      		this.setOrientation();
	      		// this.retrieveStats(4);
	      	});
		    }).catch((error: Error) => console.log(error));
	  } else if (chart === 2) {
	  	fetch(`${apiBaseURL}/stats?topic=${chart}&fromDate=${startDateFormatted}&toDate=${endDateFormatted}`).then(res => res.json())
	      .then((spawnStats: { spawns: SpawnedShips[] }) => {
	      	this.setState({ 
	      		planetSpawns: spawnStats.spawns,
	      		planetSpawnsLoaded: true 
	      	}, () => {
	      		this.setOrientation();
	      		// this.retrieveStats(3);
	      	});
		    }).catch((error: Error) => console.log(error));
	  } else if (chart === 3) {
	  	fetch(`${apiBaseURL}/stats?topic=${chart}&fromDate=${startDateFormatted}&toDate=${endDateFormatted}`).then(res => res.json())
	      .then((spawnStats: { spawns: SpawnedShips[] }) => {
	      	this.setState({ 
	      		starSpawns: spawnStats.spawns,
	      		starSpawnsLoaded: true 
	      	}, () => {
	      		this.setOrientation();
		      	// this.retrieveStats(5);
		      });
		    }).catch((error: Error) => console.log(error));
	  } else if (chart === 4) {
	  	fetch(`${apiBaseURL}/starDistro`).then(res => res.json())
	      .then((starStats: { starDistro: StarDistro }) => {
	      	this.setState({ 
	      		starDistro: starStats.starDistro,
	      		starDistroLoaded: true 
	      	}, () => {
	      		this.setOrientation();
		      	// this.retrieveStats(2);
		      });
		    }).catch((error: Error) => console.log(error));
	  } else if (chart === 5) {
	  	fetch(`${apiBaseURL}/stats?topic=${chart}&fromDate=${startDateFormatted}&toDate=${endDateFormatted}`).then(res => res.json())
	      .then((setKeysStats: { setKeys: ActiveStars[] }) => {
	      	this.setState({ 
	      		starSetKeys: setKeysStats.setKeys,
	      		starSetKeysLoaded: true 
	      	}, () => this.setOrientation());
		    }).catch((error: Error) => console.log(error));
	  }
  }

  private setOrientation = () => {
  	const { planetSalesLoaded, starSalesLoaded } = this.state;
  	const portrait = (window.innerWidth / window.innerHeight) < 1;
		const orientationChange = portrait !== this.state.portrait;
  	const fixFooter = !planetSalesLoaded && !starSalesLoaded
 	  	? true
 	  	: window.innerHeight >= document.body.scrollHeight;
	  this.setState({ portrait, fixFooter });
	}

}

export default Stats;