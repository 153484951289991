import React, { Component } from 'react';
import { isMobile, isTablet, isIOS } from 'react-device-detect';
import { Container, Col, Row, Card, CardDeck, Button, Table, Tabs, Tab, Pagination, Nav, Spinner, Breadcrumb } from 'react-bootstrap';
import { Footer, Sigil } from './';
import { SearchRequest, SearchResult } from './types/buy';
import { HeaderMetadata } from './types/header';
import ob from 'urbit-ob';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/ShipView.css';

interface Props {
	apiBaseURL: string;
	web3Available: boolean;
	updateHeader:((metadata: HeaderMetadata) => void);
	initiateTx:((point: string) => void);
}

interface Ship {
	name: string;
	point: number;
	clan: string;
	parent: string;
	hasSponsor: boolean;
	sponsor: number;
	active: boolean;
	contract?: string;
	escapeRequested: boolean;
	keyRevisionNumber: number;
	continuityNumber: number;
	priceInEther: string;
}

interface SpawnPlanet {
	name: string;
	point: string;
	available?: boolean;
}

interface State {
	validatedInput: string;
	shipIdent: { name: string, point: number };
	retrievedShipData: boolean;
	shipData: Ship;
	dotMode: boolean; // switches between commas and dots for Hoon syntax
	portrait: boolean;
	spawnListLoading: boolean;
	searchRequest: SearchRequest;
	completedSearch: SearchResult;
	spawnList: SpawnPlanet[];
	selectedPlanetPoint: number;
	isAvailable: boolean;
	queryingAvailability: boolean;
	fixFooter: boolean;
	azimuthPointCopied: boolean;
	ownerCopied: boolean;
	shareURLCopied: boolean;
	sigilImgCopied: boolean;
	selectedColor: string;
	selectedTab: number;
}

class ShipView extends Component<Props, State> {
	public state = {
		validatedInput: '',
		shipIdent: { name: '~zod', point: 0 },
		retrievedShipData: false,
		shipData: Object(),
		dotMode: false,
		portrait: true,
		spawnListLoading: false,
		searchRequest: Object() as SearchRequest,
		completedSearch: Object() as SearchResult,
		spawnList: Array<SpawnPlanet>(),
		selectedPlanetPoint: -1,
		isAvailable: false,
		queryingAvailability: false,
		fixFooter: true,
		azimuthPointCopied: false,
		ownerCopied: false,
		shareURLCopied: false,
		sigilImgCopied: false,
		selectedColor: 'black',
		selectedTab: 1
	}

	public componentDidMount = () => {
	  window.addEventListener('resize', this.setOrientation);
		this.handlePassedShipInfo(window.location.pathname.substring(1, window.location.pathname.length));
		setTimeout(this.setOrientation, 500);
	}

	public componentWillUnmount() {
	  window.removeEventListener('resize', this.setOrientation);
	}

  public render() {
  	const { portrait, retrievedShipData, shipData, shipIdent, spawnListLoading, spawnList, fixFooter } = this.state;
  	const shipViewMarginTop = isMobile && !isTablet 
  		? portrait
  			? '10px'
  			: '10px'
  		: '10px';
  	const scrollStyle = isMobile || isTablet 
  		? 'touch' 
  		: 'auto';
  	const shipViewStyle = { 
  		paddingTop: shipViewMarginTop, 
  		WebkitOverflowScrolling: scrollStyle, 
  		overflowScrolling: scrollStyle,
  		backgroundColor: '#212121'
  	} as React.CSSProperties;
  	const showSpawned = (retrievedShipData && !!shipData.spawned && shipData.spawned.length > 0) ||
  		(!spawnListLoading && spawnList.length > 0);
    return (
    	<div style={shipViewStyle}>
        {ob.isValidPatp(shipIdent.name)
        	? this.generateSigilAndShipDataTable()
        	: null}
        {showSpawned
        	? this.generateCards()
        	: null}
        <Footer fixedToBottom={fixFooter}/>
      </div>
    );
  }

  private generateSigilAndShipDataTable = () => {
  	const { shipIdent, retrievedShipData, shipData, azimuthPointCopied, ownerCopied, shareURLCopied, sigilImgCopied } = this.state;
  	const purchaseButtonTitle = retrievedShipData && shipData.available
  		? `Buy ${shipData.name} for ${shipData.priceInEther} ETH`
  		: '';
  	const rowTitleStyle = { verticalAlign: 'middle', borderColor: 'transparent', lineHeight: '1.5' };
  	const isLocked = shipData.owner === '0x86cd9cd0992F04231751E3761De45cEceA5d1801' ||
  		shipData.owner === '0x8C241098C3D3498Fe1261421633FD57986D74AeA';  // Tlon's star release contracts
  	const status = retrievedShipData 
  		? shipData.active 
  			? isLocked
  					? 'Locked'
  					: 'Spawned / Owned'
  			: shipData.available 
  				? 'Unspawned / For sale'
  				: 'Inactive'
  		: null;
  	const lockIcon = <i className='fas fa-lock' style={{ marginLeft: '10px' }}/>;
  	const spinner = <Spinner animation='border' variant='light' size='sm'/>;
  	const statusRow = 
  		<tr>
  			<td style={rowTitleStyle}>Status</td>
		  	<td className='data-row'>
			  	{retrievedShipData
			  		? <div>
			  				<b>{status}</b>
			  				{isLocked
			  					? lockIcon
			  					: null}
			  			</div>
			  		: spinner}
	  		</td>
  		</tr>;
  	const priceRow = retrievedShipData && shipData.available
  		? <tr>
  				<td style={rowTitleStyle}>Price</td>
  				<td className='data-row'>
  					{shipData.priceInEther + ' ETH'}
  				</td>
  			</tr>
  		: null;
  	const purchaseButtonClassname = isMobile && !isTablet
  		? 'purchase-button-mobile btn-lg'
  		: 'purchase-button btn-lg'
  	const purchaseButton = 
  		<Button
  			onClick={this.clickedPurchaseShip}
  			className={purchaseButtonClassname}
  		>
			  {purchaseButtonTitle}
			</Button>;
  	const acquireRow = retrievedShipData && shipData.available
  		? <tr>
  				<td
  					colSpan={2}
  					className='data-row'
  					style={{ textAlign: 'center', paddingLeft: '1px', paddingRight: '1px' }}
  				>
  					{purchaseButton}
					</td>
				</tr>
  		: null;
  	const clanValue = ob.clan(shipIdent.name);
  	const clanDisplay = clanValue.charAt(0).toUpperCase() + clanValue.slice(1);
  	const clanRow = 
  		<tr>
	      <td style={rowTitleStyle}>Type</td>
	      <td className='data-row'>{clanDisplay}</td>
      </tr>;
    const pointWithCommas = this.addCommasToPoint(ob.patp2dec(shipIdent.name));
    const pointRowTitle = isMobile && !isTablet
    	? 'AZP'
    	: 'Azimuth point';
  	const pointRow =
  		<tr>
	      <td style={rowTitleStyle}>{pointRowTitle}</td>
	      <td className='data-row point-row'>
    			{azimuthPointCopied
    				? <text 
    						className='point-copied'
    						style={{ marginRight: '15px', marginLeft: '15px' }}
    					>
    						<i className='fas fa-check'></i>
    					</text>
    				: <a onClick={this.clickedPointRow}>
				      	{pointWithCommas}
			      	</a>}
	      	<button 
			  		onClick={this.copyAzimuthPointTapped}
			  		className='point-copy-button'
			  	>
			  		<i className='far fa-copy'></i>
		  		</button>
	      </td>
	    </tr>;
	  const parentName = retrievedShipData
	  	? shipData.available || !shipData.hasSponsor
	  		? shipData.parent
	  		: ob.patp(shipData.sponsor.toString())
	  	: '';
   	const spawnRow = retrievedShipData
   		? clanValue !== 'galaxy' && !!shipData.parent
	  		?	<tr>
	  				<td style={rowTitleStyle}>Parent</td>
	  				<td className='data-row'>
	  					<a 
	  						href={`/${parentName}`}
	  						className='data-row-link'
	  					>
		  					{parentName}
							</a>
						</td>
					</tr>
	  		: null
  		: <tr>
	  			<td style={rowTitleStyle}>Owner</td>
	  			<td className='data-row keyrev-row'>{spinner}</td>
	  		</tr>;
  	const keyRevRow = retrievedShipData 
	  	? shipData.keyRevisionNumber > 0
	  			?	<tr>
	  					<td style={rowTitleStyle}>Key revision</td>
	  					<td className='data-row keyrev-row'>
  							{shipData.keyRevisionNumber}
	  					</td>
	  				</tr>
	  			: null
	  	: <tr>
	  			<td style={rowTitleStyle}>Key revision</td>
	  			<td className='data-row keyrev-row'>{spinner}</td>
	  		</tr>;
	  const continuityRow = retrievedShipData 
	  	? shipData.keyRevisionNumber > 0
	  			?	<tr>
	  					<td style={rowTitleStyle}>Continuity</td>
	  					<td className='data-row keyrev-row'>
  							{shipData.continuityNumber}
	  					</td>
	  				</tr>
	  			: null
	  	: <tr>
	  			<td style={rowTitleStyle}>Continuity</td>
	  			<td className='data-row keyrev-row'>{spinner}</td>
	  		</tr>;
  	const ownerRow = retrievedShipData 
	  	? !!shipData.owner
  			?	<tr>
  					<td style={rowTitleStyle}>Owner</td>
  					<td className='data-row owner-row'>
  						{ownerCopied
		    				? <text 
		    						className='point-copied'
		    						style={{ marginRight: '65px', marginLeft: '65px' }}
		    					>
		    						<i className='fas fa-check'/>
		    					</text>
		    				: <a 
		  							href={`/address/${shipData.owner}`}
		  							className='data-row-link owner-row'
		  						>
		  							{`${shipData.owner.substring(0,7)}...${shipData.owner.substring(shipData.owner.length - 5)}`}
		  						</a>}
			      	<button 
					  		onClick={this.copyOwnerTapped}
					  		className='point-copy-button'
					  	>
					  		<i className='far fa-copy'/>
				  		</button>
  					</td>
  				</tr>
  			: null
	  	: <tr>
	  			<td style={rowTitleStyle}>Owner</td>
	  			<td className='data-row keyrev-row'>{spinner}</td>
	  		</tr>;
	  const galaxyName = retrievedShipData
	  	? ob.sein(parentName)
	  	: '';
	  const galaxyRow = retrievedShipData
	  	? clanValue === 'planet'
		  	?	<tr>
	  				<td style={rowTitleStyle}>Galaxy</td>
	  				<td className='data-row'>
	  					<a 
	  						href={`/${ob.sein(galaxyName)}`}
	  						className='data-row-link'
	  					>
		  					{galaxyName}
							</a>
						</td>
					</tr>
	  		: null
	  	: <tr>
	  			<td style={rowTitleStyle}>Owner</td>
	  			<td className='data-row keyrev-row'>{spinner}</td>
	  		</tr>;
	  const breadcrumbs = retrievedShipData
	  	? this.generateBreadcrumbs(clanValue, parentName, galaxyName)
	  	: null;
  	const displayStyle = isMobile && !isTablet 
  		? 'inline-block'
  		: 'inline-flex';
  	const marginBottom = isMobile && !isTablet 
  		? { marginBottom: '45px' }
  		: { marginBottom: '55px' };
  	return (
  		<Container>
	  		<Row className='justify-content-md-center'>
		  		<Container style={{ marginTop: '5px' }}>
						{breadcrumbs}
		  			<h1 style={{ color: 'white', fontWeight: 'bold', marginTop: '25px', marginBottom: '15px', fontSize: '4rem' }}>
		  				{this.state.shipIdent.name}
		  			</h1>
		  		</Container>
		  		<Container style={{ display: displayStyle }}>
		  			{this.generateSigilAndCopyButtons()}
		  			<div style={{ marginLeft: '4px', marginRight: 'auto' }}>
							<Table striped hover variant='dark'>
							  <tbody style={{ textAlign: 'left', backgroundColor: '#2E2E2E' }}>
							  	{acquireRow}
								  {statusRow}
							  	{priceRow}
							  	{ownerRow}
							  	{pointRow}
							    {clanRow}
						      {spawnRow}
							    {galaxyRow}
							    {keyRevRow}
							    {continuityRow}
							  </tbody>
							</Table>
						</div>
					</Container>
				</Row>
			</Container>
		);
  }

  private generateBreadcrumbs = (clan: string, parentName: string, galaxyName: string): JSX.Element => {
  	return (
  		<React.Fragment>
	  		{clan !== 'galaxy'
	  			? <Breadcrumb>
		    			{clan === 'planet'
						  	? <Breadcrumb.Item 
								  	href={`/${ob.sein(parentName)}`}
								  	style={{ color: 'white', fontSize: '1.2rem' }}
								  	className='ml-auto'
								  >
								  	{galaxyName}
								  </Breadcrumb.Item>
						  	: null}
						  <Breadcrumb.Item 
						  	href={`/${parentName}`}
						  	style={{ color: 'white', fontSize: '1.2rem' }}
						  	className={clan !== 'planet' ? 'ml-auto' : ''}
						  >
						  	{parentName}
						  </Breadcrumb.Item>
						  <Breadcrumb.Item 
						  	style={{ color: 'white', fontSize: '1.2rem', pointerEvents: 'none', cursor: 'not-allowed' }}
						  	className='mr-auto'
						  >
						  	{this.state.shipIdent.name}
						  </Breadcrumb.Item>
						</Breadcrumb>
					: null}
			</React.Fragment>
  	);
  }

  private generateSigilAndCopyButtons = (): JSX.Element => {
  	const { shipIdent, sigilImgCopied, shareURLCopied, selectedColor } = this.state;
  	const size = isMobile && !isTablet ? 252 : 240;
		const colors = [{
			name: 'black',
			code: '#000000'
		}, { 
  		name: 'blue', 
			code: '#4330FC' 
		}, {
			name: 'red',
			code: '#DC3545'
		}, {
			name: 'green',
			code: '#28A745'
		}, {
			name: 'yellow',
			code: '#FFC107'
		}, {
			name: 'orange',
			code: '#FC5000'
		}, {
			name: 'darkgreen',
			code: '#00482F'
		}];
		const sigilColor = colors.find(color => color.name === selectedColor);
  	return (
			<div 
				id='ship-sigil'
				className='ship-sigil'
			>
  			<Sigil 
  				key={Number(ob.patp2dec(shipIdent.name))}
  				patp={shipIdent.name} 
  				size={size}
  				color={!!sigilColor ? sigilColor.code : colors[0].code}
  				display='block'
  				margin={35}
  			/>
  			{this.generateColorSelector(size, colors)}
  			<div className='buttons-container'>
	  			{isMobile || isTablet
	  				? <Button
				  			onClick={this.copySigilImageURL}
				  			className='copy-sigil-button btn-lg'
				  		>
							  {sigilImgCopied
							  	? <i 
							  			className='fas fa-check'
							  			style={{ marginRight: '30px', marginLeft: '30px' }}
							  		/>
							  	: <div>
							  			<i 
							  				className='far fa-copy'
							  				style={{ marginRight: '10px', fontSize: '1rem' }}
						  				/>
							  			Sigil URL
						  			</div>}
							</Button>
	  				: <a
				  			href={`${this.props.apiBaseURL}/images/${shipIdent.name.substring(1, shipIdent.name.length)}_${selectedColor}.png`}
				  			className='download-sigil-button btn-lg'
				  		>
							  <i 
							  	className='fas fa-file-download'
							  	style={{ marginRight: '10px', fontSize: '1.1rem' }}
							  />
							  Download .png
							</a>}
					<Button
		  			onClick={this.copyShareURL}
		  			className='share-button btn-lg'
		  		>
					  {shareURLCopied
					  	? <i 
					  			className='fas fa-check'
					  			style={{ marginRight: '35px', marginLeft: '35px' }}
					  		/>
					  	: <div>
					  			<i 
					  				className='far fa-copy'
					  				style={{ marginRight: '7px', fontSize: '1rem' }}
				  				/>
					  			Share URL
				  			</div>}
					</Button>
				</div>
			</div>
  	);
  }

  private generateColorSelector = (sigilSize: number, colors: { name: string, code: string }[]): JSX.Element => {
  	const tileSize = Math.round(sigilSize / colors.length).toString() + 'px';
  	return (
  		<div className='color-tile-container'>
	  		{colors.map(color => 
	  			<Button
		  			onClick={this.clickedColorTile}
		  			className='color-tile'
		  			style={{ backgroundColor: color.code, width: tileSize }}
		  			id={color.name}
		  			key={color.name}
		  		/>
	  		)}
  		</div>
  	);
  }

  private generateCards = () => {
  	const { retrievedShipData, shipData, spawnListLoading, completedSearch, spawnList, shipIdent, searchRequest } = this.state;
  	const showSpawned = retrievedShipData && !!shipData.spawned && shipData.spawned.length > 0;
  	const activeTab = showSpawned ? 'spawned' : 'complete';
  	const spawnedShipType = shipData.clan === 'star' ? 'planets' : 'stars';
	  const spawnedTab = showSpawned
	  	? <Nav.Item>
          <Nav.Link
          	eventKey='spawned' 
          	href='#spawned'
          	className='planet-list-tab-link'
          >
          	{`Spawned ${spawnedShipType}`}
          </Nav.Link>
        </Nav.Item>
		  : null;
		const showCompleteList = ob.clan(shipIdent.name) !== 'galaxy' && ob.sein(shipIdent.name) !== 'planet';
		const completeListTab = showCompleteList
		  ?	<Nav.Item>
	        <Nav.Link
	        	eventKey='complete'
	        	href={`#planets/${searchRequest.page}`}
	        	className='planet-list-tab-link'
	        >
	        	{'Complete planet list'}
	        </Nav.Link>
	      </Nav.Item>
	    : null;
	  const spawnedPane = showSpawned
			?	<Tab.Pane 
					eventKey='spawned' 
					id='spawned' 
				>
					<Container style={{ paddingRight: '0', paddingLeft: '0' }}>
						<h3 style={{ color: 'white', marginTop: '25px' }}>
							{`${shipData.spawned.length} ${spawnedShipType} have been spawned from ${shipData.name}`}
						</h3>
		  			{this.buildSpawnedDecks(shipData.spawned)}
			  	</Container>
		  	</Tab.Pane>
		  : null;
	  const planetListTabTitle = `${shipIdent.name}'s planets`;
	  const firstResultNumber = (Number(searchRequest.page) - 1) * 100 + 1;
  	const lastResultNumber = firstResultNumber + spawnList.length - 1;
  	const displayingTitle = !!completedSearch.resultsCount
  		? `Displaying planets ${this.addCommasToPoint(firstResultNumber.toString())} to ${this.addCommasToPoint(lastResultNumber.toString())} of ${this.addCommasToPoint(completedSearch.resultsCount.toString())}`
  		: '';
  	const pagination = !!completedSearch.resultsCount ? this.generatePagination() : null;
		const completePlanetList = 
			<Container>
				<h3 style={{ color: 'white', marginTop: '15px' }}>
					{planetListTabTitle}
				</h3>
				<h5 style={{ color: 'white', marginTop: '5px' }}>
					{displayingTitle}
				</h5>
				{pagination}
  			{this.buildPlanetDecks(spawnList)}
  			{pagination}
	  	</Container>;
    const spinner = 
  		<Container style={{ marginTop: '60px' }}>
				<Spinner animation='border' variant='light'/>
			</Container>;
	  const completeListPane = showCompleteList
	  	? <Tab.Pane 
	  			eventKey='complete' 
	  			id='planets'
	  		>
          {spawnListLoading
						? spinner
						: completePlanetList}
        </Tab.Pane>
      : null;
		const tabsDisplayStyle = isMobile && !isTablet ? 'block' : '';
		const activeKey = this.state.selectedTab === 0 ? 'complete' : 'spawned';
		return (
			<Container style={{ marginTop: '25px', color: 'white', paddingRight: '0', paddingLeft: '0' }}>
				<Tab.Container 
					defaultActiveKey={activeTab}
					activeKey={activeKey} 
					onSelect={(k: string) => this.setState({ selectedTab: k === 'complete' ? 0 : 1 }, () => this.setOrientation())}
				>
					<Nav 
						variant='tabs'
						style={{ display: tabsDisplayStyle }}
					>
						{completeListTab}
		        {spawnedTab}
		      </Nav>
		      <Tab.Content>
		        {spawnedPane}
		        {completeListPane}
		      </Tab.Content>
				</Tab.Container>
			</Container>
		);
  }

  private buildSpawnedDecks = (points: number[]): JSX.Element[] => {
  	const cardsPerRow = isMobile && !isTablet ? 2 : 4;
  	const baseClass = isMobile && !isTablet ? 'spawned-deck-mobile' : 'spawned-deck';
  	return points.filter((point, i) => i % cardsPerRow === 0).map((point, i) => {
  		const rowLeadIdx = points.indexOf(point);
  		const cardCount = rowLeadIdx + cardsPerRow >= points.length 
  			? points.length - rowLeadIdx
  			: cardsPerRow;
			const cards = this.buildSpawnedCards(points, rowLeadIdx, cardCount);
			const deckClass = rowLeadIdx === 0 
				? baseClass + ' spawned-deck-top' 
				: rowLeadIdx + cardsPerRow >= points.length 
					? baseClass + ' spawned-deck-bottom' 
					: baseClass + ' spawned-deck-other';
			const deckMargin = (cardsPerRow - cards.length) * 5;
			const marginValue = deckMargin.toString() + 'px';
			return (
				<CardDeck 
					className={deckClass} 
					key={i} 
					style={{ marginLeft: marginValue, marginRight: marginValue }}
				>
					{cards}
				</CardDeck>
			);
		});
  }

  private buildPlanetDecks = (points: SpawnPlanet[]): JSX.Element[] => {
  	const cardsPerRow = isMobile && !isTablet ? 2 : 4;
  	const baseClass = isMobile && !isTablet ? 'spawned-deck-mobile' : 'spawned-deck';
  	return points.filter((point, i) => i % cardsPerRow === 0).map((point, i) => {
  		const rowLeadIdx = points.indexOf(point);
  		const cardCount = rowLeadIdx + cardsPerRow >= points.length 
  			? points.length - rowLeadIdx
  			: cardsPerRow;
  		const cards = this.buildPlanetCards(rowLeadIdx, cardCount);
			const deckClass = rowLeadIdx === 0 
				? baseClass + ' spawned-deck-top' 
				: rowLeadIdx + cardsPerRow >= points.length 
					? baseClass + ' spawned-deck-bottom' 
					: baseClass + ' spawned-deck-other';
			const deckMargin = (cardsPerRow - cards.length) * 50;
			const marginValue = deckMargin.toString() + 'px';
			return (
				<CardDeck 
					className={deckClass} 
					key={i}
					style={{ marginLeft: marginValue, marginRight: marginValue }}
				>
					{cards}
				</CardDeck>
			);
		});
  }

  private buildSpawnedCards = (points: number[], startingValue: number, count: number): JSX.Element[] => {
  	return points.filter((spawnedShip, i) => i >= startingValue && i < (startingValue + count)).map(spawnedShip => {
			const spawnedName = ob.patp(spawnedShip.toString());
			const spawnedLink = `/${spawnedName}`;
			return (
				<a 
					href={spawnedLink}
					className='spawned-card-link'
					key={spawnedShip}
				>
			  	<Card 
			  		id={spawnedShip.toString()}
			  		className='spawned-card'
			  		style={{ backgroundColor: '#2E2E2E' }}
			  	>
			  		<Card.Header as='h6' className='spawned-card-header'>
			  			{spawnedName}
			  		</Card.Header>
			  		<div 
			  			className='ship-view-card-sigil-container'
			  			key={spawnedShip.toString()}
			  		>
						  <Sigil 
						  	patp={spawnedName}
						  	size={160}
						  	margin={20}
						  	color='#000000'
						  />
					  </div>
					</Card>
				</a>
			)
		});
  }

  private buildPlanetCards = (startingValue: number, count: number): JSX.Element[] => {
  	const { spawnList, selectedPlanetPoint, isAvailable, queryingAvailability } = this.state;
  	return spawnList.filter((spawnedShip, i) => i >= startingValue && i < (startingValue + count)).map(spawnedShip => {
			const selected = selectedPlanetPoint === Number(spawnedShip.point);
			const xButton = selected
				? <Button 
	      		onClick={() => this.setState({ selectedPlanetPoint: -1 })}
	      		variant='dark'
	      		style={{ position: 'absolute', left: '5px', top: '5px', padding: '0', lineHeight: 1 }}
	      	>
						<i className='fas fa-times-circle' style={{ color: 'white', fontSize: 'large' }}/>
					</Button>
				: null;
			const infoButton = 
				<Row style={{ display: 'inline-block' }}>
					<a 
				  	className='planet-info-btn'
				  	href={`/${spawnedShip.name}`}
				  	role='button' 
				  >
				  	More info
				  	<i 
				  		style={{ marginLeft: '12px' }}
				  		className='fas fa-sign-in-alt'
				  	/>
				  </a>
			  </Row>;
			const purchaseButton = selected
				?	<Button 
						onClick={this.clickedPurchaseCard} 
						className='planet-purchase-button btn-dark btn-lg'
					>
				  	{`Buy ${ob.patp(selectedPlanetPoint.toString())}`}
			  	</Button>
			  : null;
			const disabledPurchaseButton = selected
				?	<p style={{ marginBottom: '0px' }}>
				  	{`${ob.patp(selectedPlanetPoint.toString())} is not available`}
			  	</p>
			  : null;
			const spinner = <Spinner animation='border' variant='light'/>;
			return (
		  	<Card 
		  		key={spawnedShip.point} 
		  		onClick={selected ? undefined : this.clickedCard}
		  		id={spawnedShip.point}
		  		className={selected ? 'complete-list-planet-card-selected' : 'complete-list-planet-card'}
		  		style={{ backgroundColor: '#2E2E2E' }}
		  	>
		  		<Card.Header as='h6' className='planet-card-header'>
		  			{xButton}
		  			{spawnedShip.name}
		  		</Card.Header>
				  <div 
		  			className='ship-view-card-sigil-container'
		  			key={spawnedShip.point.toString()}
		  		>
					  <Sigil 
					  	patp={spawnedShip.name}
					  	size={160}
					  	display='inline-block'
					  	margin={20}
					  	color='#000000'
					  />
				  </div>
				  {selected ? infoButton : null}
				  {<Card.Footer className={selected ? 'planet-card-footer' : 'planet-card-footer-info'}>
				  	{selected
				  		? queryingAvailability
				  			? spinner
				  			: isAvailable
				  				? purchaseButton 
				  				: disabledPurchaseButton
							: null}
					  </Card.Footer>}
				</Card>
			);
		});
  }

  private clickedCard = (e: React.MouseEvent<HTMLElement>) => {
  	const { spawnList } = this.state;
  	e.preventDefault();
  	const clickedPlanetPoint = Number(e.currentTarget.id);
  	const selectedPlanetPoint = this.state.selectedPlanetPoint === clickedPlanetPoint ? -1 : clickedPlanetPoint;
  	this.setState({ selectedPlanetPoint }, () => {
  		const planetRecord = spawnList.filter(planet => Number(planet.point) === this.state.selectedPlanetPoint);
  		if (this.state.selectedPlanetPoint !== -1 && 
  			 (planetRecord.length > 0 && !planetRecord[0].available)) {
	  		this.retrieveAvailableStatus(planetRecord[0]);
	  	}
  	});
  }

  private generatePagination = (): JSX.Element => {
  	const { completedSearch, searchRequest } = this.state;
  	const maxPaginationButtons = isMobile && !isTablet ? 4 : 8;
  	const paginationButtonCount = completedSearch.pageCount > maxPaginationButtons 
  		? maxPaginationButtons 
  		: completedSearch.pageCount - 1;
  	const currentPageNumber = Number(searchRequest.page);
  	const startingValue = completedSearch.pageCount < 11 || currentPageNumber < 5
  		? 1
  		: completedSearch.pageCount - currentPageNumber < 5
  			? completedSearch.pageCount - 8
  			: currentPageNumber - (maxPaginationButtons / 2);
		const maxPageNumberDisplayed = startingValue + paginationButtonCount;
		const items = [...Array(paginationButtonCount + 1).keys()].map(idx => {
			const pageNumber = startingValue + idx;
			return (
				<Pagination.Item 
		    	active={pageNumber === currentPageNumber}
		    	id={pageNumber}
		    	key={pageNumber}
		    	href={`#planets/${pageNumber}`}
		    	onClick={this.paginationButtonClicked}
				>
		      {pageNumber}
		    </Pagination.Item>
			);
		});
		if (currentPageNumber > 1 && paginationButtonCount > 3 && startingValue !== 1) {
			items.splice(0, 0,
				<Pagination.First 
					id={1}
					key={-2}
					href={'#planets/1'}
					onClick={this.paginationButtonClicked}
				/>,
				<Pagination.Prev 
					id={currentPageNumber - 1}
					key={-1}
					href={`#planets/${currentPageNumber - 1}`}
					onClick={this.paginationButtonClicked}
				/>
			);
		}
		if (currentPageNumber !== completedSearch.pageCount && paginationButtonCount > 3 && maxPageNumberDisplayed !== completedSearch.pageCount) {
			items.push(
				<Pagination.Next 
					id={currentPageNumber + 1}
					key={maxPageNumberDisplayed + 1}
					href={`#planets/${currentPageNumber + 1}`}
					onClick={this.paginationButtonClicked}
				/>,
				<Pagination.Last 
					id={completedSearch.pageCount}
					key={maxPageNumberDisplayed + 2}
					href={`#planets/${completedSearch.pageCount}`}
					onClick={this.paginationButtonClicked}
				/>
			);
		}
  	return (
  		<Container>
  			<Col md={{ span: 8, offset: 2 }} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
		  		<Pagination style={{ display: 'inline-flex' }}>
					  {items}
					</Pagination>
				</Col>
		  </Container>
  	);
  }

  private paginationButtonClicked = (e: React.MouseEvent<HTMLElement>) => {
  	const searchRequest = {
  		scope: this.state.searchRequest.scope,
  		page: e.currentTarget.id.toString()
  	};
  	this.setState({ searchRequest }, () => this.retrieveSpawnList());
  }

  private handlePassedShipInfo = (shipInfo: string) => {
  	/[^\d]/.test(shipInfo)
  		? this.validateShipName(shipInfo)
			: this.validateShipPoint(shipInfo);
  }

  private validateShipName = (shipName: string) => {
  	const ship = shipName.toLowerCase().match(/[a-z]/g);
  	const passedShip = !!ship 
  		? ship.reduce((a, c, i) => a + (i === 6 ? '-' : '') + c, '~') 
  		: '~zod';
  	const validatedInput = ob.isValidPatp(passedShip) 
  		? passedShip 
  		: '~zod';
		const shipIdent = { name: validatedInput, point: Number(ob.patp2dec(validatedInput)) };
  	const searchRequest = {
  		scope: JSON.stringify([shipIdent.point]),
  		page: this.parseHash(window.location.hash)
  	};
  	this.setState({ 
  		shipIdent,
  		validatedInput,
  		searchRequest
  	}, () => {
  		this.retrieveShipData();
  		this.retrieveSpawnList();
  	});
  }

  private validateShipPoint = (shipPoint: string) => {
  	const shipPointNumber = Number(shipPoint);
  	const maxPlanetPoint = Math.pow(2, 32) - 1;
		const cleanShipPointNumber = shipPointNumber < 0
			? 0
			: shipPointNumber > maxPlanetPoint
				? maxPlanetPoint
				: !Number.isInteger(shipPointNumber)
					? Math.floor(shipPointNumber)
					: shipPointNumber;
  	const shipIdent = { name: ob.patp(shipPoint), point: Number(shipPoint) }
  	const validatedInput = shipIdent.point.toString();
  	const searchRequest = {
  		scope: JSON.stringify([shipIdent.point]),
  		page: this.parseHash(window.location.hash)
  	};
  	this.setState({ 
  		shipIdent,
  		validatedInput,
  		searchRequest
  	}, () => {
  		this.retrieveShipData();
  		this.retrieveSpawnList();
  	});
  }

  private parseHash = (hash: string): string => {
  	if (hash.indexOf('#planets/') > -1 && hash.length > 9) {
  		const pageNumber = Number(hash.substring(9, hash.length));
  		return pageNumber < 0
  			? '1'
  			: pageNumber > 656
  				? '656'
  				: !Number.isInteger(pageNumber)
  					? Math.floor(pageNumber).toString()
  					: pageNumber.toString();
  	}
  	return '1';
  }

  private clickedPointRow = () => {
  	this.setState({ dotMode: !this.state.dotMode });
  }

  private clickedPurchaseShip = (e: React.MouseEvent<HTMLElement>) => {
  	this.props.initiateTx(this.state.shipIdent.point.toString());
  }

  private clickedPurchaseCard = (e: React.MouseEvent<HTMLElement>) => {
  	this.props.initiateTx(this.state.selectedPlanetPoint.toString());
  }

  private clickedColorTile = (e: React.MouseEvent<HTMLElement>) => {
  	this.setState({ selectedColor: e.currentTarget.id })
  }

  private addCommasToPoint = (point: string): string => {
  	return point.split('').reverse().map((char, i) =>
  		i % 3 === 0 && i !== 0
  			? char + (this.state.dotMode ? '.' : ',')
  			: char
  	).reverse().join('');
  }

  private copyOwnerTapped = () => {
  	if (document.queryCommandSupported('copy') && !this.state.ownerCopied) {
	  	this.copyString(this.state.shipData.owner);
	  	this.setState({ ownerCopied: true }, () => setTimeout(() => this.setState({ ownerCopied: false }), 3000));
	  }
  }

  private copyAzimuthPointTapped = () => {
  	if (document.queryCommandSupported('copy') && !this.state.azimuthPointCopied) {
	  	this.copyString(this.state.shipData.point);
	    this.setState({ azimuthPointCopied: true }, () => setTimeout(() => this.setState({ azimuthPointCopied: false }), 3000));
	  }
  }

  private copyShareURL = () => {
  	if (document.queryCommandSupported('copy') && !this.state.ownerCopied) {
	  	this.copyString(`https://urbit.live/${this.state.shipIdent.name}`);
	  	this.setState({ shareURLCopied: true }, () => setTimeout(() => this.setState({ shareURLCopied: false }), 3000));
	  }
  }

  private copySigilImageURL = () => {
  	const { ownerCopied, shipIdent, selectedColor } = this.state;
  	if (document.queryCommandSupported('copy') && !ownerCopied) {
	  	this.copyString(`${this.props.apiBaseURL}/images/${shipIdent.name.substring(1, shipIdent.name.length)}_${selectedColor}.png`);
	  	this.setState({ sigilImgCopied: true }, () => setTimeout(() => this.setState({ sigilImgCopied: false }), 3000));
	  }
  }

  private copyString = (innerText: string) => {
  	const copyEl = document.createElement('textarea');
  	copyEl.innerText = innerText;
  	(document.getElementById('ship-sigil') as HTMLElement).appendChild(copyEl);
  	copyEl.style.position = 'absolute';
  	copyEl.style.height = '10px';
  	copyEl.style.bottom = `${window.innerHeight / 2}`;
  	copyEl.style.left = '0';
  	copyEl.contentEditable = 'true';
  	copyEl.readOnly = true;
  	if (isIOS) {
  		copyEl.focus();
  		copyEl.select();
	  	const range = document.createRange();
      range.selectNodeContents(copyEl);
	  	const selection = window.getSelection();
      (selection as Selection).removeAllRanges();
      (selection as Selection).addRange(range);
      copyEl.setSelectionRange(0, 999999);
    } else {
  		copyEl.select();
  	}
    document.execCommand('copy');
    copyEl.remove();
  }

  private clickedTab = (e: React.MouseEvent<HTMLElement>) => {
  	console.log(e.currentTarget.id);
  	const selectedTab = e.currentTarget.id === 'spawned' ? 0 : 1;
  	this.setState({ selectedTab });
  }

  private retrieveShipData = () => {
  	const { shipIdent } = this.state;
  	this.props.updateHeader({
  		activeLink: 3,
  		searchMode: 0,
  		title: shipIdent.name,
  		scope: 0
  	});
  	if (ob.isValidPatp(shipIdent.name)) {
  		this.setState({ retrievedShipData: false }, () => {
		  	fetch(`${this.props.apiBaseURL}/shipData?ship=${shipIdent.name}`).then(res => res.json())
		      .then((ship: Ship) => {
		      	if (ship.name === shipIdent.name) {
				      this.setState({ 
				      	retrievedShipData: true, 
				      	shipData: ship 
				      }, () => this.setOrientation());
			      }
		      }).catch((error: Error) => console.log(error));
	    });
  	}
  }

  private retrieveSpawnList = () => {
  	const { shipIdent, searchRequest, spawnListLoading } = this.state;
  	if (ob.clan(shipIdent.name) === 'star' && !spawnListLoading) {
  		this.setState({ spawnListLoading: true }, () => {
  			this.setOrientation();
		  	fetch(`${this.props.apiBaseURL}/search?scope=${searchRequest.scope}&page=${searchRequest.page}`).then(res => res.json())
		      .then((searchResults: { results: SpawnPlanet[], resultsCount: number, pageCount: number }) => {
		      	if (Number(searchRequest.page) > 1) {
		      		window.location.hash = `#planets/${searchRequest.page}`;
		      	}
		      	this.setState({ 
			      	spawnListLoading: false,
			      	spawnList: searchResults.results,
			      	completedSearch: { 
			      		scope: searchRequest.scope,
			      		resultsCount: searchResults.resultsCount,
			      		pageCount: searchResults.pageCount
			      	}
			      }, () => this.setOrientation());
		      }).catch((error: Error) => console.log(error));
	    });
  	}
  }

  private retrieveAvailableStatus = (selectedPlanet: SpawnPlanet) => {
  	this.setState({ 
  		isAvailable: false,
  		queryingAvailability: true
  	}, () => {
    	fetch(`${this.props.apiBaseURL}/available?ship=${selectedPlanet.point}`).then(res => res.json())
	      .then((status: { available: boolean }) => {
	      	const planetRecord = selectedPlanet;
	      	planetRecord.available = status.available;
	      	const spawnListCopy = this.state.spawnList;
	      	spawnListCopy.splice(spawnListCopy.indexOf(selectedPlanet), 1, planetRecord);
	      	this.setState({ 
	      		spawnList: spawnListCopy,
	      		isAvailable: status.available,
	      		queryingAvailability: false
	      	});
	      }).catch((error: Error) => console.log(error));
    });
  }

  private setOrientation = () => {
  	const portrait = (window.innerWidth / window.innerHeight) < 1;
  	const fixFooter = this.state.spawnListLoading && this.state.selectedTab === 0
  		? true
  		: window.innerHeight >= document.body.scrollHeight;
	  this.setState({ portrait, fixFooter });
	}
}

export default ShipView;