import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Container, Col, Row, Button, Spinner, Modal, Form, Table, Dropdown, DropdownButton, InputGroup } from 'react-bootstrap';
import { BigNumber } from '@0x/utils';
import { Order } from '@0x/types';
import { SignedOrder, assetDataUtils, generatePseudoRandomSalt, signatureUtils } from '@0x/order-utils';
import { RPCSubprovider, Web3ProviderEngine, MetamaskSubprovider } from '@0x/subproviders';
import { getContractAddressesForChainOrThrow, ContractAddresses } from '@0x/contract-addresses';
import { ERC20TokenContract, ERC721TokenContract, ContractWrappers } from '@0x/contract-wrappers';
import { Web3Wrapper } from '@0x/web3-wrapper';
import { Sigil } from './';
import { patp, patq } from 'urbit-ob';
// import isEmail from 'validator/lib/isEmail';
import Web3 from 'web3';
import 'bootstrap/dist/css/bootstrap.css';

interface Props {
	apiBaseURL: string;
	networkId: number;
	point: number;
	refreshShipData: (() => void);
	activeOrder: boolean;
}

interface UnlistedOrder { 
	signedOrder: SignedOrder;
	point: number;
	networkId: number;
	makerEmailAddress: string;
}

interface FeeData {
	feeZrx: BigNumber;
	feeRecipientAddress: string;
}

interface TypedDataSig {
	from: string;
	data: any;
	sig: string;
}

interface State {
	eclipticAddress: string;
	showBuildOrderModal: boolean;
	showCancelOrderModal: boolean;
	showAdvancedOptions: boolean;
	ownerValidated: boolean;
	fieldsValidated: boolean;
	orderDurationDays: number;
	priceEth: number;
	conversionRate: number;
	makerEmailAddress: string;
	// enabledEthereum: any;
	makerAddress: string;
	buildOrderInitiated: boolean;
	shipTransferApproved: boolean;
	zrxTransferApproved: boolean;
	shipOrderSigned: boolean;
	orderCreated: boolean;
}

class ShipDashboard extends Component<Props, State> {
	public state = {
		eclipticAddress: '0xcF4843a0A355BD0d2B5Ab8977A710ac2BA2A8607',
		showBuildOrderModal: false,
		showCancelOrderModal: false,
		showAdvancedOptions: false,
		ownerValidated: false,
		fieldsValidated: false,
		orderDurationDays: 30,
		priceEth: 0,
		conversionRate: 230.00,  // dollars per ETH
		makerEmailAddress: '',
		// enabledEthereum: Object(),
		makerAddress: '',
		buildOrderInitiated: false,
		shipTransferApproved: false,
		zrxTransferApproved: false,
		shipOrderSigned: false,
		orderCreated: false
	}

  public render() {
  	const { showBuildOrderModal, ownerValidated, showCancelOrderModal } = this.state;
  	const { point, activeOrder } = this.props;
    return (
    	<Container style={{ marginTop: '30px' }}>
    		{ownerValidated
					? null
					: this.generateOwnerLogin()}
				{ownerValidated && !showBuildOrderModal
					? <Row 
		    			className='justify-content-md-center'
		    			style={{ marginBottom: '20px' }}
		    		>
			    		{!!activeOrder 
			    			? this.generateCancelSaleButton()
			    			: this.generateSellButton()}
		    		</Row>
					: null}
				{ownerValidated
					? <Row 
		    			className='justify-content-md-center'
		    			style={{ marginBottom: '20px' }}
		    		>
			    		{showBuildOrderModal
								? this.generateBuildOrderModal()
								: showCancelOrderModal
									? this.generateCancelOrderModal()
									: null}
			    	</Row>
					: null}
				{!showBuildOrderModal
					? <Row 
		    			className='justify-content-md-center'
		    			style={{ marginBottom: '20px' }}
		    		>
			    		{this.generateWithdrawButton()}
		    		</Row>
					: null}
    	</Container>
    );
  }

  private generateOwnerLogin = (): JSX.Element => {
  	const { point } = this.props;
  	const name = patp(point.toString());
  	const rowTitleStyle = { verticalAlign: 'middle', borderColor: 'transparent', lineHeight: '1.5' };
  	const ownerLoginRow = 
			<tr>
				<td style={rowTitleStyle}>{name}</td>
				<td className='data-row'>
					<Button
		  			onClick={this.clickedOwnerLogin}
		  			className='owner-login-button'
		  		>
					  {`Manage`}
					</Button>
				</td>
			</tr>;
  	return (
  		<Table striped hover variant='dark'>
			  <tbody style={{ textAlign: 'left', backgroundColor: '#2E2E2E' }}>
			  	{ownerLoginRow}
			  </tbody>
			</Table>
  	);
  }

  private generateSellButton = (): JSX.Element => {
  	const { point } = this.props;
  	const name = patp(point.toString());
  	return (
  		<Button
				onClick={() => this.setState({ showBuildOrderModal: true })}
				className='btn-lg btn-dark'
				style={{ marginLeft: 'auto', marginRight: 'auto' }}
			>
				{`List ${name} for sale`}
			</Button>
  	);
  }

  private generateCancelSaleButton = (): JSX.Element => {
  	const { point } = this.props;
  	const name = patp(point.toString());
  	return (
  		<Button
				onClick={() => this.setState({ showCancelOrderModal: true })}
				className='btn-lg btn-dark'
				style={{ marginLeft: 'auto', marginRight: 'auto' }}
			>
				Cancel sale order
			</Button>
  	);
  }

  private generateWithdrawButton = (): JSX.Element => {
  	return (
  		<Button
				onClick={() => this.withdrawWETH()}
				className='btn-lg btn-dark'
				style={{ marginLeft: 'auto', marginRight: 'auto' }}
			>
				Withdraw WETH
			</Button>
  	);
  }

  private generateBuildOrderModal = (): JSX.Element => {
  	const { buildOrderInitiated, shipTransferApproved, zrxTransferApproved, orderCreated } = this.state;
  	const { point } = this.props;
  	const shipName = patp(point.toString());
		const modalBody = orderCreated
			? this.generateOrderModalComplete()
			: shipTransferApproved
				? this.generateOrderModalStep3()
				: buildOrderInitiated
					? this.generateOrderModalStep2()
					: this.generateOrderModalStep1(shipName);
  	return (
		  <div>
	    	<Row style={{ marginLeft: '0px' }}>
	    		<h3 style={{ marginRight: '10px' }}>
	      		{`Create sale order for ${shipName}`}
	    		</h3>
	      	<Sigil 
	  				key={point}
	  				patp={shipName} 
	  				size={40}
	  				color='#000000'
	  				margin={5}
	  			/>
				</Row>
        {modalBody}
      </div>
  	);
  }

    // 		// <Modal
  		// // 	show={true} 
  		// // 	onHide={() => this.handleModalsDimissOrder()}
  		// // >
    // //     <Modal.Header 
    // //     	closeButton
    // //     	style={{ paddingBottom: '0px' }}
    // //     >
    // //       <Modal.Title>
    // <div>
    //       	<Row style={{ marginLeft: '0px' }}>
    //       		<p style={{ marginRight: '10px' }}>
	   //        		{`Create listing for ${shipName}`}
    //       		</p>
	   //        	<Sigil 
			 //  				key={point}
			 //  				patp={shipName} 
			 //  				size={40}
			 //  				color='#000000'
			 //  				margin={5}
			 //  			/>
		  // 			</Row>
    //     //   </Modal.Title>
    //     // </Modal.Header>
    //     {modalBody}
    //   // </Modal>
    //   </div>

  private generateCancelOrderModal = (): JSX.Element => {
  	const { buildOrderInitiated, shipTransferApproved } = this.state;
  	const { point } = this.props;
  	const shipName = patp(point.toString());
  	return (
  		<Modal
  			show={true} 
  			onHide={() => this.setState({ showCancelOrderModal: false })}
  		>
        <Modal.Header 
        	closeButton
        	style={{ paddingBottom: '0px' }}
        >
          <Modal.Title>
          	<Row style={{ marginLeft: '0px' }}>
          		<p style={{ marginRight: '10px' }}>
	          		{`Cancel sale order for ${shipName}`}
          		</p>
	          	<Sigil 
			  				key={point}
			  				patp={shipName} 
			  				size={40}
			  				color='#000000'
			  				margin={5}
			  			/>
		  			</Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
	      	<Row style={{ marginLeft: '0px' }}>
	      		<h5>{`Are you sure you want to cancel the sale order for ${shipName}?`}</h5>
	  			</Row>
	  			Explain what happens next
					<Container style={{ marginTop: '10px' }}>
						<Row className='justify-content-md-center'>
							<Button
		          	className='btn-dark'
		          	onClick={() => this.cancelSaleOrderButtonTapped()}
		          >
		            Yes, cancel the sale order
		          </Button>
	          </Row>
	        </Container>
      </Modal.Body>
      </Modal>
  	);
  }

  private generateOrderModalStep1 = (shipName: string): JSX.Element => {
  	const { showAdvancedOptions, fieldsValidated, orderDurationDays, priceEth } = this.state;
  	return (
  		<Modal.Body>
      	<Row style={{ marginLeft: '0px' }}>
      		<h5>Step 1 of 3</h5>
  			</Row>
				<Form>
				  <Form.Group 
				  	as={Row}
				  	style={{ marginLeft: '0px', marginRight: '0px', marginBottom: '25px', marginTop: '25px' }}
				  >
				    <Form.Label 
				    	column lg='2'
				    	style={{ fontSize: '1.5rem', marginLeft: 'auto' }}
				    >
				    	Price
				    </Form.Label>
				    <Col 
				    	lg='8'
				    	style={{ marginRight: 'auto', paddingLeft: '0px', paddingRight: '0px' }}
				    >
					    <InputGroup size='lg'>
					    	<InputGroup.Prepend>
						      <InputGroup.Text>$</InputGroup.Text>
						    </InputGroup.Prepend>
						    <Form.Control 
						    	type='text' 
						    	placeholder='0.00'
						    	spellCheck={false}
						    	autoCorrect='off'
						    	autoCapitalize='off'
						    	autoComplete='off'
						    	style={{ fontSize: '1.5rem', color: 'black', borderColor: '#ced4da', textAlign: 'right' }}
						    	onInput={this.processPriceInput}
						    />
						    <InputGroup.Prepend>
						      <InputGroup.Text 
						      	style={{ borderTopRightRadius: '0.3rem', borderBottomRightRadius: '0.3rem' }}
						      >
						      	USD
						      </InputGroup.Text>
						    </InputGroup.Prepend>
					    </InputGroup>
					  </Col>
				    <Form.Text 
				    	className='text-muted'
				    	style={{ marginLeft: 'auto', marginRight: 'auto' }}
				    >
				      {`You will receive ${priceEth} WETH`}
				    </Form.Text>
				  </Form.Group>
				</Form>
				<Button 
					variant='link'
					size='sm'
					onClick={() => this.setState({ showAdvancedOptions: !showAdvancedOptions })}
					style={{ marginBottom: '5px', marginTop: '25px', fontSize: '0.8rem', paddingLeft: '0px' }}
				>
					{showAdvancedOptions
						? 'Hide advanced settings'
						: 'Advanced settings'}
				</Button>
				{showAdvancedOptions
					? <Form>
						  <Form.Group 
						  	style={{ marginTop: '10px', marginLeft: '0px', marginRight: '0px' }}
						  >
						    <Form.Label 
						    	style={{ paddingLeft: '0px', paddingRight: '0px', fontSize: '0.9rem' }}
						    >
						    	Email address (optional)
						    </Form.Label>
						    <Form.Control 
						    	type='email' 
						    	placeholder='email@example.com'
						    	onInput={this.processEmailAddressInput}
						    />
						    <Form.Text className='text-muted'>
						      {`We'll email you when ${shipName} sells.`}
						    </Form.Text>
						  </Form.Group>
						  <Form.Group 
						  	as={Row}
						  	style={{ marginLeft: '0px', marginTop: '30px' }}
						  >
						    <Form.Label 
						    	column md='5'
						    	style={{ paddingLeft: '0px', paddingRight: '0px', fontSize: '0.9rem' }}
						    >
						    	Sale duration (optional)
						    </Form.Label>
						    <Col 
						    	md='3'
						    	style={{ paddingLeft: '0px', paddingRight: '0px' }}
						    >
							  	<DropdownButton
							      variant='outline-dark'
							      title={`${orderDurationDays} days`}
							      id='dropdown-5'
							    >
							    	{[1, 3, 7, 30, 60].map((duration, idx, ary) => (
							    		<div key={`div-${idx}`}>
								    		<Dropdown.Item 
									      	onClick={(e: React.BaseSyntheticEvent) => this.setState({ orderDurationDays: duration })}
									      	id={idx.toString()}
									      	key={idx}
									      >
									      	{`${orderDurationDays === duration ? '\u2713 ' : ''} ${duration} day${duration !== 1 ? 's' : ''}`}
									      </Dropdown.Item>
									      {idx !== ary.length - 1
									      	? <Dropdown.Divider/>
									      	: null}
								      </div>
						    		))}
							    </DropdownButton>
						    </Col>
						    <Form.Text className='text-muted'>
						      The number of days the sales order will be publicly available. 
						    </Form.Text>
						  </Form.Group>
						  <Form.Group
						  	style={{ marginTop: '30px', fontSize: '0.9rem' }}
						  >
						    <Form.Label>Buyer address (optional)</Form.Label>
						    <Form.Control type='email' placeholder='e.g. 0xa92558A2Ca32e1966E72E18590C3e14344e956C8'/>
						    <Form.Text className='text-muted'>
						      Restricts the sale to a specific buyer. Must be a valid Ethereum address.
						    </Form.Text>
						  </Form.Group>
						</Form>
					: null}
				<Container style={{ marginTop: '10px' }}>
					<Row className='justify-content-md-center'>
						<Button
	          	className='btn-dark'
	          	onClick={() => this.setState({ buildOrderInitiated: true })}
	          	disabled={!fieldsValidated}
	          >
	            Proceed to step 2
	          </Button>
          </Row>
        </Container>
      </Modal.Body>
  	);
  }

  private generateOrderModalStep2 = (): JSX.Element => {
  	return (
  		<Modal.Body>
      	<Row style={{ marginLeft: '0px' }}>
      		<h5>Step 2 of 3</h5>
  			</Row>
  			This step gives Urbit Live permission to transfer your star after the payment is received.
				<Container style={{ marginTop: '10px' }}>
					<Row className='justify-content-md-center'>
						<Button
	          	className='btn-dark'
	          	onClick={() => this.step2ButtonTapped()}
	          >
	            Approve ship transfer
	          </Button>
          </Row>
        </Container>
      </Modal.Body>
  	);
  }

  private generateOrderModalStep3 = (): JSX.Element => {
  	return (
  		<Modal.Body>
      	<Row style={{ marginLeft: '0px' }}>
      		<h5>Step 3 of 3</h5>
  			</Row>
  			This final step prepares the seller's side of the star purchase transaction. When the buyer signs the purchase query, the transaction will be completed instantly. 
				<Container style={{ marginTop: '10px' }}>
					<Row className='justify-content-md-center'>
						<Button
	          	className='btn-dark'
	          	onClick={() => this.step3ButtonTapped()}
	          >
	            Publish my sale order
	          </Button>
          </Row>
        </Container>
      </Modal.Body>
  	);
  }

  private generateOrderModalComplete = (): JSX.Element => {
  	const { orderCreated } = this.state;
  	const { point } = this.props;
  	const stateUpdate = {
  		showBuildOrderModal: false, 
  		buildOrderInitiated: false, 
  		shipTransferApproved: false, 
  		zrxTransferApproved: false
  	};
  	const starName = patp(point.toString());
  	const saleOrderStatusLabel = orderCreated
  		? 'Sale order created'
  		: 'Error creating sale order';
  	const saleOrderExplainedLabel = orderCreated
  		? 'Your star has been successfully listed for sale! It will appear in the star sale view, and can be shared with the following link: '
  		: 'There was a problem creating your sale order. Please try again.';
  	return (
  		<Modal.Body>
      	<Row style={{ marginLeft: '0px' }}>
      		<h5>{saleOrderStatusLabel}</h5>
  			</Row>
  			{saleOrderExplainedLabel}
  			<a 
			  	// className='btn btn-lg btn-dark'
			  	href={`/${starName}`} 
			  	// style={{ margin: '25px' }}
			  >
		  		{starName}
			  </a>
				<Container style={{ marginTop: '10px' }}>
					<Row className='justify-content-md-center'>
						<Button
	          	className='btn-dark'
	          	onClick={() => this.setState(stateUpdate)}
	          >
	            Close
	          </Button>
          </Row>
        </Container>
      </Modal.Body>
  	);
  }

  private step2ButtonTapped = () => {
  	// const { providerEngine } = this.state;
  	const { networkId } = this.props;
  	const ethereum = (window as any).ethereum;
		// providerEngine.addProvider(new MetamaskSubprovider(ethereum));
		// providerEngine.addProvider(new RPCSubprovider('https://kovan.infura.io/v3/92968c6aa05646c8997bd5a609067107'));  // is this needed?
		// providerEngine.start();
		// ethereum.autoRefreshOnNetworkChange = false;
    ethereum.enable().then((addresses: string[]) => 
    	this.setState({ 
    		makerAddress: addresses[0],
    		// enabledEthereum: ethereum,
    		// contractAddresses: getContractAddressesForChainOrThrow(networkId)
    	}, () => this.approveShipTransfer().then((shipTransferApprovalResult: any)  => {

	  		if (!!shipTransferApprovalResult) {
	  			console.log('shipTransferApprovalResult tx hash:', shipTransferApprovalResult.result);
	  		}

	  		this.setState({ shipTransferApproved: true });

			}).catch((error: Error) => this.handleError(error)))
		).catch((error: Error) => this.handleError(error));
  }

  // private step3ButtonTapped = () => {
  // 	this.approveZRXTransfer().then((wethTransferApprovalResult: any) => {

  // 		if (!!wethTransferApprovalResult) {
  // 			console.log('wethTransferApprovalResult tx hash:', wethTransferApprovalResult.result);
  // 		}

  // 		this.setState({ zrxTransferApproved: true });

		// }).catch((error: Error) => this.handleError(error));
  // }

  private step3ButtonTapped = () => {
  	this.signOrder().then(signOrderResult => {
			this.setState({ 
				shipOrderSigned: true,
				orderCreated: signOrderResult.completed 
			});
			this.props.refreshShipData();
		}).catch((error: Error) => this.handleError(error));
  }

  private approveShipTransfer = () => {
  	return new Promise((resolve, reject) => {
	  	const { makerAddress, eclipticAddress } = this.state;
	  	const { point, networkId } = this.props;
	  	const ethereum = (window as any).ethereum;
	  	const providerEngine = new Web3ProviderEngine();
			providerEngine.addProvider(new MetamaskSubprovider(ethereum));
			// providerEngine.addProvider(new RPCSubprovider('https://kovan.infura.io/v3/92968c6aa05646c8997bd5a609067107'));  // is this needed?
			providerEngine.start();
	  	const ecliptic = new ERC721TokenContract(eclipticAddress, providerEngine);
	  	const contractAddresses = getContractAddressesForChainOrThrow(networkId);
	  	ethereum.sendAsync({
	    	method: 'eth_sendTransaction',
			  params: [{
				  from: makerAddress,
				  to: ecliptic.address,
				  data: ecliptic.approve(contractAddresses.erc721Proxy, new BigNumber(point)).getABIEncodedTransactionData()
				}],
			  from: makerAddress
	    }, (error: Error, result: any) => {
	    	providerEngine.stop();
	    	!error ? resolve(result) : reject(error);
	    });
    });
  }

  private signOrder = (): Promise<{ completed: boolean }> => {
  	return new Promise((resolve, reject) => {
  		const { eclipticAddress, makerAddress, priceEth, orderDurationDays, makerEmailAddress } = this.state;
  		const { networkId, point } = this.props;

  		const ethereum = (window as any).ethereum;
	  	const providerEngine = new Web3ProviderEngine();
			providerEngine.addProvider(new MetamaskSubprovider(ethereum));
			providerEngine.addProvider(new RPCSubprovider('https://kovan.infura.io/v3/92968c6aa05646c8997bd5a609067107'));  // is this needed?
			providerEngine.start();
  		const contractAddresses = getContractAddressesForChainOrThrow(networkId);

	  	// this.retrieveFeeData(priceEth).then(feeData => {
		  	const zeroAddress = '0x0000000000000000000000000000000000000000';
		  	const zeroAddressShort = '0x';
		  	const duration = 1000 * 60 * 60 * 24 * orderDurationDays;

		    const order: Order = {
		      exchangeAddress: contractAddresses.exchange,
		      makerAddress,
		      makerAssetAmount: new BigNumber(1),
		      makerAssetData: assetDataUtils.encodeERC721AssetData(eclipticAddress, new BigNumber(point)),
		      // makerFee,
		      makerFee: new BigNumber(0),
		      makerFeeAssetData: zeroAddressShort,
		      takerAddress: zeroAddress,
		      takerAssetAmount: Web3Wrapper.toBaseUnitAmount(new BigNumber(priceEth), 18),
		      takerAssetData: assetDataUtils.encodeERC20AssetData(contractAddresses.etherToken),
		      takerFee: new BigNumber(0),
		      takerFeeAssetData: zeroAddressShort,
		      // takerFee: new BigNumber(feeData.feeZrx),
		      // takerFeeAssetData: assetDataUtils.encodeERC20AssetData(contractAddresses.zrxToken),
		      // feeRecipientAddress: feeData.feeRecipientAddress,
		      feeRecipientAddress: zeroAddress,
		      expirationTimeSeconds: new BigNumber(Date.now() + duration).div(1000).integerValue(BigNumber.ROUND_CEIL),
		      salt: generatePseudoRandomSalt(),
		      senderAddress: zeroAddress,
		      chainId: networkId
		    };

		  	signatureUtils.ecSignOrderAsync(
		  		providerEngine,
		  		order,
		  		makerAddress
		  	).then((signedOrder: SignedOrder) => {

  		  	providerEngine.stop();

			  	this.createOrder({
			  		signedOrder,
			  		point,
			  		networkId,
			  		makerEmailAddress
			  	}).then((result: { completed: boolean }) => resolve(result))
			  		.catch((error: Error) => reject(error));

		  	}).catch((error: Error) => reject(error));
		  // }).catch((error: Error) => reject(error));
  	});
  }

  private withdrawWETH = () => {
  	const { networkId } = this.props;

  	const ethereum = (window as any).ethereum;
  	const providerEngine = new Web3ProviderEngine();
  	providerEngine.addProvider(new MetamaskSubprovider(ethereum));
		providerEngine.addProvider(new RPCSubprovider('https://kovan.infura.io/v3/92968c6aa05646c8997bd5a609067107'));  // is this needed?
		providerEngine.start();

		const contractWrappers = new ContractWrappers(providerEngine, { chainId: networkId });
		const contractAddresses = getContractAddressesForChainOrThrow(networkId);

    ethereum.enable().then((addresses: string[]) => 
	    ethereum.sendAsync({
	    	method: 'eth_sendTransaction',
			  params: [{
				  from: addresses[0],
				  to: contractWrappers.weth9.address,
				  data: contractWrappers.weth9.withdraw(Web3Wrapper.toBaseUnitAmount(new BigNumber(0.1), 18)).getABIEncodedTransactionData()
				}],
			  from: addresses[0]
	    }, (error: Error, result: string) => 
			  !error
			  	? providerEngine.stop()
			  	: this.handleError(error)
			)
    ).catch((error: Error) => this.handleError(error));
  }

  private handleError = (e: Error) => {
  	console.log(e);
  }

  private clickedOwnerLogin = (e: React.MouseEvent<HTMLElement>) => {
  	this.signTypedData().then(signature => 
			this.authenticate(signature).then(result => 
				this.setState({ ownerValidated: !!result.valid && result.valid })
			).catch(error => console.log(error))
		).catch(error => console.log(error));
  }

  private cancelSaleOrderButtonTapped = () => {
  	const { point, networkId } = this.props;
  	this.signTypedData().then(signature => 
			this.cancelOrder(signature, point, networkId).then(result => {
				if (result.completed) {
					this.props.refreshShipData();
				}
			}).catch(error => console.log(error))
		).catch(error => console.log(error));
  }

  private processPriceInput = (e: React.FormEvent<HTMLInputElement>) => {
  	e.preventDefault();
  	this.cleanPriceInput(e);
  }

  private cleanPriceInput = (input: React.BaseSyntheticEvent<Event, EventTarget & HTMLInputElement, EventTarget>) => {
  	const numbers = input.currentTarget.value.replace(/[^0-9]/g, '');
  	const priceFieldInput = numbers.length >= 3
  		? numbers.split('').reverse().slice(2).reduce((a, c, i, ary) => 
		  		a + 
		  		((i % 3 === 0) && (i > 0) ? ',' : '')
		  		+ (c === '0' && (i === ary.length - 1) ? '' : c),
		  	'').split('').reverse().join('') + '.' + numbers.split('').reverse().slice(0, 2).reverse().join('')
  		: numbers.split('').reverse().concat(
  				Array(3 - numbers.length).fill('0')
  			).reduce((a, c, i) => 
  				a + 
  				(i === 2 ? '.' : '')	
  				+ c, '').split('').reverse().join('');
  	const parsedPrice = parseFloat(priceFieldInput.replace(',', '')) / this.state.conversionRate;
  	input.currentTarget.value = priceFieldInput;
  	this.setState({ priceEth: Math.round(parsedPrice * 1000) / 1000 }, () => {
  		if (parsedPrice >= 0.01 && !this.state.fieldsValidated) {
  			this.setState({ fieldsValidated: true });
  		}
  	});
	};

	private processEmailAddressInput = (e: React.FormEvent<HTMLInputElement>) => {
  	e.preventDefault();
  	this.cleanEmailAddressInput(e);
  }

  private cleanEmailAddressInput = (input: React.BaseSyntheticEvent<Event, EventTarget & HTMLInputElement, EventTarget>) => {
  	const makerEmailAddress = input.currentTarget.value;
  	// if (isEmail(makerEmailAddress)) {
	  	this.setState({ makerEmailAddress });
	  // }
	};

  private signTypedData = (): Promise<TypedDataSig> => {
  	return new Promise((resolve, reject) => {
	  	const wndw = (window as any);
			if (!!wndw.ethereum) {
				const ethereum = wndw.ethereum;
				ethereum.enable().then((accounts: string[]) => {
				  const domain = [
				    { name: 'name', type: 'string' },
				    { name: 'version', type: 'string' },
				    { name: 'chainId', type: 'uint256' },
				    { name: 'salt', type: 'bytes32' }
					];
					const identity = [
				    { name: 'uint', type: 'uint256' },
				    { name: 'patq', type: 'string' }
					];
					const domainData = {
				    name: 'Urbit Live',
				    version: '1.0.1',
				    chainId: parseInt(ethereum.networkVersion, 10),
				    salt: '0xf2d857f4a3edcb9b78b4d123bfe733db1e3f6cdc2b7971ee739626c97e86a456'
					};
				  const data = JSON.stringify({
				    types: {
			        EIP712Domain: domain,
			        Identity: identity
				    },
				    domain: domainData,
				    primaryType: 'Identity',
				    message: {
				    	patq: patq((new BigNumber(Date.now() + (1000 * 60 * 60))).toString()),
				    	uint: generatePseudoRandomSalt()
				    }
					});
				  const signer = accounts[0];
					ethereum.sendAsync({
				    method: 'eth_signTypedData_v3',
				    params: [signer, data],
				    signer,
				  }, (err: Error, result: any) => {
				    if (err) return reject(err)
				    if (result.error) {
				      return reject(result.error.message)
				    }
				    resolve({ 
				    	from: signer, 
				    	data, 
				    	sig: result.result 
				    });
				  });
				}).catch((error: Error) => reject(error));
			}
		});
  }

  private authenticate = (ownerAuth: TypedDataSig): Promise<{ valid: boolean }> => {
  	return new Promise((resolve, reject) => {
	  	fetch(`${this.props.apiBaseURL}/authenticate`, {
	  		method: 'POST',
	  		headers: { 'Content-Type': 'application/json' },
	      body: JSON.stringify(ownerAuth)
	  	}).then(res => res.json())
	      .then((result: { valid: boolean }) => resolve(result))
	      .catch((error: Error) => reject(error));
    });
  }

  // private retrieveFeeData = (priceEth: number): Promise<FeeData> => {
  // 	return new Promise((resolve, reject) => {
	 //  	fetch(`${this.props.apiBaseURL}/feeData?priceEth=${priceEth}`)
	 //  		.then(res => res.json())
	 //      .then((feeData: FeeData) => resolve(feeData))
	 //      .catch((error: Error) => reject(error));
  //   });
  // }

  private createOrder = (unlistedOrder: UnlistedOrder): Promise<{ completed: boolean }> => {
  	return new Promise((resolve, reject) => {
	  	fetch(`${this.props.apiBaseURL}/createOrder`, {
	  		method: 'POST',
	  		headers: { 'Content-Type': 'application/json' },
	      body: JSON.stringify(unlistedOrder)
	  	}).then(res => res.json())
	      .then((result: { completed: boolean }) => resolve(result))
	      .catch((error: Error) => reject(error));
    });
  }

  private cancelOrder = (ownerAuth: TypedDataSig, point: number, networkId: number): Promise<{ completed: boolean }> => {
  	return new Promise((resolve, reject) => {
	  	fetch(`${this.props.apiBaseURL}/cancelOrder`, {
	  		method: 'POST',
	  		headers: { 'Content-Type': 'application/json' },
	      body: JSON.stringify({ ownerAuth, point, networkId })
	  	}).then(res => res.json())
	      .then((result: { completed: boolean }) => resolve(result))
	      .catch((error: Error) => reject(error));
    });
  }
}

export default ShipDashboard;